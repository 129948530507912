var toastr = require("toastr");
LivePlayerController.$inject = [
	"$scope",
	"$rootScope",
	"$filter",
	"$location",
	"$timeout",
	"$state",
	"$stateParams",
	"$window",
	"$http",
	"PlayerFactory",
	"Util",
	"LoginManager",
	"FavoriteService",
	"RecentService",
	"LaterviewFactory",
	"AfreecaStorageFactory",
	"ItemFactory",
	"gtext",
	"TokenFactory"
];

function LivePlayerController(
	$scope,
	$rootScope,
	$filter,
	$location,
	$timeout,
	$state,
	$stateParams,
	$window,
	$http,
	PlayerFactory,
	Util,
	LoginManager,
	FavoriteService,
	RecentService,
	LaterviewFactory,
	AfreecaStorageFactory,
	ItemFactory,
	gtext,
	TokenFactory
) {
	$scope.bMultiBtn = false;
	$scope.bMultiView = false;
	$scope.bPlay = false;
	$scope.bViewerBox = false;

	$scope.bOllehTvMobile = false;
	$scope.bIosOllehTvMobile = false;
    $scope.bDirectMode = false;

	$scope.bBuffer = false;
	$scope.bBufferOK = true;
	
	window.addEventListener("message", (event) => {
		if (event.data && event.data.cmd == "BuyPPVSuccess") window.location.reload();
	});
	// url entry_platform을 체크해서 ollehmobile로 접근시 예외처리를 위한 변수 설정
	if ($location.search().entry_platform == 'ollehmobile') {
		// ollehmobile중 ios
		if (Util.getOS() == "ios") {
			$scope.bIosOllehTvMobile = true;
		}
		$scope.bOllehTvMobile = true;
	}

	/**
	 * 아이템정보 불러오기
	 */
	ItemFactory.getItemImages().then(function(itemList) {
		$scope.itemList = itemList;
	});

	var InfoTimer = function() {
		var infoTimer = null;
		this.show = true;
		this.start = function() {
			infoTimer = setTimeout(function() {
				$(".play_group").fadeOut("fast");
				this.show = false;
			}, 3000);
		};
		this.reset = function() {
			this.show = true;
			this.stop();
			this.start();
		};
		this.stop = function() {
			if (infoTimer) clearTimeout(infoTimer);
		};
	};

	//배경색 검은색으로 바꿈
	$("body").addClass("liveArea");

	//유입경로 기록
	var referer = null;
	//플레이 했는지
	var bStart = false;
	var video = null;
	var infoTimer = new InfoTimer();
	var _broadInfo = null;
	var bj_id = null;
	var broad_no = 0;
    $scope.broadState = 0;
	$scope.state = 0;
	$scope.viewer = 0;
	$scope.nCurrentViewer = 0; //현재 채널의 유저 수
	$scope.nPcViewer = 0; //현재 채널의 pc유저 수
	$scope.nMobileViewer = 0; //현재 채널의 모바일 유저 수
	$scope.nDiffViewer = 0; // 현재 채널이 아닌 유저수 (본방이면, 중계방 / 중계방이면 본방)
	$scope.nAccumulateViewer = 0; // 누적 유저 수
	$scope.nBroadType = 0;
	//전체화면
	$scope.bFullscreenEnabled =
		document.fullscreenEnabled || document.webkitFullscreenEnabled || document.mozFullScreennabled || document.msFullscreenEnabled;
	var broad = $stateParams.broad;
	var aBroad = broad.split("/");
	bj_id = aBroad[0];
	broad_no = aBroad[1];

	//플래이어
	PlayerFactory.hasQuickviewHandler(function(value) {
		$scope.bQuickview = value;
	});

	//플래이어
	PlayerFactory.broadSuccessHandler(function(broadInfo) {
		_broadInfo = broadInfo;

		$scope.$broadcast("$chat.status", "init", _broadInfo);
		if (_broadInfo.parent_broad_no) {
			$scope.bRelay = true;
		} else {
			$scope.bRelay = false;
		}

		$scope.title = _broadInfo.broad_title;
		$rootScope.nickname = _broadInfo.user_nick;

		$scope.lang = _broadInfo.lang;

		if (_broadInfo.grade >= 19 && _broadInfo.is_password) {
			$scope.state = 3;
		} else if (_broadInfo.grade >= 19) {
			$scope.state = 2;
		} else if (_broadInfo.is_password) {
			$scope.state = 1;
		}

        $scope.broadState = ($scope.state === 0) ? 0 : 1;

		//나중에보기 등록 여부
		$scope.bIsLaterView = _broadInfo.is_later_view;

    function findGetParameter(parameterName) {
	    var result = null, tmp = [];
	    location.search
	        .substr(1)
	        .split("&")
	        .forEach(function (item) {
	            tmp = item.split("=");
	            if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
	        });
	    return result;
    }

    //IOS는 unuiversal link필요
    var exp =  new RegExp(".*onlive\.vn.*");
    		if ( afreeca.os != 'ios' && ( (document.referrer != "" && !exp.test(document.referrer)) || findGetParameter("link") ) ) {
        		$timeout(function() {
            afreeca.link.liveApp(_broadInfo.bj_id, _broadInfo.origianl_broad_no);
        }, 500);
    }
	});

	PlayerFactory.broadErrorHandler(function(response) {
        console.log(response.data);
        $scope.broadState = 1;
		//성인인증 Exception
		if (response.data.code == -3004 || response.data.code == -3003 || response.data.code == -3002) {
            PlayerFactory.log('A', 6, '02');
			$scope.state = 2;
		}
		// 입력하신 비밀번호가 일치하지 않습니다.
		else if (response.data.code == -3005) {
            PlayerFactory.log('A', 6, '06');
			$scope.state = 1;
		}
		//본 방송은 저작권 문제로 인하여 해외 지역에서 시청할 수 없습니다.
		else if (response.data.code == -3015) {
			PlayerFactory.log('A', 4, response.data.code);
			alert(gtext(response.data.message));
            $state.go("home");
		}
		// 강제퇴장 되어 방송을 볼 수 없습니다.
		else if (response.data.code == -3016) {
            PlayerFactory.log('A', 4, response.data.code);
			alert(gtext(response.data.message));
			$scope.backHandler();
		}
		// 점검
		else if (response.data.code == -1200) {
            PlayerFactory.log('A', 4, response.data.code);
			alert(response.data.message);
			$state.go("home");
		} else if (response.data.code == -6602) {
            PlayerFactory.log('A', 4, response.data.code);
			alert(response.data.message);
			$state.go("home");
		} else {
            PlayerFactory.log('A', 4, response.data.code);
			//방송이 없습니다.
			$scope.state = 4;
			$scope.aChat = [];
			$scope.stop();
		}
	});

	PlayerFactory.stateHandler(function(event) {
		console.log(event.type);
		switch (event.type) {
			case "play":
				$scope.bPlay = true;
				$scope.$digest();
				break;
			case "pause":
				$scope.bPlay = false;
				$(".play").show();
				$scope.$digest();
				TokenFactory.setTokenInfo('LIVE_WATCH_PAUSE',_broadInfo);
				break;
			case "waiting":
				$scope.bBuffer = true;
                PlayerFactory.setStartBufferTime(new Date().getTime());
				$scope.bLoading = true;
				$scope.$digest();
				break;
			case "playing":
				//$scope.bLoading = false;
				//$scope.$digest();
				TokenFactory.setTokenInfo('LIVE_WATCH_START',_broadInfo);
				break;
			case "loadeddata":
                if($scope.broadState === 0) {
                    //초기버퍼링 로그 전송
                    PlayerFactory.setLastBufferTime(new Date().getTime());
                    PlayerFactory.log('B', 1);
                }
				break;
			case "canplay":
				//버퍼링 중이었으면 버퍼링 로그 전송
				if($scope.bBuffer) {
                    PlayerFactory.setLastBufferTime(new Date().getTime());
					$scope.bBuffer = false;
					if($scope.bBufferOK) {
                        $scope.bBufferOK = false;
                        PlayerFactory.log('B', 2);
                        setTimeout(function() {
                            $scope.bBufferOK = true;
                            $scope.$digest();
                        }, 500);
					}
				}
                $scope.bLoading = false;
				$scope.$digest();
				break;
		}
	});

	PlayerFactory.errorHandler(function(error) {
        switch (error.code) {
			case error.MEDIA_ERR_ABORTED:
				console.log("You aborted the video playback.");
				break;
			case error.MEDIA_ERR_NETWORK:
				console.log("A network error caused the video download to fail part-way.");
				break;
			case error.MEDIA_ERR_DECODE:
				console.log(
					"The video playback was aborted due to a corruption problem or because the video used features your browser did not support."
				);
				break;
			case error.MEDIA_ERR_SRC_NOT_SUPPORTED:
				//방송이 없을경우
				console.log(
					"The video could not be loaded, either because the server or network failed or because the format is not supported."
				);
				break;
			default:
                console.log("An unknown error occurred.");
				break;
		}
		try {
			$scope.bLoading = false;
			$scope.bRefresh = true;
            //정상 시청중이었을 경우에만 보내야 함
            if($scope.broadState === 0) {
                PlayerFactory.log('C', 8, error.code);
            }
		} catch (e) {}
	});

	/**
	 * 새로고침
	 * @returns {undefined}
	 */
	$scope.refreshHander = function() {
		if (/videoportal/.test(navigator.userAgent)) {
			$window.location.href = "//" + $window.location.host + "?" + Math.random() + $window.location.hash;
		} else {
			$window.location.reload();
		}
		return;
	};

	$scope.getBroadInfo = function() {
		return _broadInfo;
	};
	/**
	 * 공유하기 팝업
	 * @returns {undefined}
	 */
	$scope.snsHandler = function() {
		$("#snsLayer").show();
	};
	$scope.snsCloseHandler = function() {
		$("#snsLayer").hide();
	};

	/**
	 * 공유하기
	 * @returns {undefined}
	 */

	var Kakao = require("kakao");

	$scope.selectSns = function(type) {
		var descContent = "";
		if (type == "qqspace" || type == "weibo") {
			descContent = gtext("BJ %s의 방송 함께 봐요!", _broadInfo.bj_id);
		}

		$("#snsLayer").hide();
		var title = $scope.title.replace("|중계방송|", "");
		afreeca.share(type, {
			msg: gtext("아프리카TV에서 %s을 보고 있어요. 함께 보실래요?", title),
			url: PLAY_AFREECATV + "/" + _broadInfo.bj_id + "/" + +_broadInfo.origianl_broad_no,
			title: title,
			thumb: _broadInfo.thumbnail,
			nickname: _broadInfo.user_nick,
			lang: _broadInfo.lang,
			desc: descContent,
			summary: gtext("아프리카 TV 사이트(www.onlive.vn)에서 더 많은 컨텐츠를 즐길 수 있습니다!"),
		});
	};

	$scope.fullHandler = function() {
		$("body").width("100%");
		//var body = $('body')[0];

		if (
			!document.fullscreenElement && // alternative standard method
			!document.mozFullScreenElement &&
			!document.webkitFullscreenElement &&
			!document.msFullscreenElement
		) {
			// current working methods
			if (document.documentElement.requestFullscreen) {
				document.documentElement.requestFullscreen();
			} else if (document.documentElement.msRequestFullscreen) {
				document.documentElement.msRequestFullscreen();
			} else if (document.documentElement.mozRequestFullScreen) {
				document.documentElement.mozRequestFullScreen();
			} else if (document.documentElement.webkitRequestFullscreen) {
				document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
			}
			$scope.bFullscreen = true;
		} else {
			if (document.exitFullscreen) {
				document.exitFullscreen();
			} else if (document.msExitFullscreen) {
				document.msExitFullscreen();
			} else if (document.mozCancelFullScreen) {
				document.mozCancelFullScreen();
			} else if (document.webkitExitFullscreen) {
				document.webkitExitFullscreen();
			}
			$scope.bFullscreen = false;
		}
	};

	/**
	 * 추천하기 버튼
	 */
	$scope.recommandHandler = function() {
		if (Util.getOS() == "ios") {
			if (confirm(gtext("UP은 아프리카TV앱에서 하실 수 있습니다.\n\n아프리카TV 앱에서 참여하시겠습니까?"))) {
				$scope.goLive();
			}
			return true;
		}

		$scope.$broadcast("$chat.recommend");
	};

	/**
	 * 나중에 보기 추가/삭제
	 */
	$scope.laterViewHandler = function() {
		//이미 나중에보기에 추가된 경우엔 삭제
		if ($scope.bIsLaterView) {
			LaterviewFactory.setData({
				broad_no: broad_no,
				user_id: bj_id,
				location: "live",
			})
				.delete()
				.then(function(res) {
					$scope.bIsLaterView = false;
					$scope.$digest();
				});
		} else {
			LaterviewFactory.setData({
				type: "LIVE",
				broad_no: broad_no,
				location: "live",
			})
				.add()
				.then(
					function(res) {
						$scope.bIsLaterView = true;
						$scope.$digest();
					},
					function(error) {
						$scope.bIsLaterView = true;
					}
				);
		}
		return true;
	};

	//나중에보기 안내 레이어 닫기
	$scope.closeLaterLayer = function() {
		LaterviewFactory.close($scope.bLaterLayerChecked);
	};

	/**
	 * 고화질버튼
	 */
	$scope.highQualityHandler = function() {
		if (confirm(gtext("고화질 참여는 아프리카TV 앱에서 하실 수 있습니다.\n\n아프리카TV 앱에서 참여하시겠습니까?"))) {
			$scope.goLive();
		}
	};
	/**
	 * 고화질버튼
	 */
	$scope.multiViewHandler = function(event) {
		event.stopPropagation();
		if ($scope.bMultiView === false) {
			$scope.bShowChat = false;
			$scope.bMultiView = true;
			PlayerFactory.showMultiView();
		} else {
			$scope.bShowChat = true;
			$scope.bMultiView = false;
			PlayerFactory.hideMultiView();
		}
	};

	$scope.restoreHandler = function() {
		$("body").width("100%");
		var body = $("body")[0];
		if (body.requestFullScreen) {
			body.cancelFullScreen();
		} else if (body.webkitRequestFullScreen) {
			body.webkitCancelFullScreen();
		} else if (body.mozRequestFullScreen) {
			body.mozRequestFullScreen();
		}
	};
	/**
	 * 타이틀 태그문자열 살리기
	 * @param {type} title
	 * @returns String;
	 */
	$scope.decodeTitle = function(title) {
		var decoded = $("<div/>")
			.html(title)
			.text();
		return decoded;
	};

	var bodyArea = $("window");
	var headerArea = $("header");
	var playerArea = $(".player_area");
	var chatArea = $(".chat_area");
	var chatVArea = $(".chat_v");
	/**
	 * 최초 실행시 가로세로를 판단
	 * @type Event
	 */
	var VERTICAL = false;
	var HORIZONTAL = true;
	var direction = VERTICAL;
	//var bTablet = (/M380W/i.test(navigator.userAgent)) ? true : false;
	var bTablet = window.innerWidth > window.innerHeight && window.orientation == 0 ? true : false;
	$(window).on("orientationchange resize", function(event) {
		if (window.orientation == undefined) {
			if (window.innerWidth > window.innerHeight) {
				direction = HORIZONTAL;
			} else {
				direction = VERTICAL;
			}
		} else {
			switch (window.orientation) {
				case -90:
				case 90:
					direction = HORIZONTAL ^ bTablet;
					break;
				default:
					direction = VERTICAL ^ bTablet;
					break;
			}
		}
		chatResizeHandler();
	});

	/**
	 * 화면
	 * @returns {undefined}
	 */
	var chatResizeHandler = function() {
		console.log("$location.path()", $location.path());
		if ($location.path().indexOf("/player") > -1) {
			//화면 갱신후 0.5초후에.. 채팅창 크기변경
			$timeout(function() {
				var chatHeight;
				if (direction == HORIZONTAL) {
					//가로모드
					console.log("가로");
					$("body").addClass("fullscreen");
					$(".player_v").height($(window).height());
					$(".chat_area").height(105); //줄정도
					$("#live_canvas").get(0).width = $(".player_v").width();
					$("#live_canvas").get(0).height = $(window).height();

					var stageHeight = $(window).height() - $("header").height();
					$scope.$broadcast("$chat.resize", 0);
					$(".chat_area").height(Math.min(stageHeight, 105)); //줄정도

					$(".multi_area").height(Math.min(stageHeight, 105));
				} else {
					console.log("세로");
					$("body").removeClass("fullscreen");
					var width = $(window).width();
					var height = width * 9 / 16;
					$(".player_v").height(height);
					$("#live_canvas").get(0).width = width;
					$("#live_canvas").get(0).height = height;

					if ($("#message").is(":focus") && $(window).width() > $(window).height()) {
						$(".chat_area").height($(window).height() - $("div.chat_g").height() - $("header").height() - 30);
					} else {
						var chatAreaHeight = $(window).height() - $("div.chat_g").height() - $("header").height() - height - 30;
						$scope.$broadcast("$chat.resize", chatAreaHeight);
						$(".chat_area").height(chatAreaHeight);
					}
					$(".multi_area").height($(".chat_area").height());
				}
			}, 100);
		}
	};

	function get_browser() {
		var ua = navigator.userAgent,
			tem,
			M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
		if (/trident/i.test(M[1])) {
			tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
			return {
				name: "IE",
				version: tem[1] || "",
			};
		}
		if (M[1] === "Chrome") {
			tem = ua.match(/\bOPR\/(\d+)/);
			if (tem != null) {
				return {
					name: "Opera",
					version: tem[1],
				};
			}
		}
		M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, "-?"];
		if ((tem = ua.match(/version\/(\d+)/i)) != null) {
			M.splice(1, 1, tem[1]);
		}
		return {
			name: M[0],
			version: M[1],
		};
	}

	$(window).trigger("resize");

	$rootScope.$on("live.player.enter", function() {
		$("body").addClass("liveArea");
	});
	$rootScope.$on("live.player.exit", function() {
        $scope.broadState = 1;
		$scope.$broadcast("$chat.status", "stop");
		$scope.stop();
		$("body").removeClass("liveArea");
		if ($scope.bFullscreen == true) {
			$scope.fullHandler();
		}

		//멀티뷰
		$scope.bShowChat = true;
		$scope.bMultiView = false;
		PlayerFactory.revmoeMultiView();
	});

	/*
	 * 정지
	 * @returns {undefined}
	 */
	$scope.stop = function() {
		$(".playBtn").css("display", "block");
		$scope.showChat = false;

        $scope.bBuffer = false;
        $scope.bBufferOK = true;
        
        bStart = false;

		PlayerFactory.stop();
        TokenFactory.setTokenInfo('LIVE_WATCH_END',_broadInfo);
		$scope.$broadcast("$chat.status", "stop", _broadInfo);
	};

	/**
	 * 플레이
	 * @returns {undefined}
	 */
	$scope.playHandler = function() {
		//라이브캠방송 && IOS 경우
		if (_broadInfo.broad_type == "13") {
			alert(
				gtext(
					"이 방송은 모바일 라이브 캠 방송으로 BJ가 방송을 일시정지할 경우, 방송이 멈추거나 검은 화면이 노출될 수 있습니다.(일시정지가 해제되면 방송이 다시 재생됩니다.)"
				)
			);
		}
		bStart = true;
		$(".play").hide();
		$(".play_group").show();
		infoTimer.start();
		PlayerFactory.play();
		$scope.$broadcast("$chat.status", "start");
		window.szLogAction = "LIVE_MOBILE";
		$.getScript(ANALYSIS_AFREECATV + "/_au.js");
	};
	/**
	 * 화면을 터치할경우.. 제목나오게함
	 */
	$scope.touchHandler = function(event) {
		$(".play_group").fadeIn("fast");
		infoTimer.reset();

		//멀티화면이 노출되어 있으면 사라지게함
		if ($scope.bMultiView == true) {
			$scope.multiViewHandler(event);
		}
	};
	/**
	 * 일부 단말에서 video 태그 클릭시 일시정지 방지용
	 * @param {type} event
	 */
	$scope.noTouchHandler = function(event) {
		event.stopPropagation();
		$scope.touchHandler(event);
	};

	/**
	 * 즐겨찾기
	 */
	$scope.favoriteHandler = function() {
    var logData = {
    'user_no':LoginManager.getUserNo(),
    'location':'live',
    'join_cc':_broadInfo.join_cc,
    'category_no':_broadInfo.broad_cate_no,
    'broad_no':_broadInfo.broad_no,
    'parent_broad_no': (_broadInfo.broad_no === _broadInfo.origianl_broad_no ? 0 : _broadInfo.origianl_broad_no)
    };
		if (LoginManager.isLogin()) {
			if ($scope.favorite) {
				FavoriteService.delete(bj_id, logData).then(
					function(response) {
						toastr.info(gtext("즐겨찾기가 삭제되었습니다."));
						$scope.favorite = false;
						$scope.$digest();
					},
					function(resonse) {
						toastr.warning(resonse.data.message);
					}
				);
			} else {
				FavoriteService.add(bj_id, logData).then(
					function(response) {
						toastr.success(gtext("즐겨찾기에 추가되었습니다."));
						$scope.favorite = true;
						$scope.$digest();
					},
					function(resonse) {
						toastr.warning(resonse.data.message);
					}
				);
			}
		} else {
			$rootScope.addFavoriteHandler(bj_id);
		}
	};

	/**
	 * 본방입장
	 */
	$scope.originalHandler = function() {
		if (LoginManager.isLogin()) {
			var bQuickview = PlayerFactory.syncCheckQuickview();
			if (bQuickview) {
				//플레이페이지에서 로그인 되었을경우
				$scope.bQuickview = true;
				PlayerFactory.reconnect(LoginManager.getUserNo());
			} else {
				if ($rootScope.member && $rootScope.member.is_realnamecheck == false) {
					if (
						confirm(
							gtext(
								"아프리카TV는 정보통신망법에 따른 본인확인제를 시행하고 있으며, 이에따라 아이템 결제 및 아이템 교환시 최초 1회에 한에 본인 학인 절차를 거칩니다. 아이핀을 이용한 본인확인을 진행하시겠습니까?"
							)
						)
					) {
						Util.popup(M_AFREECATV + "/realname/a/agree/?path=web");
					}
					return;
				}

				if (confirm(gtext("본방입장을 위해서는 퀵뷰아이템이 필요합니다. 지금 바로 퀵뷰 구매페이지로 이동하시겠습니까?"))) {
					var data = {
						ndevice: Util.getOS() == "ios" ? 1 : 2,
						szWork: "help",
						szKind: "TBOX20131007000000",
						szUserID: LoginManager.getUserId(),
						szVersion: "1.0.0",
						szModel: "web",
					};
					var url = POINT_AFREECATV;

					Util.callbackWindowPopup(url + $.param(data), function() {
						PlayerFactory.videoPlay();
					});
				}
			}
		} else {
			$rootScope.login();
		}
	};
	/**
	 * 뒤로가기 버튼
	 */
	$scope.backHandler = function() {
		console.log("backHandler");
		if (referer) {
			$window.history.back();
		} else {
			if($scope.bOllehTvMobile){
				$window.history.back();
			}else{
				$state.go("home");
			}
		}
	};
	/**
	 * 앱으로 이동
	 */
	$scope.goApp = function() {
		$scope.stop();
		Util.app();
	};
	/**
	 * 앱 플레이어로 이동
	 */
	$scope.goLive = function() {
		$scope.bRefresh = true;
		$scope.stop();
		Util.live(bj_id, broad_no);
	};
	/**
	 * 재시작
	 * @returns {undefined}
	 */
	$scope.replayHandler = function() {
		//플레이어 재시작
		PlayerFactory.videoPlay();
		//채팅 재접속
		$scope.$broadcast("$chat.status", "init", _broadInfo);
		$scope.$broadcast("$chat.status", "start");
	};

	/**
	 *  선물을 받았을 경우
	 */
	$scope.$on("$gift", function($event, item) {
		$scope.item = item;
	});

	//최근본 방송에 추가
	RecentService.add(bj_id);

	//즐겨찾기
	FavoriteService.check(bj_id).then(
		function(value) {
			$scope.favorite = value;
			$scope.$digest();
		},
		function(error) {
			$scope.favorite = false;
			$scope.$digest();
		}
	);

	$scope.$on("$viewContentLoaded", function(event) {
		//본인에 해당하는것만
		if (event.targetScope == $scope && bStart) {
			setTimeout(function() {
				$scope.$watch("bBlind", function(newNames, oldNames) {
					if (newNames) {
						PlayerFactory.videoPause();
					} else {
						PlayerFactory.videoPlay();
					}
				});
			}, 10);
		}
	});

	$scope.setMultiviewInfo = function(info) {
		var browser = get_browser();
		//키켓이하 기본브라우져는 멀티방송이 안되게 처리함
		if (navigator.userAgent.indexOf("Android") > -1) {
			var androidVersion = parseFloat(navigator.userAgent.match(/Android\s+([\d\.]+)/)[1]);
			//안드로이드 키켓이하 크롬브라우져가 아닌경우네
			//크롬브라우져 버전 40이하인 경우
			if ((androidVersion <= 4.4 && browser.name !== "Chrome") || (browser.name === "Chrome" && browser.version <= 40)) {
				return false;
			}
		}

		//멀티방송 버튼 (비)활성화
		if (info.points && info.points.length > 0) {
			$scope.bMultiBtn = true;
		} else {
			$scope.bMultiBtn = false;
			//채팅보이기
			$scope.bShowChat = true;
			//멀티뷰 숨기기
			$scope.bMultiView = false;
			PlayerFactory.hideMultiView();
		}
		PlayerFactory.setMultiviewInfo(info);
	};

	/**
	 * 뒤로가기 버튼을 위해
	 * 어디서 왔는기 기록해둠
	 */
	$scope.$on("$locationChangeSuccess", function(evt, next, current) {
		referer = current;
	});

	/**
	 * 로그인 이벤트
	 */
	var bLogin = false;
	$scope.$on("$loginChange", function(event, value) {
		//시작
		//안드로이드 3.0 이상에서만 지원
		if (Util.isGingerbreadHigher()) {
			//3.0 이상인지
			PlayerFactory.init(bj_id, broad_no, LoginManager.getUserNo());
            if(bStart) {
                if(LoginManager.getUserNo()===0) TokenFactory.setTokenInfo('LIVE_WATCH_END', _broadInfo);
                else TokenFactory.setTokenInfo('LIVE_WATCH_START', _broadInfo);
            }
		} else {
			$scope.aChat = []; //채팅내용 삭제
			$scope.state = 5; //지원하지 않습니다.
			$scope.bNotSupportChat = true;
		}
	});
	LoginManager.check();

	/**
	 * 모바일 아프리카TV 고객센터 이동
	 */
	$scope.goReportHelp = function() {
		Util.goReportHelp();
	};

	/**
	 * 쇼핑카트 버튼 Handler
	 */
	$scope.shoppingCartHandler = function() {
		//로그인체크
		if (LoginManager.isLogin() == false) {
			$scope.message = "";
			$rootScope.login();
			return false;
		}

		Util.popup(
			SHOP_AFREECATV + "/category?szBjId=" +
				_broadInfo.bj_id +
				"&nBroadNo=" +
				_broadInfo.broad_no +
				"&nCategory=" +
				_broadInfo.broad_cate_no,
			"shopping"
		);
	};

	$scope.viewerLayerHandler = function() {
		if ($scope.bViewerBox) {
			$scope.bViewerBox = false;
		} else {
			$scope.bViewerBox = true;
		}
	};

	$scope.$on("$viewContentLoaded", function(event) {
		if (/videoportal/.test(navigator.userAgent)) {
			$("header").hide();
			$(".content_wrap").css("padding-top", "0px");
			$scope.bVideoportal = true;
		}
	});

	//생방송 랜딩 UV
	$timeout(function() {
		window.szLogAction = "MOBILE_LIVE_PAGE";
		$.getScript(ANALYSIS_AFREECATV + "/_au.js");
	}, 1000);
}

module.exports = LivePlayerController;
