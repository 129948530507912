MyTokenItemController.$inject = ['$scope', '$rootScope', '$state', '$stateParams', '$http', '$timeout', 'Util', 'LoginManager', 'MypointService', 'HistoryState']

function MyTokenItemController($scope, $rootScope, $state, $stateParams, $http, $timeout, Util, LoginManager, MypointService, HistoryState) {

    Util.moveScroll(0);
    $scope.purchaseLists = [];
    $scope.possessionLists = [];
    $scope.expiredLists = [];
     
    // 이용권 구매정보 조회
    $scope.purchaseItems = function () {
        MypointService.purchaseItems()
        .then(function (response) {
            $scope.purchaseLists = angular.copy(response.DATA);
            $scope.$digest();
        }).catch(function (response) {
            $scope.$digest();
        });
    }

    // 보유중인 이용권 조회
    $scope.possessionItems = function () {
        MypointService.possessionItems(LoginManager.getUserId())
        .then(function (response) {
            $scope.possessionLists = angular.copy(response.DATA);
            $scope.$digest();
        }).catch(function (response) {
            $scope.$digest();
        });
    }

    // 만료된 이용권 조회
    $scope.expiredItems = function () {
        MypointService.expiredItems(LoginManager.getUserId())
        .then(function (response) {
            $scope.expiredLists = angular.copy(response.DATA);
            $scope.$digest();
        }).catch(function (response) {
            $scope.$digest();
        });
    }

    // 날자형식 변환
    $scope.setDateConvert = function (date,type) {
        if (type == 'date') {
            date = date == null ? date = '' : date.substr(0,10).replace(/-/g,".");
        } else if (type == 'time') {
            date = date == null ? date = '' : date.substr(10,6).replace(/-/g,".");
        }

        return date;
    }

    // 구매타입 확인
    $scope.purchaseType = function (type) {
        if (type == 1) {
            return "wait";
        } else if (type == 2) {
            return "purchase";
        }
    }

    $scope.itemColorType = function (hexCode) {
        // hexCode = "800000;
        if (hexCode !== "" && hexCode !== null) {
            return "#"+hexCode;
        } else {
            // 색상 기본코드 필요시 기입
        }
    }

    // 이용권 구매정보 상세페이지
    $scope.goItemPurchasePage = function (itemNo,sellType) {
        if (sellType !== 2) {
            alert('현재 판매중인 상품이 아닙니다.');
            return false;
        }
        $state.go('mypoint.itemdetail', {
            itemNo: itemNo,
            actionType: 'purchase'
        });
    }

    // 이용권 사용정보 상세페이지
    $scope.goItemUsePage = function (itemNo) {
        $state.go('mypoint.itemdetail', {
            itemNo: itemNo,
            actionType: 'use'
        });
    }

    // 만료된 이용권정보 상세페이지
    $scope.goItemExpirePage = function (itemNo) {
        $state.go('mypoint.itemdetail', {
            itemNo: itemNo,
            actionType: 'expire'
        });
    }

   if (typeof $rootScope.member == 'undefined') {
        $rootScope.$on('$loginChange', function (event, value) {
            if (value) {
                $scope.purchaseItems();
                $scope.possessionItems();
                $scope.expiredItems();
            } else {
                if (afreeca.link.isWebview()) {
                    location.href = "onlive://login/requireLogin";
                } else {
                    toastr.error("Bạn không đăng nhập");
                    setTimeout(function () {
                        $state.go("home");
                    }, 500);
                }
            }
        });
        LoginManager.check();
    } else {
        $scope.purchaseItems();
        $scope.possessionItems();
        $scope.expiredItems();
    }
}

module.exports = MyTokenItemController;
