var toastr = require('toastr');
MyinfoUserInfoController.$inject = ['$scope', '$rootScope', '$state', '$sce', '$http', '$timeout', 'Util', 'LoginManager', 'NicknameService', 'CookieFactory']
function MyinfoUserInfoController($scope, $rootScope, $state, $sce, $http, $timeout, Util, LoginManager, NicknameService, CookieFactory) {

	$scope.nickname = LoginManager.getNickname();
	$rootScope.dateFormat = 'MM/dd/yyyy';
	$scope.dataUsr = {
		phone: "",
		id_card: "",
		id_card_issue_date: "",
		id_card_issue_place: "",
	};

	/**
	 * 뒤로가기 버튼
	 */
	$scope.backHandler = function () {
		$state.go("myinfo");
	}
	//로그인이벤트
	$scope.$on('$loginChange', function (event, value) {

		if (LoginManager.isLogin()) {
			$scope.nickname = LoginManager.getNickname();
		}
		else {
			toastr.error(gtext("로그인이 되어 있지 않습니다."))
			setTimeout(function () {
				$state.go("home");
			}, 500);

		}
	});

	this.$onInit = function () {
		$scope.getDataUser();
	}

	$scope.getDataUser = function (){
		return new Promise(function () {
			$http({
				method: 'GET',
				url: BILLING_AFREECATV + '/billing-api/auth/extend-profile',
				headers: {
					'Content-Type': 'application/json',
					'Authorization' : 'Bearer ' + CookieFactory.get('access_token')
				}
			}).then(function (response) {
				if (response.data?.RESULT == 1) {
					let usrInfo = response.data.RET_DATA;
					$scope.dataUsr.phone = usrInfo?.phone;
					$scope.dataUsr.id_card = usrInfo?.id_card;
					$scope.dataUsr.id_card_issue_date = $scope.formatDate(usrInfo?.id_card_issue_date);
					$scope.dataUsr.id_card_issue_place = usrInfo?.id_card_issue_place;
				}
			}).catch(function (error) {
				console.log(error);
			})
		});
	}

	$scope.formatDate = function(date){
		return new Date(date);
	}

	$scope.convertDate = function (date){
		let dateFormat = new Date(date);
		let y = dateFormat.getFullYear();
		let m = dateFormat.getMonth() + 1;
		let d = dateFormat.getDate();
		return y + "-" + $scope.padWithLeadingZeros(m, 2) + "-" + $scope.padWithLeadingZeros(d, 2);
	}

	$scope.padWithLeadingZeros = function (num, totalLength) {
		return String(num).padStart(totalLength, '0');
	}

	$scope.submit = function() {
		let dataUser = {
			phone: $scope.dataUsr.phone,
			id_card: $scope.dataUsr.id_card,
			id_card_issue_date: $scope.convertDate($scope.dataUsr.id_card_issue_date),
			id_card_issue_place: $scope.dataUsr.id_card_issue_place,
		}

		return new Promise(function () {
			$http({
				method: 'POST',
				url: BILLING_AFREECATV + '/billing-api/auth/extend-profile-update',
				data: dataUser,
				headers: {
					'Content-Type': 'application/json',
					'Authorization' : 'Bearer ' + CookieFactory.get('access_token')
				}
			}).then(function (response) {
				if (response.data?.RESULT == 1) {
					toastr.info("Cập nhật thông tin thành công");
					$scope.backHandler();
				}else{
					toastr.error("Có lỗi xảy ra vui lòng thử lại sau");
					$scope.backHandler()
				}
			}).catch(function (error) {
				toastr.error("Có lỗi xảy ra vui lòng thử lại sau");
				$scope.backHandler()
			})
		});
	};
	LoginManager.check();
}
module.exports = MyinfoUserInfoController;