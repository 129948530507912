

ChatService.$inject = ['$http'];
function ChatService($http) {
  this.list = function () {
    return new Promise(function (resolve, reject) {
      $http.post(API_M_AFREECATV + '/broad/chat/bridge/a/getList').then(function (response) {
        if(response.data.result == 1){
resolve(response.data)
}else
{
reject(response.data);
};
      }).catch(function (error) {
        reject(error);
      })
    });
  }
}


module.exports = ChatService;