/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */
define(['jquery', 'md5'], function ($, md5) {
    const LOG_NAME = {
        VOD: 'VOD'
		, VOUT: 'VOUT'
		, LATERVIEW: 'LATERVIEW'
        , FAV : 'FAV'
        , CSTATUS : 'CSTATUS'
        , MWEB_STATUS : 'MWEB_STATUS'
        , CLICK : 'CLICK'
        , MTHEME : 'MTHEME'
    };
    const HTTP_COLECTOR_ENV_URL_DEV = '//tcollector1.onlive.vn:8080/CONFIG/';
    const HTTP_COLECTOR_LOG_URL_DEV = '//sdev1.onlive.vn:8080/gather?sv=AF';
    const HTTP_COLECTOR_ENV_URL = '//collector1.onlive.vn/CONFIG/';
    const HTTP_COLECTOR_LOG_URL = '//collector1.onlive.vn/gather';
    const VOD_UA_LOG_URL = 'https://eventapi.onlive.vn/set_log_api.php';
    const SERVICE_NAME = 'AF';
    const VERSION = '1.1';
    const CONNECT_SYSTEM = 'webm';
    const VALUE_DELIMITER = String.fromCharCode(6) + '=' + String.fromCharCode(6);
    const KEY_DELIMITER = String.fromCharCode(6) + '&' + String.fromCharCode(6);

    var log = {};
    var reloadTime = 0;
    var uid = '';
    var canLog = false;

    /**
     * 로그 검증용 hash 값
     * @param {type} param
     * @returns {unresolved}
     */
    function createHash(param) {
		var values = Object.keys(param).map(function ( key ) {
			return param[key];
		});
        values.push('afreeca');
        var hashValue = values.join('|');
        return md5(hashValue);
    }

    function getUUIDHash(uuid, persent)
    {
        if (!uuid || uuid === null) {
            return 100;
        }

        var hash = md5(uuid);
        var len = hash.length;
        var i, j = 0;
        for (i = 0; i < len; i++)
        {
            j = hash[i].charCodeAt(0);
        }

        return j % persent;
    }


    function getOs() {
        if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
            return 'ios';
        } else if (navigator.userAgent.match(/Android/i)) {
            return 'android';
        } else {
            return 'etc';
        }
    }
    
    function get_browser() {
		var OS = {
            name: 'Unknown',
            version: 0
        };
        if (navigator.userAgent.match(/Android (\d+(?:\.\d+){1,2})/i)) { OS.name = 'win', OS.version = 'Windows 10' }
        if (navigator.userAgent.indexOf('Windows NT 6.2') !== -1) { OS.name = 'win', OS.version = 'Windows 8' }
        if (navigator.userAgent.indexOf('Windows NT 6.1') !== -1) { OS.name = 'win', OS.version = 'Windows 7' }
        if (navigator.userAgent.indexOf('Windows NT 6.0') !== -1) { OS.name = 'win', OS.version = 'Windows Vista' }
        if (navigator.userAgent.indexOf('Windows NT 5.1') !== -1) { OS.name = 'win', OS.version = 'Windows XP' }
        if (navigator.userAgent.indexOf('Windows NT 5.0') !== -1) { OS.name = 'win', OS.version = 'Windows 2000' }
        if (navigator.userAgent.indexOf('Mac') !== -1) { OS.name = 'mac', OS.version = 'Mac/iOS' }
        if (navigator.userAgent.indexOf('X11') !== -1) { OS.name = 'unix', OS.version = 'UNIX' }
        if (navigator.userAgent.indexOf('Linux') !== -1) { OS.name = 'linux', OS.version = 'Linux' }

        return OS;
	}

    /**
     * 쿠키읽기
     * @param {string} name
     * @returns {match|Array}
     */
    function getCookie(name) {
        match = document.cookie.match(new RegExp(name + '=([^;]+)'));
        return match ? match[1] : null;
    }
    /**
     * 쿠키쓰기
     * @param {string} name
     * @param {string} value
     * @param {number} exdays
     * @returns {undefined}
     */
    function setCookie(name, value, exdays) {
        var exdate = new Date();
        exdate.setDate(exdate.getDate() + exdays);
        var c_value = escape(value) + ((exdays == null) ? "" : "; expires=" + exdate.toUTCString());
        document.cookie = name + "=" + c_value;
    }

    /**
     * 32bit uuid문자열
     * @returns {Array}
     */
    function generateUUID() {
        return '0x'+'xxxxxxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }


    var collector = {

        init: function () {
            $.ajax({
                method: "GET",
                dataType: 'json',
                url: HTTP_COLECTOR_ENV_URL
            })
                    .success(function (response) {
                        canLog = true;
                        reloadTime = response.config_reload;
                        log = response.log;
                    });
            return this;
        },
        setUserId: function (val) {
            uid = val;
            return this;
        },
        getDelimiter: function () {
            return KEY_DELIMITER;
        },
        buildQuery: function (data) {
            var newData = {};
            //값이 없는거 제거
            Object.keys(data).map(function (key) {
                if (data[key] != null && data[key] != '' && data[key] != 0) {
                    newData[key] = data[key];
                } else {
                    if(key == 'parent_broad_no') {
                        newData[key] = data[key] || 0;
                    }
                }
            });

            return Object.keys(newData).map(function (key) {
                return  key + VALUE_DELIMITER + data[key];
            }).join(KEY_DELIMITER);
        },
        uuid: function ()
        {
            var uuid = getCookie('_au');
            if (uuid)
            {
                return uuid;
            } else {
                uuid = generateUUID();
                setCookie('_au', uuid, 10);
                return uuid;
            }
        },
        sendLog: function (logName, data) {
            if (!canLog) {
                return;
            }

            var logConfig = (logName == LOG_NAME.CSTATUS) ? log[logName][data.m_type] : log[logName];
            if (!logConfig) {
                return;
                logConfig = {
                    able: true,
                    send_percent: 100,
                    rand_send: 0
                }
            }
            
            if (!logConfig.able || logConfig.send_percent == 0) {
                return;
            }

            if (logConfig.send_percent < 100
                    && getUUIDHash(this.uuid(), logConfig.send_percent) >= logConfig.send_percent) {
                return;
            }
			
			//os 추가
            if(data.os === null || data.os === undefined) {
                var os = getOs();
                var data = $.extend(data,{
                    os: (os === 'android') ? 'aos' : os
                });
            }
			
			//null, undefined 제거
			$.each(data, function(key, value){
				if( value === null || value === undefined ) delete data[key];
			});

            var aParam = {
                sv: SERVICE_NAME,
                ns: logName,
                ver: VERSION,
                tm: Math.round(new Date().getTime() / 1000),
                ht: "etc",
                cs: CONNECT_SYSTEM,
                uid: uid,
                a: this.buildQuery(data)
            };
            aParam.hash = createHash(aParam);


            //로그 보내는 시간 (default 0)
            //0 이면 발생즉시 보내며
            //다른값이면 sleep( rand( 0~해당값 ) ) 후 전송
            setTimeout(function () {
                $.ajax({
                    type: 'POST'
                    , url: HTTP_COLECTOR_LOG_URL
                    , data: aParam
                    , xhrFields: {
                        withCredentials: false
                    }
                    , async: false
                    , dataType: 'json'
                    , success: function (response) {
                    }
                    , error: function (xhr, ajaxOptions, thrownError) {
                    }
                });
            }, logConfig.rand_send * 1000);
        },
        /**
         * VOD 로깅
         * @param {type} data
         * @returns {undefined}
         */
        vod: function (data) {
            this.sendLog(LOG_NAME.VOD, data);
        },		
		/**
		 * 나중에보기 로깅
		 * @param object data
		 * @returns collector
		 */
        laterView: function (data) {
			var params = {
				uuid: getCookie('_au')
				, ad_uuid: getCookie('OAX')
			};
			
			var data = $.extend(params, data);
			
            this.sendLog(LOG_NAME.LATERVIEW, data);
			return this;
		},
		/**
		 * VOUT 로깅
		 * @param {object} data
		 * @returns {undefined}
		 */
        vout: function (data) {
            this.sendLog(LOG_NAME.VOUT, data);
        },
        /**
         * 즐겨찾기 로깅
         * @param {object} data
         * @returns {undefined}
         */
        fav : function(data) { 
            this.sendLog(LOG_NAME.FAV, data);
        },
        /**
         * CSTATUS 로깅
         * @param {type} data
         * @returns {undefined}
         */
        cstatus : function(data) {
            var params = {
				uuid: getCookie('_au')
				, ad_uuid: getCookie('OAX')
                //, build_ver: ''
                //, os_ver: ''
			};
            data = $.extend(params, data);
            this.sendLog(LOG_NAME.MWEB_STATUS, data);
        },
        /**
         * CLICK 로깅
         * @param {type} data
         * @returns {undefined}
         */
        click : function(data) {
            this.sendLog(LOG_NAME.CLICK, data);
        },
        /**
         * MTHEME 로깅
         * @param {type} data
         * @returns {undefined}
         */
        mtheme : function(data) {
            this.sendLog(LOG_NAME.MTHEME, data);
        },

        sendVodUaLogBtnType : function(btnType) {
            let szEtcData = `location=VOD&action=share&share_type=url&btn_type=${btnType}`;

            $.ajax({
                type : "POST"
                , url : VOD_UA_LOG_URL
                , data : {
                    sztype : 'VOD_UA'
                    , send_data : szEtcData,
                }
                , dataType : 'json'
                , xhrFields: {
                    withCredentials: true
                }
                , beforeSend : function(request) {
                    request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded;charset=utf-8');
                }
                , success : function(response) {
                }
                , error : function(xhr, ajaxOptions, thrownError) {
                }
            });
        }
    };

    return ( window.collector = collector );
});


