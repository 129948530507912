require('jquery');
var angular = require('angular');

angular.module('afreecatv')
	.directive('ngEnter', require('./input.enter.directive'))
	//.directive('ngTouchstart', require('./input.touch.start.directive'))
	.directive('afterlaterlayer', require('./afterlater.layer.directive'))
	.directive('ngContenteditable', require('./contentedable.directive'))
	.directive('tagInput', require('./tag.input.directive'))
	.directive('customSelect', require('./custom.select.directive'))
	.directive('ngContextMenu', require('./context.menu.directive'))
	.directive('bannerSwipe', require('./banner.swipe.directive'))
	.directive('ngScrollGlue', require('./scroll.glue.directive'))
	.directive('checkImg', require('./check.image.directive'))
	.directive('normalIcon', require('./icon.normal.directive'))
	.directive('activeIcon', require('./icon.active.directive'))
	.directive('vodClip', require('./video.clip.directive'))
	.directive('vodCatch', require('./video.catch.directive'))
	.directive('favVideo', require('./favoirte.video.directive'))
	.directive('tagTranslate', require('./tag.translate.directive'))
	.directive('ngUploadChange', require('./upload.image.directive'))
	.directive('searchSwiper', require('./search.swiper.directive'))
	.directive('searchImage', require('./search.image.directive'))
	.directive('inputKorean', require('./input.korean.directive'))
	.directive('playList' , require('./playlist.directive'))
	.directive('onError' , require('./on.error.img'))
	.directive('videoPlaylist' , require('./video.playlist.directive'));

