var toastr = require('toastr');
MyinfoController.$inject = ['$scope', '$rootScope', '$filter', '$state', '$stateParams', '$http', '$timeout', 'Util', 'LoginManager', 'PmService', '$sce', 'gtext']

function MyinfoController($scope, $rootScope, $filter, $state, $stateParams, $http, $timeout, Util, LoginManager, PmService, $sce, gtext) {

    $rootScope.title = gtext('내 정보');
    $scope.note_message = $sce.trustAsHtml("On<span>Live</span>");
    $scope.item = {
        gold: 0,
        starballoon: 0,
        chocolate: 0
    };

    /**
     * 로그아웃
     */
    $scope.logout = function () {
        if (confirm(gtext('로그아웃 하시겠습니까?'))) {
            LoginManager.logout(function () {
                //temp
                location.href="https://id.vplay.vn/api-core/v2/oidc-service/oauth2/session/end?client_id=48b25e130a7f4446&post_logout_redirect_uri=https://m.onlive.vn";
            });
        }

        // LoginManager.logout('');
    };

    /**
     * 비밀번호 변경
     */
    $scope.changePW = function () {
        Util.popup(AUTH_M_AFREECATV + '/password/change');
    };

    /**
     * 내 방송국
     */
    $scope.goMyStation = function () {
        Util.goStation(LoginManager.getUserId());
    }

    /**
     *닉네임 변경페이지
     */
    $scope.goNicknamePage = function () {
        $state.go("myinfo.nickname");
    }

    /**
     * 2차 비밀번호 설정
     *
     */
    $scope.changeSecondPW = function () {
        Util.popup(AUTH_M_AFREECATV + '/password2');
    };


    /**
     * 로그인 기기 관리
     *
     */
    $scope.loginDeviceInfo = function () {
        Util.popup(MEMBER_M_AFREECATV + '/member/a/cookieallow');
    };

    /**
     * 이용 제재 내역 버튼 노출 여부
     *
     */
    $scope.isShowMyPenalty = function () {    
        return true;
    };

    /**
     * 이용 제재 내역
     *
     */
    $scope.viewMyPenalty = function () {
        Util.popup(MEMBER_AFREECATV + '/app/user_penalty.php');
    };

    /**
     * 개인정보 변경
     *
     */
    $scope.changeMyInfo = function () {      
        // sns로 개인정보 변경 시도시
        if($scope.member.ch_mem_code != 1){
            Util.popup(AUTH_M_AFREECATV + '/myinfo/modify');
        }else{
            Util.popup(AUTH_M_AFREECATV + '/myinfo/confirmPwd');
        }
    };

    /**
     * 국내 SNS 여부
     *
     */
    $scope.isDomesticSns = function () {
        if (typeof $scope.member === undefined || typeof $scope.member === 'undefined') {
            return false;
        }
        return ($scope.member.ch_code_name != null);
    };

     /**
     * 계정 연결
     *
     */
    $scope.idConnection = function () {
        Util.popup(MEMBER_M_AFREECATV + '/connection/a/connectionList');
    };

    /**
     *
     * @param {type} num
     * @returns {unresolved}
     */
    $scope.getNewNoteCount = function (n) {
        var new_count = $scope.$parent.new_count ? $scope.$parent.new_count : 0;
        return $sce.trustAsHtml(gtext('새 쪽지 <span>%s</span>개', new_count.numberFormat()));
    }


    $scope.note = function () {
        $state.go('note.receive');
    }

    /**
     * 통계쪽 한국만 표시를 위해 언어반환 함수
     * @returns {navigator.language : default 'en'}
     */
    $scope.getNavigatorLanguage = function () {
        return Util.language()
    }

    /**
     * 포인트서비스 페이지
     */
    $scope.mypoint = function () {
        $state.go('mypoint.pointstatus');
    }

    /**
     * 토큰서비스 페이지
     */
    $scope.mytoken = function () {
        $state.go('mypoint.tokenstatus');
    }

    if (LoginManager.isLogin()) {} else {
        toastr.error(gtext("로그인이 되어 있지 않습니다."))
        setTimeout(function () {
            $state.go("home");
        }, 500);
    }

    $scope.$parent.checkNewNote();

    $scope.goUserInfoPage = function () {
        $state.go("myinfo.userinfo");
    }

}
module.exports = MyinfoController;
