var md5 = require('md5');
var toastr = require('toastr');
IteminfoChocoExchangeController.$inject = ['$scope', '$rootScope','$state', '$stateParams', 'LoginManager', 'ItemService', 'PmService', 'gtext'];

function IteminfoChocoExchangeController($scope, $rootScope,$state, $stateParams, LoginManager, ItemService, PmService, gtext) {
    
    $scope.isLoading = true;
    $scope.chocolateCount= 0;
    $scope.isAvailable = false;
    $scope.itemInfo = [];

    /**
     * 점검 체크 후 로드
     */
    $scope.checkPmAndLoad = function(itemNo) {
        PmService.check({
			szItem: 'quickview',
			szFunction: 'buy',
			szAgent: 'web'
		}).then(function (response) {
            $scope.loadChocolate(itemNo);
		}).catch(function (response) {
			if (response.result == -1 && response.data.code == -1200) {
				ToastMessage(response.data.message);
                $state.go("home");
                return;
			}
            $scope.loadChocolate(itemNo);
		});
    };

	/**
	 * 초콜릿/아이템 정보 조회
	 */
	$scope.loadChocolate = function (itemNo) {
        ItemService.chocolateExchange(
        {
            nItemIdx : itemNo,
            szWork : 'status',
            szDevice : 'mobile'
        })
        .then(function (response) {
            let chocoType = response.DATA.ITEM.choco_type;
            $scope.itemInfo = response.DATA.ITEM;
            $scope.chocolateCount = response.DATA.CHOCO[chocoType];
            if (parseInt($scope.itemInfo.price) <= parseInt($scope.chocolateCount)) {
                $scope.isAvailable = true;
            } else {
                $scope.isAvailable = false;
            }
            $scope.isLoading = false;
            $scope.$digest();
        }).catch(function (response) {
            $scope.isLoading = false;
            if (response.RET == -1) {
                ToastMessage(response.MSG);
                $state.go("home");
                return;
            } else {
                ToastMessage('일시적인 오류가 발생하였습니다.\n잠시 후 다시 이용해주세요.');
            }
            
            $scope.$digest();
        });
	};

	/**
	 * 초콜릿/아이템 교환
	 */
     $scope.exchangeItem = function () {
        let chocoType = $scope.itemInfo.choco_type;
        if(!$scope.isAvailable)
        {
            if(chocoType=='recv')
             {
                ToastMessage('선물받은 초콜릿 개수가 부족하여 교환할 수 없습니다.');
             } else {
                ToastMessage('초콜릿 개수가 부족하여 교환할 수 없습니다.');
             }
             return false;
        }

        ItemService.chocolateExchange(
        {
            nItemIdx : $scope.itemInfo.idx,
            szWork : 'exchange',
            szDevice : 'mobile'
        })
        .then(function (response) {
            if (response.RET == 1) {
                // 히스토리 없이 페이지 전환
                location.replace(M_AFREECATV + "/#/iteminfo/chocoexchanged/"+$scope.itemInfo.bundle+"");
            } else {
                ToastMessage(response.MSG);
            }
            $scope.$digest();
        }).catch(function (response) {
            if (response.RET == -1) {
                ToastMessage(response.MSG);
            } else {
                ToastMessage('일시적인 오류가 발생하였습니다. 잠시 후 다시 이용해주세요.');
            }
            $scope.$digest();
        });
	};

	/**
	 * 뒤로가기 버튼
	 */
	$scope.backHandler = function () {
		if (history.length > 1) { 
			history.back();
		} else { 
			if(afreeca.link.isWebview()) {
				afreeca.link.close();
			} else {
				window.close();
			}
		}
	};

    /**
	 * toastMessage
	 */
	$scope.toastMessage = function (message) {
        ToastMessage(message);
	};

	$rootScope.$on('$loginChange', function (event, value) {
		if (LoginManager.isLogin()) {
            if (!isNaN(Number($stateParams.itemNo)) && $stateParams.itemNo != "") {
                $scope.checkPmAndLoad($stateParams.itemNo);
            } else {
                alert('아이템 정보가 없습니다. 다시 확인해 주시기 바랍니다.');
                $state.go("home");
                return;
            }
		} else {
			toastr.error(gtext("로그인이 되어 있지 않습니다."));
			setTimeout(function () {
				$state.go("home");
			}, 500);
		}
	});
	LoginManager.check();
}

function ToastMessage(message){
    let timeout = null;	
    let nAnimationT = 300;
    let nDelay = 3000;

    let divElement = document.getElementById("toast_message");
    let toastElement =  document.createElement("div");
    toastElement.id = "toast";
    toastElement.className = "toast_message";
    toastElement.style.display = "none";
    let toastTextElement = document.createElement("p");
    toastTextElement.className = "text";
    toastElement.appendChild(toastTextElement);
    toastTextElement.textContent = message;
    toastTextElement.innerHTML = toastTextElement.innerHTML.replace(/\n/g, '<br />');

    divElement.insertBefore(toastElement, divElement.firstChild);
    $(toastElement).fadeIn(nAnimationT);
    
    clearTimeout(timeout);
    timeout = setTimeout(function(){
        $(toastElement).fadeOut(nAnimationT, function(){
            toastElement.remove();
        });
    }, nDelay)
}

module.exports = IteminfoChocoExchangeController;