RecentController.$inject = ['$scope', '$rootScope', 'Util', '$sessionStorage', 'RecentService', 'LiveService', 'gtext']
function RecentController($scope, $rootScope, Util, $sessionStorage, RecentService, LiveService, gtext) {
	$rootScope.title = '최근 본 방송';
	$scope.storage = $sessionStorage;
	$scope.oGroups = {};
	$scope.bShowEmptyBox = true;
	$scope.bHasMore = true;
	console.log(RecentService);

	// order정보
	$scope.orderByColumns = [
		{ id: 'view', name: '참여자순' },
		{ id: 'latest', name: '최신순' }
	];

	if (!$scope.storage.recentOrder) {
		$scope.storage.recentOrder = $scope.orderByColumns[0];
	}

	// 정렬 
	$scope.selecOrderby = function (oOrder) {
		$scope.oGroups = {};
		$scope.storage.recentOrder = oOrder;
		$scope.bCategory = false;
		$scope.bOrder = false;
		getList();
	};

	// 최근본방송 전체 삭제
	$scope.clickDeleteRecentAll = function () {
		if (confirm(gtext('최근 본 방송 내역을 모두삭제하시겠습니까?\n(방송별로 개별 삭제하시려면방송 목록의 더보기 버튼을눌러주세요)'))) {

			RecentService.removeAll();
			$scope.oGroups = {};
			$scope.bShowEmptyBox = true;
		}
	};

	// 최근본방송 삭제
	$scope.clickDeleteRecentItem = function (szUserId) {
		RecentService.remove(szUserId);
		$scope.oGroups = {};
		getList();
	};



	/**
	 * 리스트 호출
	 * @returns {undefined}
	 */
	var getList = function () {
		RecentService.getAll({
			current_page: 1,
			rows_per_page: 100,
			order_by_column: $scope.storage.recentOrder.id || 'view_cnt',

		}).then(function (oRes) {
			if (oRes.data.groups.length) {
				angular.forEach(oRes.data.groups, function (oGroup, nKey) {
					if ($scope.oGroups.hasOwnProperty(oGroup.title)) {
						$.merge($scope.oGroups[oGroup.title].contents, oGroup.contents);
					}
					// 
					else {
						$scope.oGroups[oGroup.title] = oGroup;
					}

					// 모든그룹에 방송이 하나도없을경우  (최근본 방송은 더보기가 없음)
					$scope.bShowEmptyBox = (oGroup.contents.length < 1) ? true : false;
				});
			} else {
				$scope.bShowEmptyBox = true;
			}
			// 로딩 끝
			$scope.bIsLoading = false;
			$scope.$digest();
		}).catch(function (oError) {
			$scope.bIsLoading = false;
			$scope.$digest();
		});
	};

	getList();


}
module.exports = RecentController; 