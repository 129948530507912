var md5 = require('md5');
var toastr = require('toastr');
IteminfoQuickviewController.$inject = ['$scope', '$rootScope', '$state', 'Util', 'LoginManager', 'ItemService', 'PmService', 'gtext'];

function IteminfoQuickviewController($scope, $rootScope, $state, Util, LoginManager, ItemService, PmService, gtext) {

	$scope.defaultSort = [
		{id : "datelist", name : gtext("유효기간 만료순")},
		{id : "latelist", name : gtext("최신순")}
	];

	$scope.firstRun = true;
    
    /**
     * 점검 체크 후 로드
     */
    $scope.checkPmAndLoad = function() {
        PmService.check({
			szItem: 'quickview',
			szFunction: 'buy',
			szAgent: 'web'
		}).then(function (response) {
            $scope.loadQuickview();
		}).catch(function (response) {
			if (response.result == -1 && response.data.code == -1200) {
				toastr.error(response.data.message);
                $state.go("home");
                return;
			}
            $scope.loadQuickview();
		});
    };

	/**
	 * 퀵뷰정렬정보
	 */
	$scope.loadQuickviewSort = function () {

		if($scope.quickviewSelected.id =='datelist'){
			var sort = "exp_date";
		}else{
			var sort = "reg_date";
		}

		ItemService.quickviewSort({
			uno: LoginManager.getUserNo(),
			hvalue: md5(LoginManager.getUserNo() + "AfreecaMobile"),
			szOrder: sort
		}).then(function (response) {
			if (response.STATUS == 1) {
				$scope.$apply(function () {
					$scope.quickview = response;
				});
			}
		})
	};

	/**
	 * 퀵뷰정보
	 */
	$scope.loadQuickview = function () {
		ItemService.quickview({
			uno: LoginManager.getUserNo(),
			hvalue: md5(LoginManager.getUserNo() + "AfreecaMobile")
		}).then(function (response) {
			if (response.STATUS == 1) {
				$scope.$apply(function () {
					$scope.quickview = response;
				});
			}
		})
	};

	/**
	 * 뒤로가기 버튼
	 */
	$scope.backHandler = function () {
		if (history.length > 1) {
			history.back();
		} else {
			if(afreeca.link.isWebview()) {
				afreeca.link.close();
			} else {
				window.close();
			}
		}
	};

	$scope.use = function (coupon) {
		ItemService.use({
			no: LoginManager.getUserNo(),
			couponno: coupon.CouponNo,
			itemid: coupon.ItemID,
			duration: coupon.Duration,
			hvalue: md5(coupon.CouponNo + coupon.ItemID + coupon.Duration + LoginManager.getUserNo() + "AfreecaMobile"),
			sys_type: 'webm',
			location: 'item',
			ndevice: Util.getOS() == 'ios' ? 1 : 2
		}).then(function (response) {
			switch (response.RESULT) {
				case "1":
					$rootScope.$broadcast('$quickviewReload');

					const { UPGRADEFLAG, QUICKVIEWITEMCODE } = response;
					if (UPGRADEFLAG == 1) {
						if (confirm(gtext('퀵뷰가 사용되었습니다.\n퀵뷰 플러스로 전환 기회가 있습니다.\n퀵뷰 플러스로 전환하시겠습니까?'))) {
							$state.go('iteminfo.quickview.upgrade', {
								itemType: 'normal'
							});
						}
					} else if (
						QUICKVIEWITEMCODE == 10000 &&
						UPGRADEFLAG == 4
					) {
						toastr.success(gtext("무료 퀵뷰가 사용되었습니다.<br>무료 퀵뷰는 퀵뷰 플러스로 전환이 불가합니다."));
					} else if (QUICKVIEWITEMCODE == 10004) {
						toastr.success(gtext("무료 퀵뷰 플러스가 사용 되었습니다.<br>무료 퀵뷰 플러스는 애드벌룬이 모이지 않습니다."));
					} else if (QUICKVIEWITEMCODE == 10000) {
						toastr.success(gtext("퀵뷰가 사용되었습니다."));
					} else if (QUICKVIEWITEMCODE == 10003) {
						toastr.success(gtext("퀵뷰 플러스가 사용되었습니다."));
					} else {
						toastr.success(gtext("등록되었습니다."));
					}
					$scope.loadQuickview();
					break;
				case "-200":
					toastr.warning(gtext(response.MESSAGE));
					break;
				default:
					toastr.warning(response.MESSAGE);
					break;
			}
		}).catch(function (error) {
			toastr.warning(gtext("오류가 발생하여 이용하실수 없습니다."));
		});
	};

	/**
	 * 퀵뷰체크
	 */
	$rootScope.$on('$loginChange', function (event, value) {
		//뒤로갔다가 이페이지를 로드할때마다 이함수 호출을 1,2,3,4번씩 계속해서 추가
		if($scope.firstRun){
			if (LoginManager.isLogin()) {
                $scope.checkPmAndLoad();
			} else {
				toastr.error(gtext("로그인이 되어 있지 않습니다."))
				setTimeout(function () {
					$state.go("home");
				}, 500);
			}
			$scope.firstRun=false;
		}
	});
	LoginManager.check();
}

module.exports = IteminfoQuickviewController;