var toastr = require('toastr');
IteminfoQuickviewUpgradeController.$inject = ['$scope', '$rootScope', '$state', '$stateParams', 'Util', 'LoginManager', 'ItemService', 'PmService', 'gtext'];

function IteminfoQuickviewUpgradeController($scope, $rootScope, $state, $stateParams, Util, LoginManager, ItemService, PmService, gtext) {

	$scope.language = Util.getPreferredLocale();
	$scope.startDate = getCurrentDate('start');
	$scope.endDate = getCurrentDate('end');
	$scope.loading = false;

    /**
     * 점검 체크 후 로드
     */
    $scope.checkPmAndLoad = async function() {
		try {
			await PmService.check({
				szItem: 'quickview',
				szFunction: 'buy',
				szAgent: 'web'
			});

			return true;
		} catch (error) {
			if (error.result == -1 && error.data.code == -1200) {
				toastr.error(response.data.message);
				$state.go("home");
				return false;
			}

			return true;
		}
    };

	/**
	 * 인앱 결제 여부 확인
	 * @returns {Promise<boolean>}
	 */
	$scope.checkInAppPurchase = async () => {
		const szOS = Util.getOS(),
			oVersion = Util.getOSVersion(),
			bPlayStore = Util.isPlayStore();

		// 인앱 체크
		if (!afreeca.link.isWebview()) {
			return false;
		}

		// 허용 기기 확인
		const aAllowOS = ['android'];
		if (!aAllowOS.includes(szOS)) {
			return false;
		}

		// 플레이 스토어 확인
		if (!bPlayStore) return false;

		// 인앱 활성화 여부 조회
		const szVersion = oVersion.version;
		try {
			const oResponse = await ItemService.itemInfoApi({
				work: 'IN_APP',
				type: 'quickview',
				os: szOS,
				version: szVersion
			});
			if (oResponse.RESULT !== 1) {
				throw new Error(oResponse.MESSAGE);
			}

			// 인앱 활성화 여부 확인
			if (!oResponse.DATA) {
				return false;
			}
		} catch (error) {
			return false;
		}

		return true;
	};

    /**
     * 퀵뷰 플러스 업그레이드 정보조회
     *
     * @param {boolean} bInApp
     * @returns {Promise<void>}
     */
	$scope.quickviewUpgradeInfo = async (bInApp) => {
		try {
			const response = await ItemService.quickviewUpgradeInfo({
                in_app: bInApp
            });
			if (response.result !== 1 || !(response.data.UPGITEMID > 0) && !(response.data.UPGITEMID !== '')) {
				throw new Error();
			}

			$scope.$apply(() => {
				$scope.upgradeInfo = response.data;
				$scope.upgradeNotice = getUpgradeNotice(response.data, bInApp);
			});
		} catch (error) {
			alert(gtext('유료 퀵뷰, 유료 퀵뷰 선물권만 \n퀵뷰 플러스로 전환 가능합니다.'));
			$state.go("iteminfo");
		}
	};

	/**
	 * 퀵뷰 플러스 업그레이드 구매팝업 호출
	 */	
	$scope.openUpgradeBuy = function () {
		const oVersion = Util.getOSVersion();
		const szModel = oVersion.model,
			szOsVer = oVersion.os_version,
			appVersion = oVersion.version,
			store = oVersion.store;

		var data = {
			ndevice: Util.getOS() == 'ios' ? 1 : 2,
			szWork: 'help',
			szKind: 'TBOX20131007000000',
			szUserID: $rootScope.member.user_id ? $rootScope.member.user_id : '',
			szVersion: afreeca.link.isWebview() ? appVersion : '1.0.0',
			szApp: 'mafreecatv',
			szModel,
			szOsVer,
			broadno: '',
			broad_no: '',
			sys_type: afreeca.link.isWebview() ? 'app' : 'webm',
			location: 'item',
			itemId: $scope.upgradeInfo.UPGITEMID,
			lang:Util.getPreferredLocale(),
			store: afreeca.link.isWebview() ? store : ''
		};

		var szPgUrl = POINT_AFREECATV + '/MobileBuySelectPG.asp?';

		if(afreeca.link.isWebview()) {
			afreeca.link.closeAction(szPgUrl + $.param(data));
		} else {
			// 전환페이지는 아이템 페이지로 이동
			$state.go('iteminfo');
			// 새창페이지는 PG결제 페이지로 이동
			var windowReference = window.open();
			windowReference.location = szPgUrl + $.param(data);
		}
	}

	$scope.openInAppUpgradeBuy = () => {
		const oUpgradeInfo = $scope.upgradeInfo;
		const szProductionId = oUpgradeInfo.UPGPLAYSTOREITEMID,
			szItemId = oUpgradeInfo.UPGITEMID;
		afreeca.link.goInAppPurchase('quickviewupgrade', {
			productionid: szProductionId,
			itemid: szItemId
		});
	};

	/**
	 * 퀵뷰 플러스 자동결제 전환
	 */
	 $scope.autoPayTrial = function () {
		if (!$scope.loading) {
			$scope.loading = true;
			ItemService.quickviewSetAutoTrial().then(function (response) {
				$scope.loading = false;
				const errorCode = [553, 554, 555, 559, 560, 561];
				if (response.result === 1) {
					alert('퀵뷰 플러스로 자동결제 전환이\n완료되었습니다.\n\n'+ $scope.endDate +'부터 매월 퀵뷰 플러스 이용권이\n5,300원 자동결제 됩니다.\n(기존 자동결제수단 유지)');
				} else {
					if (errorCode.indexOf(response.data.RETCODE) !== -1)  {
						alert('퀵뷰 플러스 자동결제 전환에 일시적인 오류가 발생하였습니다.\n잠시 후 다시 시도해주세요.');
					} else {
						alert(response.data.ERRMSG);
					}
				}
				if (afreeca.link.isWebview()) {
					afreeca.link.close();
				} else {
					$state.go("iteminfo" ,{}, { reload: 'iteminfo' });
				}
			})
		}
	};

	/**
	 * 뒤로가기 버튼
	 */
	$scope.backHandler = function () {
		if (history.length > 1) {
			history.back();
		} else {
			if(afreeca.link.isWebview()) {
				afreeca.link.close();
			} else {
				window.close();
			}
		}
	};

	const initProcess = async () => {
		// 점검 체크
		const bIsPM = await $scope.checkPmAndLoad();
		if (!bIsPM) return;

		// 인앱 체크
		const bInApp = await $scope.checkInAppPurchase();
		if (bInApp !== $stateParams.inApp) {
			$state.go('iteminfo.quickview.upgrade', {
				...$stateParams,
				inApp: bInApp
			}, {
				location: 'replace'
			});
			return;
		}

		// 일반 결제 시 퀵뷰 플러스 전환 데이터 조회 && render
		if ($stateParams.itemType === 'normal') {
			await $scope.quickviewUpgradeInfo(bInApp);
		}
	}

	$scope.$on('$loginChange', function (event, value) {
		if (!LoginManager.isLogin()) {
			toastr.error(gtext("로그인이 되어 있지 않습니다."));
			setTimeout(function () {
				$state.go("home");
			}, 500);
		}

		initProcess().then();
	});
	LoginManager.check();

	/**
	 * 업그레이드 내용 세팅
	 *
	 * @param gtext
	 * @param nDay
	 * @param nDuration
	 * @param nPrice
	 * @returns {*}
	 */
	function getUpgradeNotice(oData, bInApp) {
		const nDay = oData.USEDAY,
			nPrice = oData.UPGPRICE,
			nPlayStorePrice = oData.UPGPLAYSTOREPRICE,
			nDuration = oData.UPGDURATION;

		let szPrice = nPrice;
		if (bInApp) {
			szPrice = nPlayStorePrice;
		}

		szPrice = `${szPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
		szPrice = gtext("" + szPrice + "원");

		const szTransMessage = "사용기간 <strong>%s일</strong>로 <span>%s</span> 결제 시 <strong>퀵뷰 플러스 %s일권</strong>으로 전환됩니다.";
		let upgradeNotice = gtext(szTransMessage, nDay, szPrice, nDuration);
		switch (Util.getPreferredLocale()) {
			case 'vi_VN':
				upgradeNotice = gtext(szTransMessage, szPrice, nDay, nDuration);
				break;

			case 'en_US':
				upgradeNotice = gtext(szTransMessage, nDay, nDuration, szPrice);
				break;
		}

		return upgradeNotice;
	}
}

function getCurrentDate(type)
{
	var date = new Date();	
	var oData = date;
	if (type === 'end') {
		var endDate = new Date(date.setDate(date.getDate() + 30));
		oData = endDate;
	}
	var year = oData.getFullYear().toString();
	var month = oData.getMonth() + 1;
	month = month < 10 ? '0' + month.toString() : month.toString();
	var day = oData.getDate();
	day = day < 10 ? '0' + day.toString() : day.toString();

	return year + '.' + month + '.' + day ;
}

module.exports = IteminfoQuickviewUpgradeController;