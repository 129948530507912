MypointStatusController.$inject = ['$scope', '$rootScope', '$state', '$stateParams', '$http', '$timeout', 'Util', 'LoginManager', 'MypointService', 'HistoryState', '$filter']

function MypointStatusController($scope, $rootScope, $state, $stateParams, $http, $timeout, Util, LoginManager, MypointService, HistoryState, $filter) {

    // 기본 조회날자 세팅 (3개월<->오늘자)
    var nLimitMonth = 3;
    var oDate = new Date()
    $scope.endDate = getDateStr(oDate);
    var nMonth = oDate.getMonth()
    oDate.setMonth(nMonth - nLimitMonth)
    $scope.startDate = getDateStr(oDate);
    $scope.pointHistory = [];
    $scope.missionChart = [];
    $scope.nextPageNo = 1;
    $scope.totalCount = 0;
    $scope.perPageLimit = 20;
    $scope.hasMore = false;
    $scope.bPageLoading = true;
    $scope.pageNo = 1;
    $scope.startDateModel = new Date($scope.startDate);
    $scope.endDateModel = new Date($scope.endDate);
    $scope.defaultStartDate = $scope.startDate;
    $scope.defaultEndDate = $scope.endDate;
    $scope.callType = 'point';
 
    // 히스토리 내역 조회
    $scope.history = function (nPageNo) {
        $scope.bPageLoading = true;
        $scope.hasMore = false;
        $scope.pageNo = nPageNo;

        // 조회값 가능 범위를 벗어난 경우 (기본값으로 변경)
        if ($scope.startDate < $scope.defaultStartDate) {
            $scope.startDate = $scope.defaultStartDate;
        }

        if ($scope.endDate > $scope.defaultEndDate) {
            $scope.endDate = $scope.defaultEndDate;
        }

        var nRandNo = '';
        if (!$rootScope.cacheFlag) {
            nRandNo = new Date().getTime();
        }

        MypointService.history(LoginManager.getUserId(), $scope.startDate, $scope.endDate, $scope.callType, nPageNo, nRandNo)
        .then(function (response) {
            if (nPageNo == 1) {
                $scope.pointHistory = response.DATA;
            } else {
                $.merge($scope.pointHistory, response.DATA);
            }

            $scope.totalCount = response.TOTAL_COUNT;
            $scope.bPageLoading = false;

            // 전체 페이지 수
            var nTotalPageCount = Math.ceil($scope.totalCount/$scope.perPageLimit);

            // 추가 페이지 여부
            if (nPageNo < nTotalPageCount) {
                $scope.hasMore = true;
                $scope.nextPageNo = nPageNo+1;
            }

            if ($scope.pointHistory.length === 0) {
                Util.moveScroll(200);
            }

            $scope.$digest();

        }).catch(function (response) {
            $scope.bPageLoading = false;
            $scope.$digest();
        });
    }

    // 페이지 더보기
    Util.lastItem(function () {
        if ($scope.bPageLoading == false && $scope.hasMore) {
            $scope.amount();
            $scope.history($scope.nextPageNo);
        }
    });

    // 포인트 미션그룹별 달성수치
    $scope.mission = function () {
        var aCategoryLists = ["LIVE", "VOD", "STATION", "ETC"];
        MypointService.mission(LoginManager.getUserId())
        .then(function (response) {
            $scope.missionChart = angular.copy(response.DATA);
            $timeout(function(){
                if (Object.keys($scope.missionChart).length > 0) {
                    $.each(response.DATA, function(key, value) {
                        if (aCategoryLists.indexOf(key) !== -1) {
                            // 오늘의 포인트통계 노출
                            drawChart('canvas_'+key+'');
                        }
                    });
                }
            });
            $scope.$digest();
        }).catch(function (response) {
            $scope.$digest();
        });
    }

    // 내역조회
    $scope.goSearch = function () {
        $scope.startDate = $("#oStartPicker").val();
        $scope.endDate = $("#oEndPicker").val();

        if ($scope.startDate > $scope.endDate) {
            alert('조회 범위가 잘못되었습니다. 선택한 날짜를 확인해주세요.');
            return false;
        } else if ($scope.startDate < $scope.defaultStartDate) {
            alert('최근 3개월 이내 기록만 조회 가능합니다.');
            return false;
        }

        $scope.mission();
        $scope.amount();
        $scope.history(1);
    }

    // 포인트 내역삭제
    $scope.deleteHistory = function () {
        if (confirm("모든 포인트 내역을 삭제합니다. \n내역 삭제 시, 보유 중인 포인트 수치는 변하지 않습니다.")) {
            MypointService.deleteHistory(LoginManager.getUserId(),{s_type : 'point'})
            .then(function (response) {
                if (response.status == 204) {
                    $scope.mission();
                    $scope.amount();
                    $scope.history(1);
                } else {
                    alert('잠시 후 다시 시도해주세요.');
                }
                $scope.$digest();
            }).catch(function (response) {
                if (response.data.RESULT == -1) {
                    alert(response.DETAIL.message);
                }
                $scope.$digest();
            });
        }
    }

    // 내역 날자형식 변환
    $scope.setDateConvert = function (date) {
        date = date == null ? date = '' : date.substr(0,16).replace(/-/g,".");

        return date;
    }

    // 조회 날자형식 변환
    function getDateStr(date) {
        var nMonth = date.getMonth() + 1;
        var nDay = date.getDate();
        if (nMonth < 10) {
            nMonth = "0" + nMonth;
        }
        if (nDay < 10) {
            nDay = "0" + nDay;
        }

        return (date.getFullYear() + '-' + nMonth + '-' + nDay)
    }

    // 포인트 미션차트 생성
    function drawChart(element){
        var can = document.getElementById(element);
        if (can !== null) {
            var c = can.getContext('2d');
            var percentage = $('#'+element+'').attr('data-percent');
            var posX = can.width / 2,
                posY = can.height / 2,
                fps = 1000 / 200,
                oneProcent = 360 / 100,
                result = oneProcent * percentage;
            var graY = (percentage <= 50) ? (100 - percentage) : percentage;
            var graX = (percentage <= 50) ? 0 : 100;

            c.lineCap = 'butt';

            var gradient = c.createRadialGradient(posX,posY,100,graX,graY,50);
                gradient.addColorStop(1, '#a675ff');
                gradient.addColorStop(0, '#4f78ff');

            function arcMove(color) {
                var deegres = 0;
                var acrInterval = setInterval (function() {
                    deegres += 1;
                    c.clearRect( 0, 0, can.width, can.height );

                    c.beginPath();
                    c.arc( posX, posY, 52, (Math.PI/180) * 270, (Math.PI/180) * (270 + 360) );
                    c.strokeStyle = '#e4e7ea';
                    c.lineWidth = '7';
                    c.stroke();

                    c.beginPath();
                    c.arc( posX, posY, 52, (Math.PI/180) * 90, (Math.PI/180) * (90 + deegres) );
                    c.strokeStyle = color;
                    c.lineWidth = '7';
                    c.stroke();
                    
                    if( deegres >= result ) clearInterval(acrInterval);
                }, fps);
            }
            arcMove(gradient);
        }
    }

   if (typeof $rootScope.member == 'undefined') {
        $rootScope.$on('$loginChange', function (event, value) {
            if (value) {
                $scope.history(1);
                $scope.mission();
            } else {
                if (afreeca.link.isWebview()) {
                    location.href = "onlive://login/requireLogin";
                } else {
                    toastr.error("Bạn không đăng nhập");
                    setTimeout(function () {
                        $state.go("home");
                    }, 500);
                }
            }
        });
        LoginManager.check();
    } else {
        $scope.history(1);
        $scope.mission();
    }
}

module.exports = MypointStatusController;
