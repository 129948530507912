var angular = require("angular");
var uirouter = require("angular-ui-router");
var gettext = require("angular-gettext");
var carousel = require("angular-carousel");
var touch = require("angular-touch");
var ngStorage = require("ngstorage");
var oclazyload = require("oclazyload");
var ngSanitize = require("angular-sanitize");
var ngCookies = require('angular-cookies');
var jquery = require("jquery");
var collector = require("collector");
var angularDebounce = require("angular-debounce");
var angularLazyImg = require("../node_modules/angular-lazy-img/dist/angular-lazy-img");
if (typeof Promise === "undefined") {
	window.Promise = require("promise-polyfill").default;
}
var mobileLivePlayer = null;
var liveView = null;
window.playerWasLoaded = false;

angular.uppercase = function(text) {
	return text.toUpperCase();
};
angular.lowercase = function(text) {
	return text.toLowerCase();
};
angular
	.module("afreecatv", [uirouter, ngStorage.name, gettext, oclazyload, ngCookies, "ngSanitize", "angularLazyImg", "angular-carousel", "rt.debounce"])
	.config(require("./config"))
	.config(require("./route"))
        .run(require("./constant"))
	.run(require("./run"))
	.run(require("./locale"));
require("./directives");
require("./filters");
require("./factorys");
require("./services");
require("helper");
collector.init();

//모바일 메인 UV
window.szLogAction = "MOBILE_MAIN_PAGE";
$.getScript(ANALYSIS_AFREECATV + "/_au.js");
