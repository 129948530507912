var md5 = require('md5');
var toastr = require('toastr');
IteminfoChocoExchangeController.$inject = ['$scope', '$rootScope', '$state', '$stateParams', '$http', '$timeout', 'Util', 'LoginManager', 'ItemService', 'PmService', 'gtext'];

function IteminfoChocoExchangeController($scope, $rootScope, $state, $stateParams, $http, $timeout, Util, LoginManager, ItemService, PmService, gtext) {

    $scope.itemImg = "img_qv_gif7";
    $scope.itemName = "";

	/**
	 * 교환 아이템 정보
	 */
     $scope.exchangedItemInfo = function () {
        switch ($stateParams.bundle) {
            case "4" :
                $scope.itemImg = "img_qv_gif7";
                $scope.itemName = "퀵뷰 7일 이용권";
                break;
            case "8" :
                $scope.itemImg = "img_qv_gif7";
                $scope.itemName = "퀵뷰 플러스 7일 이용권";
                break;
            default :
                $scope.itemImg = "img_qv_gif7";
                $scope.itemName = "";
                break;
        }
	};

	/**
	 * 뒤로가기 버튼
	 */
     $scope.backHandler = function () {
		if (history.length > 1) { 
			history.back();
		} else { 
			if(afreeca.link.isWebview()) {
				afreeca.link.close();
			} else {
				window.close();
			}
		}
	};

	$rootScope.$on('$loginChange', function (event, value) {
		if (LoginManager.isLogin()) {
            if (!isNaN(Number($stateParams.bundle)) && $stateParams.bundle != "") {
                $scope.exchangedItemInfo();
            } else {
                alert('아이템 정보가 없습니다. 다시 확인해 주시기 바랍니다.');
                $state.go("home");
                return;
            }
		} else {
			toastr.error(gtext("로그인이 되어 있지 않습니다."));
			setTimeout(function () {
				$state.go("home");
			}, 500);
		}
	});
	LoginManager.check();
}

module.exports = IteminfoChocoExchangeController;