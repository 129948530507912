MyTokenItemDetailController.$inject = ['$scope', '$rootScope', '$state', '$stateParams', 'LoginManager', 'MypointService', '$sce', 'PmService']
function MyTokenItemDetailController($scope, $rootScope, $state, $stateParams, LoginManager, MypointService, $sce, PmService) {

    $scope.purchaseDetailInfo = [];
    $scope.useDetailInfo = [];
    $scope.availableToken = 0;
    $scope.notice_message = '';
    $scope.expire_date = '';
    $scope.period_date = '';
    $scope.use_type = '';
    $scope.item_price = null;

    // 이전 페이지로 이동
    $scope.back = function () {
        history.back();
    }

    // 사용가능 크레딧 조회
    $scope.amount = function () {
        MypointService.amount(LoginManager.getUserId())
        .then(function (response) {
            $scope.availableToken = response.DATA.AVAILABLE_TOKEN;
            $scope.$digest();
        }).catch(function (response) {
            $scope.$digest();
        });
    }

    // 아이템 구매 정보조회
    $scope.itemPurchaseDetail = function () {
        MypointService.itemPurchaseDetail($stateParams.itemNo)
        .then(function (response) {            
            $scope.purchaseDetailInfo = angular.copy(response.DATA);
            $scope.item_price = $scope.purchaseDetailInfo.token_price;
            $scope.expire_date = $scope.setDateFormat($scope.purchaseDetailInfo.expire_date);
            $scope.use_type = $scope.purchaseDetailInfo.use_type;
            $scope.$digest();
        }).catch(function (response) {
            if (response.data.RESULT == -1) {
                alertErrorMessage(response.data);
            }
            $scope.$digest();
        });
    }

    // 아이템 사용 정보조회
    $scope.itemUseDetail = function () {
        MypointService.itemUseDetail(LoginManager.getUserId(),$stateParams.itemNo)
        .then(function (response) {
            $scope.useDetailInfo = angular.copy(response.DATA);
            if ($scope.useDetailInfo.reg_date !== null && $scope.useDetailInfo.expire_date !== null) {
                $scope.period_date = $scope.setDateFormat($scope.useDetailInfo.reg_date) + ' ~ ' 
                + $scope.setDateFormat($scope.useDetailInfo.display_expire_date);
            }
            $scope.use_type = $scope.useDetailInfo.use_type;
            $scope.$digest();
        }).catch(function (response) {
            if (response.data.RESULT == -1) {
                alertErrorMessage(response.data);
            }
            $scope.$digest();
        });
    }

    // 아이템 구매하기
    $scope.itemPurchase = function () {
        // 본인확인 진행
        if (LoginManager.isRealName() == false) {
            $scope.checkRealName();
            return false;
        }
        // 퀵뷰상품의 경우 점검확인 진행
        if ($scope.use_type == 2) {
            PmService.check({
                szItem: 'quickview',
                szFunction: 'buy',
                szAgent: 'web'
            }).then(function (oRes) {
                $scope.setPurchase();
            }).catch(function (response) {
                if (response.result == -1 && response.data.code == -1200) {
                    alert(response.data.message);
                    return false;
                }
                $scope.setPurchase();
            });
        } else {
            $scope.setPurchase();
        }
    }

    $scope.setPurchase = function () {
        if ($scope.item_price == null || $scope.item_price < 0) {
            alert('상품구매시 오류가 발생하였습니다. 잠시 후 다시 시도해주세요.');
            return false;
        }
        if ($scope.availableToken < $scope.item_price) {
            alert('보유한 크레딧이 부족합니다.');
            return false;
        }
        // 구매진행
        MypointService.itemPurchase($stateParams.itemNo)
        .then(function (response) {
            if (response.RESULT == 1) {
                if ($scope.use_type == 2) {
                    alert('퀵뷰 상품을 구매했습니다. 보유 중인 퀵뷰를 확인하세요.');
                } else if ($scope.use_type == 3) {
                    alert('퀵뷰 플러스 상품을 구매했습니다. 보유 중인 퀵뷰 플러스를 확인하세요.');
                } else {
                    alert('크레딧 상품을 구매했습니다. 미사용 상품을 확인하세요.');
                }
                history.back();
            } else {
                alert('잠시 후 다시 시도해주세요.');
            }
            $scope.$digest();
        }).catch(function (response) {
            if (response.data.RESULT == -1) {
                alertErrorMessage(response.data);
            }
            $scope.$digest();
        });
    }

    // 아이템 사용하기
    $scope.itemUse = function () {
        // 본인확인 진행
        if (LoginManager.isRealName() == false) {
            $scope.checkRealName();
            return false;
        }
        var szConfirmMessage = '';
        if ($scope.use_type == 0) {
            szConfirmMessage = '반드시 담당 직원이 직접 확인해야 합니다. 5분 내에 승인되지 않는 이용권은 처음 상태로 돌아갑니다.';
        } else if ($scope.use_type == 1) {
            szConfirmMessage = '지금 이용권을 사용하시겠습니까?';
        }

        if (confirm(szConfirmMessage)) {
            MypointService.itemUse(LoginManager.getUserId(),$stateParams.itemNo)
            .then(function (response) {
                if (response.RESULT == 1) {
                    history.back();
                } else {
                    alert('잠시 후 다시 시도해주세요.');
                }
                $scope.$digest();
            }).catch(function (response) {
                if (response.data.RESULT == -1) {
                    alertErrorMessage(response.data);
                }
                $scope.$digest();
            });
        }
    }

    // Date Format 설정
    $scope.setDateFormat = function (date) {
        date = date == null ? date = '' : date.substr(0,10).replace(/-/g,".");
        return date;
    }

    // 본인확인 페이지 호출
    $scope.checkRealName = function () {
        if (confirm("본인 확인이 필요한 서비스 입니다. 진행하시겠습니까?")) {
            window.open(AUTH_M_AFREECATV + '/realname?type=popup');
        }
    };

    // 아이템 설명 (html style 적용)
    $scope.explainMessageHtml = function(message) {
        return $sce.trustAsHtml(message);
    };

    // API 호출 - 예외메시지 출력
    function alertErrorMessage(response) {
        alert(response.DETAIL.message);
        $state.go("mypoint.item");
    }

    // 이용권 타입별 정보호출
    function getItemInfo(type) {
        switch ($stateParams.actionType) {
            case 'purchase' : 
                return $scope.itemPurchaseDetail();
                break;
            case 'use' :
            case 'expire' :
                return $scope.itemUseDetail();
                break;
            default :
                break;
        }
    }

    if (typeof $rootScope.member == 'undefined') {
        $rootScope.$on('$loginChange', function (event, value) {
            if (value) {
                $scope.amount();
                getItemInfo($stateParams.actionType);
            } else {
                if (afreeca.link.isWebview()) {
                    location.href = "onlive://login/requireLogin";
                } else {
                    toastr.error("Bạn không đăng nhập");
                    setTimeout(function () {
                        $state.go("home");
                    }, 500);
                }
            }
        });
        LoginManager.check();
    } else {
        $scope.amount();
        getItemInfo($stateParams.actionType);
    }
}

module.exports = MyTokenItemDetailController;
