var Swiper = require("swiper/dist/js/swiper.min");
NoteSpamViewController.$inject = ['$scope', '$rootScope', '$state', '$stateParams', '$http', '$timeout', 'Util', 'LoginManager', 'NoteService', 'HistoryState', '$sce', 'gtext']

function NoteSpamViewController($scope, $rootScope, $state, $stateParams, $http, $timeout, Util, LoginManager, NoteService, HistoryState, $sce, gtext) {
    $scope.notes= [];
    $scope.isDisabled = false;
    	//초기화
	$scope.note = {
		read_date: 0
    };
    
    var oDate = new Date();
    $scope.date = oDate.getFullYear() + '-' + ((oDate.getMonth()+1).addZero()) + '-' + oDate.getDate().addZero() + ' ' + oDate.getHours().addZero() + ":" + oDate.getMinutes().addZero() + ":" + oDate.getSeconds().addZero();

    $scope.byteConvert = function (bytes) {
        var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes == 0) return '0 Byte';
        var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
        bytes = Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
        return bytes;
    };

    var mySwiper = null;
    
    $rootScope.headerClassRemove();

    $scope.layers = function (target, index) {
        if (mySwiper == null) {
            mySwiper = new Swiper('.swiper-container', {
                navigation: {
                    nextEl: '.viewer-next',
                    prevEl: '.viewer-prev',
                },
                pagination: {
                    el: '.swiper-pagination',
                }
            });
        }
        $scope.layerOpen = true;
        mySwiper.slideTo(index);
    };
    $scope.layersClose = function (target) {
        $scope.layerOpen = false;
    };

	$scope.prev = function () {
		history.back();
	}

    /**
     * 수신거부
     */
    $scope.reject = function () {
        $scope.isDisabled = true;
        NoteService.receviceReject({
            no: $scope.note.no
        }).then(function (response) {
            if (!confirm(gtext("수신거부가 완료되었습니다. (운영자 ID 제외)\n지금 수신거부 설정에서 확인하시겠습니까?"))) {
                $scope.isDisabled = false;
                $scope.$digest();
                return;
            } else {
                $rootScope.setting();
                $scope.isDisabled = false;
                $scope.$digest();
            }
        }).catch(function (response) {
            $scope.isDisabled = false;
            alert(response.data.message);
            $scope.$digest();
        });
    }

    /**
     * 스팸 해제
     */
    $scope.spamRaise = function () {
        var aRaiseNo = [];
        if(!confirm(gtext('해당 쪽지를 스팸 해제하시겠습니까?\n(스팸 해제된 쪽지는 받은 쪽지로 이동됩니다.)'))) {
            return;
        }
        aRaiseNo.push($scope.note.no);
        NoteService.spamRaise({
            recv_no : aRaiseNo
        }).then(function (response) {
            $scope.isDisabled = false;
            history.back();
        }).catch(function (response) {
            $scope.isDisabled = false;
            $scope.$digest();
        });
    }

    	/**
	 * 삭제하기
	 */
	$scope.delete = function (szBeforeAction) {
		if (!confirm(gtext('삭제하시겠습니까?'))) {
			return;
		}
		NoteService.receviceDelete({
			no: $scope.note.no
		}).then(function (response) {
			$scope.isDisabled = false;
			history.back();
		}).catch(function (response) {
			$scope.isDisabled = false;
			$scope.$digest();
		});
    }
    /**
     * 답장
     */
	$scope.reply = function () {
		if ($rootScope.bBlock) {
			alert($rootScope.szBlockMsg);
			return false;
        }
        $rootScope.bNickClick = false;
		$state.go('note.write', {
			id: $scope.note.send_id
		});
	}
	NoteService.receviceView({
		no: $stateParams.no
	}).then(function (response) {
        $scope.note = response.data;
        $scope.bLoginAdmin = $scope.note.bAdmin ? true : false;
        $scope.note.content = Util.eacapeBackslash($scope.note.content);
		$scope.$digest();
	}).catch(function (response) {
		alert(response.data.message);
		$state.go('note.spam', {
			deleteNo: $stateParams.no
		});
    });

    goAosMove = function (url) {
        afreeca.link.browser(url);
    }

	goItemMove = function (url, scheme) {
        // 앱내의 웹뷰 일때
        if(afreeca.link.isWebview()) {
			var schemeUrl = scheme == '' ? ('onlive://browser/station?url=' + encodeURIComponent(url)) : scheme;
			document.location.href = schemeUrl;
            return false;
		} else { // 모웹
			window.open(url, "_blank");
            return false;
        }
    }
    
    $scope.checkAtag = function (szContent, szNoteType, nSubType) {
        return Util.tagAchange('view', szContent, $scope.bLoginAdmin, szNoteType, nSubType);
    }

    $scope.nickClick = function ($event, bNickClick) {
		$event.stopPropagation();
		$event.preventDefault();
		$rootScope.bNickClick = !bNickClick;
		return $rootScope.bNickClick;
	}
	
	$scope.showIct = function () {
		return $rootScope.bNickClick ? '' : 'none';
	}

	// 방송국 가기
	$scope.goBjStation = function(szUserId){
		$rootScope.bNickClick = false;
		if(Util.getOS() == 'android' && afreeca.link.isWebview()) {
			goAosMove(BJ_AFREECATV + '/'+szUserId);
			return;
		}
		Util.goStation(szUserId);
	}

	$scope.searchUser = function() {
		$state.go('note.spam', {deleteNo: null,searchWord: $scope.note.send_id, searchType: 2}, {reload: 'note.receive'});
	}

	$scope.ictClose = function () {
        $rootScope.bNickClick = false;
    }
    
}
module.exports = NoteSpamViewController;

Number.prototype.addZero = function () {
    return ('0' + this).substr(-2);
}