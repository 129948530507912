var toastr = require('toastr');
LivePlayerChatController.$inject = ['$scope', '$rootScope', '$location', '$timeout', '$state', '$stateParams', '$window', '$http', '$filter', 'ChatFactory', 'Util', 'ItemFactory', 'LoginManager', '$sessionStorage', 'EmoticonService', 'RecommanderService', 'PmService', '$sce', 'gtext', 'TokenFactory']

function LivePlayerChatController($scope, $rootScope, $location, $timeout, $state, $stateParams, $window, $http, $filter, ChatFactory, Util, ItemFactory, LoginManager, $sessionStorage, EmoticonService, RecommanderService, PmService, $sce, gtext, TokenFactory) {
	var _broadInfo = {};
	var _bChat = false;
	var replaceHtmlEntites = function (s) {
		var translate_re = /&(nbsp|amp|quot|lt|gt);/g;
		var translate = {
			"nbsp": " ",
			"amp": "&",
			"quot": "\"",
			"lt": "<",
			"gt": ">"
		};
		return (s.replace(translate_re, function (match, entity) {
			return translate[entity];
		}));
	};

	var changed; //최초 login 동기
	$scope.aChat = [{
		'class': 'space'
	}, {
		'class': 'space'
	}];
	$scope.viewer = 0;
	$scope.bSocket = true;
	//$scope.bNotSupportChat = (/iphone|ipod/i.test(navigator.userAgent)); //아이폰/아이팟은 채팅을 지원하지 않음
    $scope.bNotSupportChat = false;
    $scope.chatPlaceHolder = gtext('채팅 메세지를 입력하세요.');
	$scope.bChatvisible = true;
	$scope.bAutoScroll = true; //채팅자동스크롤 여부
	$scope.chatNotice = {
		showPossible: true,
		state: false,
		notShow: false,
		message: "",
		open: false,
		new: false
	};
	$rootScope.szSentStarballoon = false; // 중국사용자가 별풍선을 선물했는지 여부값

	$scope.storage = $sessionStorage;
	if (!$scope.storage.hideChatting)
		$scope.storage.hideChatting = {};

	$scope.emoticonPages = [];
	var emoticons = angular.copy(EmoticonService.getEmoticons());
	while (emoticons.length > 0) {
		var page = [];
		if (emoticons.length == 0)
			break;
		for (var i = 0; i < 17; i++) {
			if (emoticons.length == 0) {
				page.push({
					"file": "blank.png",
					"word": "/공백/",
					"type": "blank"
				});
			} else {
				page.push(emoticons.shift());
			}
		}
		page.push({
			"file": "btn_delete.png",
			"word": "/공백/",
			"type": 'back'
		});
		
		$scope.emoticonPages.push(page);
	}



	var blockCleanUp = function (chat_id) {

		var cnt = $scope.aChat.length;
		for (var i = (cnt - 1); i >= 0; i--) {
			var chart = $scope.aChat[i];
			if (chart.chat_id == chat_id) {
				$scope.aChat.splice(i, 1);
			}

		}
	}

	$scope.$on('$chat.status', function (event, status, broadInfo) {
		if ($scope.bNotSupportChat)
			return;
		switch (status) {
			case 'init':
				_broadInfo = broadInfo;
				ChatFactory.init(_broadInfo, $location.search());
				break;
			case 'start':
				$scope.bPlay = true;
				ChatFactory.play();
				break;
			case 'stop':
				ChatFactory.stop();
				$scope.aChat = [];
				$scope.bSocket = false; //채팅 비활성화
				break;
		}
	});
	$scope.$on('$chat.recommend', function (event) {

		var info = ChatFactory.getRecommandServer();
		var szIp = info[0];
		var szPort = info[1];
		if (info.length < 2) {
			if (confirm(gtext("UP은 아프리카TV앱에서 하실 수 있습니다.\n\n아프리카TV 앱에서 참여하시겠습니까?"))) {
				$scope.goLive();
			}
			return true;
		}

		if (LoginManager.isLogin()) {
			/**
			 * 카테고리를 불러오기
			 */
			RecommanderService.encode({
				szBjId: _broadInfo.bj_id,
				nBroadNo: _broadInfo.broad_no
			}).then(function (oRes) {

				RecommanderService.recomand({
					szBjId: _broadInfo.bj_id,
					nBroadNo: _broadInfo.broad_no,
					szIp: szIp,
					szPort: szPort,
					szData: oRes.MSG
				}).then(function (oRes) {
					toastr.success(_broadInfo.user_nick + "đã được UP");
					// toastr.success(oRes.MSG)
				}).catch(function (oRes) {
					if (oRes.MSG)
						toastr.warning(oRes.MSG);
					else
						toastr.warning(oRes.data.message);

				});
			}).catch(function (err) {
				toastr.warning(err.data.message);
			});
		} else {
			$rootScope.login();
		}
	});


	/**
	 * 1분에 한번씩 채팅이 많으면 정리
	 * @returns {undefined}
	 */
	var chatCleanUp = function () {
		//채팅 일정갯수 이상이면 삭제
		//300개만 남기고 삭제
		$scope.aChat.splice(0, $scope.aChat.length - 300);
		$scope.$digest();
		$timeout(chatCleanUp, 1000 * 60);
	};

	$timeout(chatCleanUp, 1000 * 60);



	/**
	 * 채팅메시지
	 */
	ChatFactory.loginHandler(function (chat_id) {
		//채팅서버로 부터 채팅ID 받아옴 > 선물하기 할때 사용
		$scope.$parent.chat_id = chat_id;
	});

	ChatFactory.socketNotSupportHandler(function (value) {
		$scope.bSocket = !value;
		$scope.chatPlaceHolder = gtext("채팅입력이 지원되지 않는 브라우져입니다.");
		toastr.warning(gtext("채팅입력이 지원되지 않는 브라우져입니다. 아프리카TV앱을 이용해주세요"));
	});

	/**
	 * 채팅메시지
	 */
	ChatFactory.messageHandler(function (chat) {
		if ($scope.$parent.bBlind)
			return;

		//채팅메세지 일때
		if (chat.chat_id !== undefined && chat.chat_id !== '') {
			if ($scope.checkHideChat(chat.chat_id)) {
				return false;
			}
		}

		chat.message = EmoticonService.getEmoticonMessage(chat);
		$scope.aChat.push(chat);
		$scope.$digest();
	});

	/**
	 * 접속종료
	 */
	ChatFactory.closeHandler(function () {
        $scope.$parent.broadState = 1; 
        $scope.$parent.$apply();
        location.replace('#/home');
	});

	/**
	 * 방문자
	 */
	ChatFactory.viewerHandler(function (cnt) {

		$scope.$parent.viewer = cnt;
		$scope.$parent.$apply();
	});

	ChatFactory.viewerBoxHandler(function (aViewerCnt) {

		$scope.$parent.viewer = aViewerCnt['total_viewer'];
		$scope.$parent.nCurrentViewer = aViewerCnt['current_broad_viewer'];
		$scope.$parent.nPcViewer = aViewerCnt['current_broad_pc'];
		$scope.$parent.nMobileViewer = aViewerCnt['current_broad_mobile'];
		$scope.$parent.nDiffViewer = aViewerCnt['diff_broad_viewer'];
		$scope.$parent.nAccumulateViewer = aViewerCnt['accumulate_viewer'];
		$scope.$parent.nBroadType = aViewerCnt['broad_type'];
		$scope.$parent.$apply();
	});


	/**
	 * 채팅내용 삭제
	 */
	ChatFactory.clearHandler(function (cnt) {
		console.log('clearHandler')
		$scope.aChat = [];
	});

	ChatFactory.blockChatClearHandler(function (block_id) {
		blockCleanUp(block_id);
	});

	ChatFactory.giftHandler(function (type, nickname, count, code) {
		var giftEffect = {};
		giftEffect.nickname = $sce.trustAsHtml(nickname);
		giftEffect.count = count;
		giftEffect.type = type;
		giftEffect.class = '';
		switch (type) {
			case 'starballoon':
				/*var key = count;
				giftEffect.image = ItemFactory.getStarBalloon(key);
				var msg = {
					'class': 'ntc',
					'nickname': $sce.trustAsHtml(''),
					'message': '<img src="http://gift.img.onlive.vn/gift.php?szType=chat_img&szKey=' + key + '" alt="" style="width:auto;height:auto;" />'
				};
				$scope.aChat.push(msg);*/
                giftEffect.image = ItemFactory.getStarBalloon(count, code);
				giftEffect.title = "별풍선";
				break;
			case 'sticker':
				giftEffect.image = ItemFactory.getSticker(code)
				giftEffect.title = "스티커";
				break;
			case 'chocolate':
				giftEffect.image = ItemFactory.getChocolate(count);
				giftEffect.title = "초콜릿";
				break;
			case 'vodballoon':
				/*var key = count;
				giftEffect.image = ItemFactory.getStarBalloon(key);
				var msg = {
					'class': 'ntc',
					'nickname': $sce.trustAsHtml(''),
					'message': '<img src="http://gift.img.onlive.vn/gift.php?szVod=1&szType=chat_img&szKey=' + key + '" alt="" style="width:auto;height:auto;" />'
				};
				$scope.aChat.push(msg);*/
                giftEffect.image = ItemFactory.getStarBalloon(count, code);
				giftEffect.title = "별풍선";
				break;
			case 'adcon':
				giftEffect.class = 'item-adballoon';
				giftEffect.image = code;
				giftEffect.title = "애드벌룬";
				break;
            case 'videoballoon':
                giftEffect.image = ItemFactory.getVideoballoon(count, code);
				giftEffect.title = "영상풍선";
                break;
		}


		$('.item_area').show('fast')
			.animate({
				opacity: 1
			}, {
				start: function () {
					$scope.gift = giftEffect;
					$scope.$digest();
				}

			})
			.animate({
				zoom: 1
			}, {
				duration: 1000
			}) //1초동안 대기
			.animate({
				opacity: 0
			}) //사라지기
			.hide('fast');
	});

	/**
	 * 경고메시지
	 */
	ChatFactory.alertHandler(function (code, quitChCount) {
		if (code == 19) {
			if (confirm(gtext('지금 참여하시던 방송은 청소년 유해매체물로서 정보통신망 이용촉진 및 정보보호 등에 관한 법률 및 청소년 보호법의 규정에 의해\n 만19세 미만의 청소년이 이용할수 없도록 방송정보가 변경되었습니다.\n 계속참여하려면 앱을 이용하여 본인 인증이 필요합니다.'))) {
				$scope.$parent.state = 2;
				$scope.$parent.$apply();
				//앱으로 이동
				Util.live(_broadInfo.bj_id, _broadInfo.broad_no);
				return;
			} else {
				$scope.$parent.state = 2;
			}
			$scope.$parent.$apply();
			$scope.$parent.stop();
		} else if (code == 18) //**************************화면 보여주는 작업, 해당하는 조건에 따라 데이터 세팅
		{


			$scope.$parent.nCount = quitChCount;
			$scope.$parent.szLightbox = {
				"visibility": "visible"
			};
			if (typeof quitChCount != "undefined") {

				$scope.$parent.bLeave = true;


			} else {

				$scope.$digest();
				$state.go("home");
			}


			if (quitChCount >= 1 && quitChCount <= 3) {

				$scope.$parent.message = "강제퇴장";
				$scope.$parent.szChatMessage = "(채팅금지 24시간)";
				$scope.$parent.szImage = "af1";


			} else if (quitChCount == 4) {
				$scope.$parent.message = "강제퇴장 및 3일 이용정지";
				$scope.$parent.szImage = "af2";
				$scope.$parent.newLine = true;


			} else if (quitChCount >= 5) {

				$scope.$parent.message = "강제퇴장 및 7일 이용정지";
				$scope.$parent.over = " 이상~";
				$scope.$parent.szImage = "af2";
				$scope.$parent.newLine = true;


			}
			$scope.$digest();


			//	$state.go("home");
		} else if (code == 20) {
			$scope.$parent.bBlind = true;
			$scope.$parent.$apply();
			setTimeout(function () {
				$scope.$parent.bBlind = false;
				$scope.$parent.$apply();
				ChatFactory.disableBlind();
			}, 1000 * 120);
		} else if (code == 21) {
			alert(gtext("강제퇴장 되어 방송을 보실 수 없습니다."));
			$state.go("home");
		}
	});

	ChatFactory.pauseHandler(function (value) {
		if (value)
			$scope.$parent.state = 7; //일시정지
		else
			$scope.$parent.state = 0;

		$scope.$parent.$apply();

	});

	ChatFactory.stateHandler(function (state, data) {
		switch (state) {
			case 'multi_view':
				$scope.$parent.setMultiviewInfo(data);
				break;
		}
	});


	/**
	 * 로그인, 실명인증체크
	 * @returns {Boolean}
	 */
	$scope.checkRealName = function () {
		// 실명인증체크 (한국 사용자만)
		if (confirm("아프리카TV는 정보통신망법에 따른 본인확인제를 시행하고 있으며, 이에따라 아이템 결제 및 아이템 교환시 최초 1회에 한에 본인 학인 절차를 거칩니다. 아이핀을 이용한 본인확인을 진행하시겠습니까?")) {
			window.open(AUTH_M_AFREECATV + '/realname?type=popup');
		}
	};

	/**
	 * 채팅 메시지 전달
	 */
	$scope.sendHandler = function () {
		//로그인체크
		if (LoginManager.isLogin() == false) {
			$rootScope.login();
			return false;
		}
		if (LoginManager.isRealName() == false && $rootScope.szNation == 'korean') {
			$scope.checkRealName();
			return false;
		}
		var message = $scope.message;
		//태그 문제열 제거
		message = replaceHtmlEntites(message).trim();

		//빈글 입력제한
		if (message == "" || message === undefined) {
			return;
		}

		//이모티콘 치환
		message = $filter('emoticonEncode')(message);
		ChatFactory.sendMessage(message);
		TokenFactory.setTokenInfo('CHATTING',_broadInfo);
		$scope.message = "";
	};
	$scope.getHtmlMessage = function (message) {
		return $sce.trustAsHtml(message)
	}

	/**
	 * 선물하기 페이지
	 */
	$scope.giftHandler = function () {
		//로그인체크
		if (LoginManager.isLogin() == false) {
			$rootScope.login();
			return false;
		}
		// 실명인증 체크
		if (LoginManager.isChnnlRealName() == false && $rootScope.szNation == 'korean') {
			$scope.checkRealName();
			return false;
		}

		PmService.check({
			szItem: 'balloon',
			szFunction: 'gift',
			szAgent: 'web'
		}).then(function (response) {
			$state.go("player.gift");
		}).catch(function (response) {
			if (response.result == -1 && response.data.code == -1200) {
				alert(response.data.message);
				return;
			}
		});

	};

	/**
	 * Focus핸들러
	 * @returns {undefined}
	 */
	$scope.chatFocusHandler = function () {
		if (_bChat == false) {
			//로그인체크
			if (LoginManager.isLogin() == false) {
				$rootScope.login();
				return false;
			}
			// 실명인증 체크
			if (LoginManager.isRealName() == false && $rootScope.szNation == 'korean') {
				$scope.checkRealName();
				return false;
			}

			_bChat = true;
		}
	};
	//
	$scope.chatStageClickHandler = function () {
		$('.chat_wrap').focus();
		$('.ch_layer').addClass('hide');
		$scope.bAutoScroll = true;
	};

	/**
	 * 채팅 안보기/해제하기 레이어 팝업
	 * @param boolean possible_hide
	 * @returns boolean
	 */
	$scope.popupHideLayer = function (chat) {
		$scope.bAutoScroll = false; //채팅 자동 스크롤 멈춤

		if (!chat.possible_hide) {
			return chat.possible_hide;
		}

		var $thisLayer = $(event.target).parent().find('.ch_layer');
		//이미 레이어 노출 된 경우
		if (!$thisLayer.hasClass('hide')) {
			$thisLayer.addClass('hide');
			$scope.bAutoScroll = true;
			return false;
		}

		$('.ch_layer').addClass('hide');

		//이미 숨기기 된 ID 인 경우
		if ($scope.checkHideChat(chat.chat_id)) {
			chat.layer_message = gtext('채팅안보기 해제');
		} else {
			chat.layer_message = gtext('채팅안보기');
		}

		$thisLayer.removeClass('hide');

		return true;
	};


	/**
	 * 채팅안보기 리스트 가져오기
	 * @returns array
	 */
	$scope.getHideChattingList = function () {
		var list = $scope.storage.hideChatting;
		return (list[_broadInfo.broad_no] === undefined) ? new Array() : list[_broadInfo.broad_no];
	};

	/**
	 * 현재 채팅안보기 상태인지 확인
	 * @param string chat_id
	 * @returns boolean
	 */
	$scope.checkHideChat = function (chat_id) {
		var aChatId = $scope.getHideChattingList();

		//채팅안보기 리스트에 포함된 경우
		if (aChatId.indexOf(chat_id) > -1) {
			return true;
		}
		return false;
	};

	/*
	 * 채팅 안보기/해제 하기
	 */
	$scope.setHideChat = function (chat) {
		//현재 채팅 안보기 상태인지 확인
		if ($scope.checkHideChat(chat.chat_id)) {
			//해제함
			var aChatId = $scope.getHideChattingList();
			var nIndex = aChatId.indexOf(chat.chat_id);
			if (aChatId.splice(nIndex, 1)) {
				$scope.storage.hideChatting[_broadInfo.broad_no] = aChatId;
				//                                toastr.success(chat.nickname + '님의 채팅이<br>다시 보이기 시작합니다.');
				toastr.success(gtext('%s님의 채팅이<br>다시 보이기 시작합니다.', chat.nickname));
			} else {}
		} else {
			//안보기
			var aChatId = $scope.getHideChattingList();
			aChatId.push(chat.chat_id);
			$scope.storage.hideChatting[_broadInfo.broad_no] = aChatId;
			//                            toastr.success(chat.nickname + '님의 채팅이<br>지금부터 보이지 않습니다.');
			toastr.success(gtext('%s님의 채팅이<br>지금부터 보이지 않습니다.', chat.nickname));
		}

		//레이어 숨김
		$('.ch_layer').addClass('hide');
		$scope.bAutoScroll = true;
		return true;
	};
	/**
	 * 이모티콘
	 */
	$scope.setEmoticon = function (type, img) {
		switch (type) {
			case 'blank':
				return;
			case 'back':
				$scope.chatBackspace();
				return;
		}

		$scope.message = ($scope.message || "") + "<img src=\""+ RES_AFREECATV +"/images/chat/emoticon/54/" + img + "\">";
		/*
		 $timeout(function () {
		 var visibleInvalids = $.find("#textBox");
		 var el = visibleInvalids[0];
		 var el2, range, sel;
		 range = document.createRange();
		 sel = window.getSelection();
		 if ( el.childNodes.length > 0 ) {
		 el2 = el.childNodes[el.childNodes.length - 1];
		 range.setStartAfter(el2);
		 } else {
		 range.setStartAfter(el);
		 }
		 range.collapse(true);
		 sel.removeAllRanges();
		 sel.addRange(range);
		 }, 100);
		 */
	}
	/**
	 *
	 * @returns {undefined}
	 */


	function getLastTextNodeIn(node) {
		while (node) {
			if (node.nodeType == 3) {
				return node;
			} else {
				node = node.lastChild;
			}
		}
	}

	function isRangeAfterNode(node) {

		var range = document.createRange();
		var nodeRange, lastTextNode;
		if (range.compareBoundaryPoints) {
			nodeRange = document.createRange();
			lastTextNode = getLastTextNodeIn(node);
			if (lastTextNode)
				nodeRange.selectNodeContents(lastTextNode);
			nodeRange.collapse(false);
			return range.compareBoundaryPoints(range.START_TO_END, nodeRange) > -1;
		} else if (range.compareEndPoints) {
			if (node.nodeType == 1) {
				nodeRange = document.body.createTextRange();
				nodeRange.moveToElementText(node);
				nodeRange.collapse(false);
				return range.compareEndPoints("StartToEnd", nodeRange) > -1;
			} else {
				return false;
			}
		}

	}
	$scope.chatBackspace = function () {
		var range;
		if (window.getSelection && window.getSelection().getRangeAt) {
			range = window.getSelection().getRangeAt(0);
		} else if (document.selection && document.selection.createRange) {
			range = document.selection.createRange();
		} else {
			range = document.createRange();
		}

		var editNode = $('#textBox').get(0);
		var node = editNode.lastChild;
		if (node && node.nodeValue == "") {
			node = node.previousSibling;
		}
		if (node && node.nodeType != 3) {
			while (node) {
				if (isRangeAfterNode(node)) {
					nodeToDelete = node;
					break;
				} else {
					node = node.previousSibling;
				}
			}

			if (nodeToDelete) {
				editNode.removeChild(nodeToDelete);
			}
		} else if (node) {
			var index = node.length - 1;
			if (index >= 0)
				node.deleteData(index, 1);
			else
				editNode.removeChild(node);
		}
		$scope.message = (editNode.innerHTML);
		return false;

	};



	$rootScope.$watch('szSentStarballoon', function (newValue, oldValue) {
		if (newValue !== oldValue) {
			$scope.chatFocusHandler();
		}
	});

	ChatFactory.noticeHandler(function (state, message) {
		$scope.chatNotice.state = state;
		if ($scope.chatNotice.state) {
			$scope.chatNotice.new = true;
			$scope.chatNotice.notShow = false;
		} else {
			$scope.chatNotice.open = false;
			$scope.chatNotice.new = false;
			$(".chat_notice .msg").scrollTop(0);
			message = "";
		}

		$scope.chatNotice.message = $sce.trustAsHtml(message.replace(/(?:\r\n|\r|\n)/g, '<br>'));
		$scope.$digest();
	});

	$scope.chatNoticeCloseHandler = function () {
		$scope.chatNotice.open = !$scope.chatNotice.open;
		if (!$scope.chatNotice.open) {
			$(".chat_notice .msg").scrollTop(0);
		}

		$scope.chatNotice.new = false;
	};

	$scope.chatNoticeNeverShowHandler = function () {
		$scope.chatNotice.notShow = true;
		// 다시 보지 않기를 하더라도 BJ가 공지를 재등록 또는 수정 시 BJ공지가 재노출 되도록 수정
		//$scope.chatNotice.notShow = false;
		$scope.chatNotice.open = false;
		$scope.chatNotice.new = false;
	};

	// 채팅 영역이 리사이즈 될 경우
	$scope.$on('$chat.resize', function (event, chatAreaHeight) {
		// 채팅공지 영역이 보일수 있는 크기인지 판단
		$scope.chatNotice.showPossible = $('.chat_notice').height() < chatAreaHeight;
		if (!$scope.chatNotice.showPossible) {
			$scope.chatNotice.open = false;
		}
		$(".chat_notice .msg").scrollTop(0);
	});

	/**
	 * 로그인 이벤트
	 */
	/*
	$scope.$on('$loginChange', function (event, value) {
	    if (value)
	    {
	        //채팅정보 재설정
	        _broadInfo.is_realnamecheck = true;
	        ChatFactory.init(_broadInfo);

	    }
	});
	*/
	//비디오포털 예외처리
	if (/videoportal/.test(navigator.userAgent)) {
		$scope.bVideoportal = true;
	}

	//OllehTvMobile 예외처리
	if ($location.search().entry_platform == 'ollehmobile') {
		$scope.bOllehTvMobile = true;
	}

}

module.exports = LivePlayerChatController;
