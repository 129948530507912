routing.$inject = ['$stateProvider', '$urlRouterProvider', '$locationProvider'];

function routing($stateProvider, $urlRouterProvider, $locationProvider){

	$urlRouterProvider
		.when('', '/home')
		.when('/live', '/home')
		.when('/rank', '/rank/home')
		.when('/note', '/note/receive')
		.when('/notification', '/notification/list')
		.when('/mypoint', '/mypoint/guide')
		.otherwise('/home');

	$stateProvider
		.state('app', {
			abstract: true,
			url: '',
			controller: require('./controllers/app.controller'),
			template: require('./templates/app.html'),
		})
		.state('home', {
			parent: 'app',
			url: '/home',
			controller: require('./controllers/home.controller'),
			template: require('./templates/home.html')
		})
		.state('video', {
			parent: 'app',
			url: '/video',
			params: {
				oParams: null
			},
			controller: require('./controllers/video.list.controller'),
			template: require('./templates/video.list.html')
		})
		.state('vod', {
			parent: 'app',
			url: '/video',
			params: {
				oParams: null
			},
			controller: require('./controllers/video.list.controller'),
			template: require('./templates/video.list.html')
		})
		.state('favorite', {
			parent: 'app',
			url: '/favorite',
			controller: require('./controllers/favorite.controller'),
			template: require('./templates/favorite.html'),
		})
		.state('favorite/list', {
			parent: 'app',
			url: '/favorite/list',
			controller: require('./controllers/favorite.list.controller'),
			template: require('./templates/favorite.list.html'),
		})
		.state('recent', {
			parent: 'app',
			url: '/recent',
			controller: require('./controllers/recent.controller'),
			template: require('./templates/recent.html'),
		})
		.state('pm', {
			parent: 'app',
			url: '/pm',
			template: require('./templates/pm.html'),
			controller: require('./controllers/pm.controller'),
			params: {
				pm: null
			},
			onEnter: ['$rootScope', function ($rootScope) {
				$rootScope.bIsPm = true;
			}],
			onExit: ['$rootScope', function ($rootScope) {
				$rootScope.bIsPm = false;
			}]
		})
		.state('player', {
			parent: 'app',
			url: '/player/*broad',
			onEnter: ['$rootScope', '$stateParams', '$location', '$state', function ($rootScope, $stateParams, $location, $state) {
                // if($rootScope.playerLoaded) {
                 //    $rootScope.playerLoaded = false;
                 //    location.href = '/#/player~' + $stateParams.broad;
				// }
				$rootScope.$emit('live.player.enter');
				$rootScope.subView = true;

				clearInterval($rootScope.playerRender);
				$rootScope.playerRender = 0;

				$rootScope.playerLoaded = true;
			}],
			onExit: ['$rootScope', function ($rootScope) {

				$rootScope.$emit('live.player.exit');
				$rootScope.subView = false;

                clearInterval($rootScope.playerRender);
                $rootScope.playerRender = 0;
			}],
			views: {
				'@subView': {
					controller : ['$rootScope', '$scope', '$state', '$stateParams', '$location', 'Util', 'gettextCatalog', 'LoginManager', 'RecentService',
						function ($rootScope, $scope, $state, $stateParams, $location, Util, gettextCatalog, LoginManager, RecentService) {

                        // url entry_platform을 체크해서 ollehmobile로 접근시 예외처리를 위한 변수 설정
                        if ($location.search().entry_platform == 'ollehmobile') {
                            // ollehmobile중 ios
                            if (Util.getOS() == "ios") {
                                $scope.bIosOllehTvMobile = true;
                            }
                            $scope.bOllehTvMobile = true;
                        }

                        var broad = $stateParams.broad;
						if(!broad){
							window.location.replace(window.location.origin)
						}

						var aBroad = broad.split("/");
                        var bj_id = aBroad[0];
                        var broad_no = isNaN(aBroad[1]) ? '' : aBroad[1];

                        //dashboard, direct, picaplay 등 추가할 때 추가하세여
                        var modes = ['embed', 'direct'];
                        var mode = aBroad[2] || aBroad[1];
                        mode = (modes.indexOf(mode) !== -1) ? mode : 'live';
                        
                        if (mode === 'direct') {
                            $scope.bDirectMode = true;
                        }

                        window.angularUtil = Util;
                        window.angularState = $state;
                        window.angularRootScope = $rootScope;
                        window.angularScope = $scope;
                        window.angularService = {
                        	recentService : RecentService
                        };

                        $rootScope.emoticonPages = [];

                        $scope.lang = gettextCatalog.currentLanguage;

                        $.ajaxSetup({
                            cache: true
                        });

						//광고차단체크스크립트가 없다면 load
						if(typeof window.adBlockLogSend === 'undefined') {
							$.getScript('//res.onlive.vn/script/da/af_da_block.js').done(function() {
								/**
								 * 동적으로 스크립트 로드한 이유는 af_ad_block.js가 불필요한 위치에서 로드되는 경우가 많을 듯하여
								 * 로드 후 checkBlock window.onload때 못했기 때문에 수동으로 호출
								 * 이때 ad_check.js 로드 및 확인용 dom 생성이 바로 안되서 block된 걸로 체크되는 경우 발생
								 * 어쩔수 없이 딜레이 줌
								 */
								setTimeout(() => {
									window.checkBlock();	
								}, 500);
							});
						}

                        makePlayer();

                        if(typeof MobileLivePlayer === 'undefined') {
                            $.getScript(STATIC_AFREECATV + '/asset/app/liveplayer/player/dist/vendor.js')
                                .done(function () {
                                    $.getScript(STATIC_AFREECATV + '/asset/app/liveplayer/player/dist/MobileLivePlayer.js')
                                        .done(function() {
                                            makePlayer();
                                        });
                                });
                        }

                        if(typeof MobileLiveView === 'undefined') {
                            $.getScript(STATIC_AFREECATV + '/asset/app/liveplayer/view/dist/ViewVendor.js')
                                .done(function() {
                                    $.getScript(STATIC_AFREECATV + '/asset/app/liveplayer/view/dist/MobileLiveView.js')
                                        .done(function() {
                                            makePlayer();
                                        });
                                });
                        }

                        function makePlayer() {
                            if(typeof MobileLivePlayer !== 'undefined' && typeof MobileLiveView !== 'undefined') {
                                if(typeof mobileLivePlayer === 'undefined') {
                                    mobileLivePlayer = new MobileLivePlayer({
                                        language : gettextCatalog.currentLanguage,
										mode : mode
                                    });
                                }

                                if(typeof liveView === 'undefined') {
                                    liveView = new MobileLiveView(mobileLivePlayer.externalInterface, {
                                        language : gettextCatalog.currentLanguage,
                                        mode : mode
                                    });
                                }

                                liveView.isLoaded().then(function() {

									LiveOverlay.initialize({
										api: 'https://api.onplay.live/',
										wss: 'https://wsk.onplay.live/',
										iframeClass: 'live-interactive-iframe',
										payment: 'https://pay-api.onplay.live/',
									});
									const layout = document.getElementById('videoLayer');
									LiveOverlay.attachTo(layout);
									// LiveOverlay.join({
									// 	mediaContentId:	bj_id + '_' + broad_no,
									// 	deviceType: 'web',
									// 	type: 1,
									// });
                                    if(playerWasLoaded) {
                                        mobileLivePlayer.setConfig({
											mode : mode
										});
                                        //liveView.setOption();
                                        //liveView.LiveViewInfo.mode = szMode;
                                        liveView.initialize(undefined, {
                                            mode: mode
                                        });
                                    }

                                    $rootScope.playerRender = setInterval(function() {
                                    	if(document.getElementById('videoLayer')) {
                                            clearInterval($rootScope.playerRender);
                                            $rootScope.playerRender = 0;
                                            //mobileLivePlayer.getPlayerInfo().mode = szMode;

                                            mobileLivePlayer.loadBroad(bj_id, broad_no);
                                            playerWasLoaded = true;
										}
									}, 100);
                                    //console.log(livePlayer);
                                });
                            }
                        }

                        //$rootScope.$on("live.player.enter", function() {
                        // 	$("body").addClass("liveArea");
                        // });
                        $rootScope.$on("live.player.exit", function(event) {
                            // event.stopPropagation();
                            // livePlayer.endBroad();
                            //
                            // $scope.broadState = 1;
                        });

                        $scope.$on('$destroy', function(arg) {
                            console.log(arg);
                            // // Do your cleanup here or call a function that does
                            $("body").removeClass("liveArea");
                            mobileLivePlayer.endBroad('', 'stop_self');
							LiveOverlay.leave();
                            //$scope.broadState = 1;
                        });

						//하 복잡하다... ㅋㅋㅋ 어쩔 수가 없다.
						//로그인 처리는 앵귤러에 있어서...
						$scope.$on('$loginChange', function (event, value) {
                            console.log($rootScope.member);
							if (LoginManager.isLogin()) {
								liveView.controller.callAll('getLoginEvent');
							}
						});

						//뒤로가기에 필요
						$scope.$on("$locationChangeSuccess", function(evt, next, current) {
							window.referer = current;
						});
                    }],
					template: function(params){
                        var modes = ['embed', 'direct'];
                        var isEmbed = false;
                        for(var i = 0; i < modes.length; i++) {
                        	if(params.broad.indexOf(modes[i]) > -1) {
                        		isEmbed = true;
                        		break;
							}
						}
                        if(isEmbed) {
                            return require('./templates/live.player.embed.html')
						} else {
                            return require('./templates/live.player.html')
						}
					}
				},
                '@gift': {
                    //controller: require('./controllers/live.player.gift.controller'),
                    controller : ['$rootScope', function ($rootScope, $scope) {
                        $rootScope.balloons = [];
                        angular.forEach([33, 100, 200], function (value, key) {
                            this.push({
                                type: 'starballoon',
                                code: value,
                                count: value,
                                title: value,
                                thumb: STATIC_FILE_AFREECATV + '/new_player/items/thumb/balloon_' + (key + 2) + '.png'
                            });
                        }, $rootScope.balloons);
                        //$rootScope.selectItem = $rootScope.balloons[0]; //초기 선택
                        $rootScope.tab = 'starballoon';

                        $rootScope.stickers = [];
                    }],
                    template: require('./templates/live.player.gift.html')
                }
			}
		})
		.state('rank', {
			parent: 'app',
			url: '/rank',
			onEnter: ['$rootScope', function ($rootScope) {
				$rootScope.subView = true;
			}],
			onExit: ['$rootScope', function ($rootScope) {
				$rootScope.subView = false;
			}],
			views: {
				'@subView': {
					controller: require('./controllers/rank.controller'),
					template: require('./templates/rank.html')
				}
			}
		})
		.state('rank.list', {
			parent: 'rank',
			url: '/*rank',
			onEnter: ['$rootScope', function ($rootScope) {
				$rootScope.subView = true;
			}],
			onExit: ['$rootScope', function ($rootScope) {
				$rootScope.subView = false;
			}],
			views: {
				'@view': {
					controller: require('./controllers/rank.list.controller'),
					template: require('./templates/rank.list.html')
				}
			}
		})
		.state('search', {
			parent: 'app',
			url: '/search/:query',
			params: {
				stype: null,
				ac_type: null,
				original: null,
				pk_cnt: 0,
				has_hint: false
			},
			controller: require('./controllers/search.controller'),
			template: require('./templates/search.html')
		})
		.state('keyword', {
			parent: 'app',
			url: '/keyword',
			params: {
				search_tab: null
			},
			controller: require('./controllers/keyword.controller'),
			template: require('./templates/keyword.html')
		})
		.state('webview', {
			parent: 'app',
			url: '/webview/:id',
			controller: require('./controllers/webview.controller'),
			template: require('./templates/webview.html')
		})
		.state('myinfo', {
			parent: 'app',
			url: '/myinfo',
			controller: require('./controllers/myinfo.controller'),
			template: require('./templates/myinfo.html')
		})
		.state('myinfo.nickname', {
			parent: 'myinfo',
			url: '/nickname',
			onEnter: ['$rootScope', function ($rootScope) {
				$rootScope.subView = true;
			}],
			onExit: ['$rootScope', function ($rootScope) {
				$rootScope.subView = false;
			}],
			views: {
				'@subView': {
					controller: require('./controllers/myinfo.nickname.controller'),
					template: require('./templates/myinfo.nickname.html'),
				}
			}
		})
		.state('myinfo.userinfo', {
			parent: 'myinfo',
			url: '/userinfo',
			onEnter: ['$rootScope', function ($rootScope) {
				$rootScope.subView = true;
			}],
			onExit: ['$rootScope', function ($rootScope) {
				$rootScope.subView = false;
			}],
			views: {
				'@subView': {
					controller: require('./controllers/myinfo.userinfo.controller'),
					template: require('./templates/myinfo.userinfo.html'),
				}
			}
		})
		.state('iteminfo', {
			parent: 'app',
			url: '/iteminfo',
			params: {
				'entry_location' : null
			},
			controller: require('./controllers/iteminfo.controller'),
			template: require('./templates/iteminfo.html')
		})
		.state('iteminfo.quickview', {
			parent: 'iteminfo',
			url: '/quickview',
			onEnter: ['$rootScope', function ($rootScope) {
				$rootScope.subView = true;
			}],
			onExit: ['$rootScope', function ($rootScope) {
				$rootScope.subView = false;
			}],		
			views: {
				'@subView': {
					controller: require('./controllers/iteminfo.quickview.controller'),
					template: require('./templates/iteminfo.quickview.html')
				}
			}
		}).state('iteminfo.sticker', {
			parent: 'iteminfo',
			url: '/sticker',
			onEnter: ['$rootScope', function ($rootScope) {
				$rootScope.subView = true;
			}],
			onExit: ['$rootScope', function ($rootScope) {
				$rootScope.subView = false;
			}],
			views: {
				'@subView': {
					controller: require('./controllers/iteminfo.sticker.controller'),
					template: require('./templates/iteminfo.sticker.html'),
				}
			}
		})
		.state('iteminfo.exchangeticket', {
			parent: 'iteminfo',
			url: '/exchageticket/*ticket_type',
			onEnter: ['$rootScope', function ($rootScope) {
				$rootScope.subView = true;
			}],
			onExit: ['$rootScope', function ($rootScope) {
				$rootScope.subView = false;
			}],
			views: {
				'@subView': {
					controller: require('./controllers/iteminfo.exchangeticket.controller'),
					template: require('./templates/iteminfo.exchageticket.html'),
				}
			}
		})
		.state('iteminfo.ticketend', {
			parent: 'iteminfo',
			url: '/exchageticketend/*ticket_type',
			onEnter: ['$rootScope', function ($rootScope) {
				$rootScope.subView = true;
			}],
			onExit: ['$rootScope', function ($rootScope) {
				$rootScope.subView = false;
			}],
			views: {
				'@subView': {
					controller: require('./controllers/iteminfo.ticketend.controller'),
					template: require('./templates/iteminfo.ticketend.html'),
				}
			}
		})
		.state('iteminfo.giftticket', {
			parent: 'iteminfo',
			url: '/giftticket/*ticket_type',
			onEnter: ['$rootScope', function ($rootScope) {
				$rootScope.subView = true;
			}],
			onExit: ['$rootScope', function ($rootScope) {
				$rootScope.subView = false;
			}],
			views: {
				'@subView': {
					controller: require('./controllers/iteminfo.giftticket.controller'),
					template: require('./templates/iteminfo.giftticket.html'),
				}
			}
		})
		.state('iteminfo.mysticker', {
			parent: 'iteminfo',
			url: '/mysticker',
			onEnter: ['$rootScope', function ($rootScope) {
				$rootScope.subView = true;
			}],
			onExit: ['$rootScope', function ($rootScope) {
				$rootScope.subView = false;
			}],
			views: {
				'@subView': {
					controller: require('./controllers/iteminfo.mysticker.controller'),
					template: require('./templates/iteminfo.mysticker.html'),
				}
			}
		})
		.state('iteminfo.giftticketend', {
			parent: 'iteminfo',
			url: '/giftticketend/*ticket_type',
			onEnter: ['$rootScope', function ($rootScope) {
				$rootScope.subView = true;
			}],
			onExit: ['$rootScope', function ($rootScope) {
				$rootScope.subView = false;
			}],
			views: {
				'@subView': {
					controller: require('./controllers/iteminfo.giftticketend.controller'),
					template: require('./templates/iteminfo.giftticketend.html'),
				}
			}
		})
		.state('iteminfo.insertaddr', {
			parent: 'iteminfo',
			url: '/insertaddr/*ticket_type/*order_type',
			onEnter: ['$rootScope', function ($rootScope) {
				$rootScope.subView = true;
			}],
			onExit: ['$rootScope', function ($rootScope) {
				$rootScope.subView = false;
			}],
			views: {
				'@subView': {
					controller: require('./controllers/iteminfo.insertaddr.controller'),
					template: require('./templates/iteminfo.insertaddr.html'),
				}
			}
		})
		.state('iteminfo.confirmaddr', {
			parent: 'iteminfo',
			url: '/confirmaddr/*order_num/*reload',
			onEnter: ['$rootScope', function ($rootScope) {
				$rootScope.subView = true;
			}],
			onExit: ['$rootScope', function ($rootScope) {
				$rootScope.subView = false;
			}],
			views: {
				'@subView': {
					controller: require('./controllers/iteminfo.confirmaddr.controller'),
					template: require('./templates/iteminfo.confirmaddr.html'),
				}
			}
		})
		.state('iteminfo.chocoexchange', {
			parent: 'iteminfo',
			url: '/chocoexchange/*itemNo',
			onEnter: ['$rootScope', function ($rootScope) {
				$rootScope.subView = true;
			}],
			onExit: ['$rootScope', function ($rootScope) {
				$rootScope.subView = false;
			}],
			views: {
				'@subView': {
					controller: require('./controllers/iteminfo.chocoexchange.controller'),
					template: require('./templates/iteminfo.chocoexchange.html'),
				}
			}
		})
		.state('iteminfo.chocoexchanged', {
			parent: 'iteminfo',
			url: '/chocoexchanged/*bundle',
			onEnter: ['$rootScope', function ($rootScope) {
				$rootScope.subView = true;
			}],
			onExit: ['$rootScope', function ($rootScope) {
				$rootScope.subView = false;
			}],
			views: {
				'@subView': {
					controller: require('./controllers/iteminfo.chocoexchanged.controller'),
					template: require('./templates/iteminfo.chocoexchanged.html'),
				}
			}
		})
		.state('iteminfo.quickview.upgrade', {
			parent: 'iteminfo',
			url: '/quickview/upgrade/*itemType',
			onEnter: ['$rootScope', function ($rootScope) {
				$rootScope.subView = true;
			}],
			onExit: ['$rootScope', function ($rootScope) {
				$rootScope.subView = false;
			}],
			params: {
				'inApp': false
			},
			views: {
				'@subView': {
					controller: require('./controllers/iteminfo.quickview.upgrade.controller'),
					template: function(params){
						switch (params.itemType.trim()) {
							case 'normal' :
								const szTemplate = params.inApp ?
									'./templates/iteminfo.quickview.upgrade.inapp.html' :
									'./templates/iteminfo.quickview.upgrade.html';
								return require('' + szTemplate);
								break;
							case 'auto' :
								return require('./templates/iteminfo.quickview.autotrial.html')
								break;
							default :
								break;
						}
					}
				}
			}
		})		
		.state('note', {
			parent: 'app',
			abstract: true,
			url: '/note',
			onEnter: ['$rootScope', '$timeout', function ($rootScope, $timeout) {
				$rootScope.szHeader = "memo_headers";
				$rootScope.headerClassRemove = function () {
					$timeout(function() {
						$rootScope.szHeader="";
						return false;
					}, 100);
				}
				$rootScope.subView = true;
			}],
			onExit: ['$rootScope', function ($rootScope) {
				$rootScope.subView = false;
			}],
			views: {
				'@subView': {
					controller: require('./controllers/note.controller'),
					template: require('./templates/note.html')
				}
			}
		})
		.state('note.receive', {
			parent: 'note',
			url: '/receive',
			onEnter: ['$rootScope', function ($rootScope) {
				$rootScope.subView = true;
				$rootScope.prevNoti = false;
			}],
			onExit: ['$rootScope', function ($rootScope) {
				$rootScope.subView = false;
				$rootScope.prevNoti = true;
			}],
			params: {
				deleteNo: null,
				searchWord: null,
				searchType: 1
			},
			views: {
				'@view': {
					controller: require('./controllers/note.receive.list.controller'),
					template: require('./templates/note.receive.list.html')
				}
			}
		})
		.state('note.receiveview', {
			parent: 'note',
			url: '/receive/*no',
			onEnter: ['$rootScope', function ($rootScope) {
				$rootScope.subView = true;
			}],
			onExit: ['$rootScope', function ($rootScope) {
				$rootScope.subView = false;
			}],
			views: {
				'@subView': {
					controller: require('./controllers/note.receive.view.controller'),
					template: require('./templates/note.receive.view.html')
				}
			}
		})
		.state('note.send', {
			parent: 'note',
			url: '/send',
			onEnter: ['$rootScope', function ($rootScope) {
				$rootScope.subView = true;
			}],
			onExit: ['$rootScope', function ($rootScope) {
				$rootScope.subView = false;
			}],
			params: {
				deleteNo: null,
				disableHistoryState: false,
				searchWord: null,
				searchType: 1
			},
			views: {
				'@view': {
					controller: require('./controllers/note.send.list.controller'),
					template: require('./templates/note.send.list.html')
				}
			}
		})
		.state('note.sendview', {
			parent: 'note',
			url: '/send/*no',
			onEnter: ['$rootScope', function ($rootScope) {
				$rootScope.subView = true;
			}],
			onExit: ['$rootScope', function ($rootScope) {
				$rootScope.subView = false;
			}],
			views: {
				'@subView': {
					controller: require('./controllers/note.send.view.controller'),
					template: require('./templates/note.send.view.html')
				}
			}
		})
		.state('note.write', {
			parent: 'note',
			url: '/write',
			params: {
				id: null
			},
			onEnter: ['$rootScope', function ($rootScope) {
				$rootScope.subView = true;
			}],
			onExit: ['$rootScope', function ($rootScope) {
				$rootScope.subView = false;
			}],
			views: {
				'@subView': {
					controller: require('./controllers/note.write.controller'),
					template: require('./templates/note.write.html'),
				}
			}
		})
		.state('note.write2', {
			parent: 'note',
			url: '/write2/*id',
			params: {
				id: null
			},
			onEnter: ['$rootScope', function ($rootScope) {
				$rootScope.subView = true;
			}],
			onExit: ['$rootScope', function ($rootScope) {
				$rootScope.subView = false;
			}],
			views: {
				'@subView': {
					controller: require('./controllers/note.write.controller'),
					template: require('./templates/note.write2.html'),
				}
			}
		})
		.state('note.setting', {
			parent: 'note',
			url: '/setting',
			onEnter: ['$rootScope', function ($rootScope) {
				$rootScope.subView = true;
			}],
			onExit: ['$rootScope', function ($rootScope) {
				$rootScope.subView = false;
			}],
			views: {
				'@subView': {
					controller: require('./controllers/note.setting.controller'),
					template: require('./templates/note.setting.html')
				}
			}
		})

		.state('note.store', {
			parent: 'note',
			url: '/store',
			onEnter: ['$rootScope', function ($rootScope) {
				$rootScope.subView = true;
			}],
			onExit: ['$rootScope', function ($rootScope) {
				$rootScope.subView = false;
			}],
			params: {
				deleteNo: null,
				searchWord: null,
				searchType: 1
			},
			views: {
				'@view': {
					controller: require('./controllers/note.store.list.controller'),
					template: require('./templates/note.store.list.html')
				}
			}
		})

		.state('note.storeview', {
			parent: 'note',
			url: '/store/*no/',
			onEnter: ['$rootScope', function ($rootScope) {
				$rootScope.subView = true;
			}],
			onExit: ['$rootScope', function ($rootScope) {
				$rootScope.subView = false;
			}],
			views: {
				'@subView': {
					controller: require('./controllers/note.store.view.controller'),
					template: require('./templates/note.store.view.html')
				}
			}
		})

		.state('note.spam', {
			parent: 'note',
			url: '/spam',
			onEnter: ['$rootScope', function ($rootScope) {
				$rootScope.subView = true;
			}],
			onExit: ['$rootScope', function ($rootScope) {
				$rootScope.subView = false;
			}],
			params: {
				deleteNo: null,
				searchWord: null,
				searchType: 1
			},
			views: {
				'@view': {
					controller: require('./controllers/note.spam.list.controller'),
					template: require('./templates/note.spam.list.html')
				}
			}
		})

		.state('note.spamview', {
			parent: 'note',
			url: '/spam/*no/',
			onEnter: ['$rootScope', function ($rootScope) {
				$rootScope.subView = true;
			}],
			onExit: ['$rootScope', function ($rootScope) {
				$rootScope.subView = false;
			}],
			views: {
				'@subView': {
					controller: require('./controllers/note.spam.view.controller'),
					template: require('./templates/note.spam.view.html')
				}
			}
		})


		.state('notification', {
			parent: 'app',
			url: '/notification',
			onEnter: ['$rootScope', function ($rootScope) {
				$rootScope.subView = true;
			}],
			onExit: ['$rootScope', function ($rootScope) {
				$rootScope.subView = false;
			}],
			views: {
				'@subView': {
					controller: require('./controllers/notification.controller'),
					template: require('./templates/notification.html')
				}
			}
		})

		.state('notification.list', {
			parent: 'notification',
			url: '/list',
			onEnter: ['$rootScope', function ($rootScope) {
				$rootScope.subView = true;
				$rootScope.$on('$locationChangeSuccess', function(event, newUrl, oldUrl){
					if (oldUrl.split('/')[4] !== "notification"){
						$rootScope.fromBadge = true;
					}
				});
			}],
			onExit: ['$rootScope', function ($rootScope) {
				$rootScope.subView = false;
				$rootScope.fromBadge = false;
			}],
			views: {
				'@view': {
					controller: require('./controllers/notification.list.controller'),
					template: require('./templates/notification.list.html')
				}
			}
		})

		.state('notification.pushsetting', {
			parent: 'notification',
			url: '/pushsetting',
			onEnter: ['$rootScope', function ($rootScope) {
				$rootScope.subView = true;
			}],
			onExit: ['$rootScope', function ($rootScope) {
				$rootScope.subView = false;
			}],
			views: {
				'@subView': {
					controller: require('./controllers/notification.pushsetting.controller'),
					template: require('./templates/notification.pushsetting.html')
				}
			}
		})

		.state('notification.notisetting', {
			parent: 'notification',
			url: '/notisetting',
			onEnter: ['$rootScope', function ($rootScope) {
				$rootScope.subView = true;
			}],
			onExit: ['$rootScope', function ($rootScope) {
				$rootScope.subView = false;
			}],
			params: {
				infoType: null
			},
			views: {
				'@subView': {
					controller: require('./controllers/notification.notisetting.controller'),
					template: require('./templates/notification.notisetting.html')
				}
			}
		})

		.state('mypoint', {
			parent: 'app',
			url: '/mypoint',
			onEnter: ['$rootScope', function ($rootScope) {
				$rootScope.subView = true;
				$rootScope.cacheFlag = true;
				$rootScope.$on('$locationChangeSuccess', function(event, newUrl, oldUrl){
					if(oldUrl.indexOf('exchange') > -1) {
						$rootScope.cacheFlag = false;
					}
				});
			}],
			onExit: ['$rootScope', function ($rootScope) {
				$rootScope.subView = false;
				$rootScope.cacheFlag = true;
			}],
			views: {
				'@subView': {
					controller: require('./controllers/mypoint.controller'),
					template: require('./templates/mypoint.html')
				}
			}
		})

		.state('mypoint.guide', {
			parent: 'mypoint',
			url: '/guide',
			onEnter: ['$rootScope', function ($rootScope) {
				$rootScope.subView = true;
			}],
			onExit: ['$rootScope', function ($rootScope) {
				$rootScope.subView = false;
			}],
			views: {
				'@view': {
					controller: require('./controllers/mypoint.guide.controller'),
					template: require('./templates/mypoint.guide.html')
				}
			}
		})

		.state('mypoint.status', {
			parent: 'mypoint',
			url: '/status',
			onEnter: ['$rootScope','$state', '$timeout', function ($rootScope, $state, $timeout) {
			   $rootScope.subView = true;
			   $rootScope.$on('$stateChangeSuccess', function (evt, toState) {
			     if (toState.name === 'mypoint.status') {
					$timeout(function() {
					  $state.go('mypoint.pointstatus');
					});
			     }
			   });
			}],
			onExit: ['$rootScope', function ($rootScope) {
				$rootScope.subView = false;
			}],
		})

		.state('mypoint.pointstatus', {
			parent: 'mypoint',
			url: '/pointstatus',
			onEnter: ['$rootScope', function ($rootScope) {
				$rootScope.subView = true;
			}],
			onExit: ['$rootScope', function ($rootScope) {
				$rootScope.subView = false;
			}],
			views: {
				'@view': {
					controller: require('./controllers/mypoint.pointstatus.controller'),
					template: require('./templates/mypoint.pointstatus.html')
				}
			}
		})

		.state('mypoint.tokenstatus', {
			parent: 'mypoint',
			url: '/tokenstatus',
			onEnter: ['$rootScope', function ($rootScope) {
				$rootScope.subView = true;
			}],
			onExit: ['$rootScope', function ($rootScope) {
				$rootScope.subView = false;
			}],
			views: {
				'@view': {
					controller: require('./controllers/mypoint.tokenstatus.controller'),
					template: require('./templates/mypoint.tokenstatus.html')
				}
			}
		})

		.state('mypoint.accumulate', {
			parent: 'mypoint',
			url: '/accumulate',
			onEnter: ['$rootScope', function ($rootScope) {
				$rootScope.subView = true;
			}],
			onExit: ['$rootScope', function ($rootScope) {
				$rootScope.subView = false;
			}],
			views: {
				'@subView': {
					controller: require('./controllers/mypoint.accumulate.controller'),
					template: require('./templates/mypoint.accumulate.html')
				}
			}
		})

		.state('mypoint.subscribe', {
			parent: 'mypoint',
			url: '/subscribe',
			onEnter: ['$rootScope', function ($rootScope) {
				$rootScope.subView = true;
			}],
			onExit: ['$rootScope', function ($rootScope) {
				$rootScope.subView = false;
			}],
			views: {
				'@subView': {
					controller: require('./controllers/mypoint.subscribe.controller'),
					template: require('./templates/mypoint.subscribe.html')
				}
			}
		})

		.state('mypoint.accumulatedetail', {
			parent: 'mypoint',
			url: '/accumulatedetail',
			onEnter: ['$rootScope', function ($rootScope) {
				$rootScope.subView = true;
			}],
			onExit: ['$rootScope', function ($rootScope) {
				$rootScope.subView = false;
			}],
			views: {
				'@subView': {
					controller: require('./controllers/mypoint.accumulate.controller'),
					template: require('./templates/mypoint.accumulatedetail.html')
				}
			}
		})

		.state('mypoint.exchange', {
			parent: 'mypoint',
			url: '/exchange',
			onEnter: ['$rootScope', function ($rootScope) {
				$rootScope.subView = true;
			}],
			onExit: ['$rootScope', function ($rootScope) {
				$rootScope.subView = false;
			}],
			views: {
				'@subView': {
					controller: require('./controllers/mypoint.exchange.controller'),
					template: require('./templates/mypoint.exchange.html')
				}
			}
		})

		.state('mypoint.item', {
			parent: 'mypoint',
			url: '/item',
			onEnter: ['$rootScope', function ($rootScope) {
				$rootScope.subView = true;
			}],
			onExit: ['$rootScope', function ($rootScope) {
				$rootScope.subView = false;
			}],
			views: {
				'@view': {
					controller: require('./controllers/mypoint.item.controller'),
					template: require('./templates/mypoint.item.html')
				}
			}
		})

		.state('mypoint.itemdetail', {
			parent: 'mypoint',
			url: '/itemdetail/*actionType/*itemNo',
			onEnter: ['$rootScope', function ($rootScope) {
				$rootScope.subView = true;
			}],
			onExit: ['$rootScope', function ($rootScope) {
				$rootScope.subView = false;
			}],
			views: {
				'@subView': {
					controller: require('./controllers/mypoint.itemdetail.controller'),
					template: function(params){
						switch (params.actionType.trim()) {
							case 'purchase' :
								return require('./templates/mypoint.item.purchase.html')
								break;
							case 'use' :
								return require('./templates/mypoint.item.use.html')
								break;
							case 'expire' :
								return require('./templates/mypoint.item.expire.html')
								break;
							default :
								break;
						}
					}
				}
			}
		});
}
module.exports = routing;