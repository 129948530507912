ChatFactory.$inject = ['Util', '$rootScope', 'CookieFactory', '$sce', 'gtext', 'ChatService'];

function ChatFactory(Util, $rootScope, CookieFactory, $sce, gtext, ChatService) {

	var WebChat = require('./chat.websocket');
	var HttpChat = require('./chat.http');

	var _broadInfo;
	var stateParams;
	var webChat;
	var httpChat;
	var messageCallback = null;
	var closeCallback = null;
	var viewerCallback = null;
	var clearCallback = null;
	var alertCallback = null;
	var pauseCallback = null;
	var giftCallback = null;
	var loginCallback = null;
	var socketSupportCallback = null;
	var blockChatClearCallback = null;
	var stateCallback = null;
	var viewerBoxCallback = null;
	var noticeCallback = null;
	var bReconnect = false // 재접속
	var freezeStatus = false; //얼리기

	//OGQ 이미지 CDN 도메인
	var OGQ_IMG_DOMAIN = {
        KR : OGQ_IMG_AFREECATV,
        GLOBAL : OGQ_IMG_AFREECATV
	};
	//OGQ 이미지 사이즈 정의
	var OGQ_IMG_SIZE = {
        TAB : 28,
        LIST : 51,
        SELECT : 80,
        CHAT_DEFAULT : 80,
        CHAT_SMALL : 40
    };

	var chat = this;
	var szRealUserId = '';
	var szRecommandServer = '';
	var bPlay = false;
	var USERLEVEL = {
		'ADMIN': 0x01, // 관리자 (강퇴, 벙어리 대상이 될 수 없음) : 운영자
		'HIDDEN': (0x01 << 1), // 아이디 숨김 (사용자 목록에서 볼 수 없음) : 서버에서만 사용. 비트가 세팅되어 있으면  클라이언트에는 아예 보내지 않음.
		'BJ': (0x01 << 2), // BJ : 방장
		'DUMB': (0x01 << 3), // 벙어리 (채팅금지) : 벙어리 지정된 놈.
		'GUEST': (0x01 << 4), // 비회원 : 아이디 없는 놈. 서버에서만 사용하고 클라이언트에는 아예 안보냄.
		'FANCLUB': (0x01 << 5), // 팬클럽 회원
		'AUTOMANAGER': (0x01 << 6), // 자동 메니저로 지정된 놈
		'MANAGERLIST': (0X01 << 7), // 자동 매니저 리스트에 등록된놈
		'SUBBJ': (0x01 << 8), // 부방장 : 매니저
		'FEMALE': (0x01 << 9), // 여자 (아니면 모두 남자로 처리)
		'AUTODUMB': (0x01 << 10), // 자동 벙어리 (서버에서 지정) : 서버에서 일정량 이상의 채팅 내용이 오면 벙어리로 지정한다. 클라이언트에서는 신경 안써도 됨.
		'DUMB_BLIND': (0x01 << 11), // 벙어리로 인한 블라인드 : 벙어리로 인해 블라인드 지정된 놈.
		'DOBAE_BLIND': (0x01 << 12), // 도배로 인한 블라인드 처리 : 도배로 인한 블라인드 지정된 놈.
		'EXITUSER': (0x01 << 13), // 나간사람. 서버엔 영향 없기 때문에 클라이언트 정의 : 무시해도 됨.
		'MOBILE': (0x01 << 14), // 모바일 user
		'TOPFAN': (0x01 << 15), // 열혈팬 여부
		'REALNAME': (0x01 << 16), // 실명인증여부 : 실명 인증되어 있는 놈이면 로그인 할 때 플래그를 세팅해서 보내야 한다.
		'NODIRECT': (0x01 << 17), // 1:1 직접 채팅 금지 : 귓말 금지
		'GLOBAL_APP': (0x01 << 18), // 글로벌 모바일앱 유저
		'QUICKVIEW': (0x01 << 19), // 퀵뷰 사용 여부
		'SPTR_STICKER': (0x01 << 20), // 스티커 서포터 여부
		'CHROMECAST': (0x01 << 21), // 크롬캐스트 유저
		'SUBSCRIPTION': (0x01 << 28), // 구독 유저
	}

	function _getLogData() {
        return collector.getDelimiter() + collector.buildQuery({
			geo_cc: _broadInfo.country_code,
			geo_rc: _broadInfo.region_code,
			acpt_lang: Util.getPreferredLocale(),
			//svc_lang: window.navigator.language || window.navigator.userLanguage,
            svc_lang: _broadInfo.lang,
			entry_platform: stateParams.entry_platform,
			entry_way: stateParams.entry_way,
			videoportal_id: stateParams.videoportal_id,
			external_key: stateParams.external_key,
			join_cc : _broadInfo.join_cc
		});
	}

	var chat = {
		getBroad: function () {
			return _broadInfo;
		},
		init: function (data, params) {
			stateParams = params
			//채팅중이고  채팅정보(중계방>본방) 가 변경되었을 경우
			if (bPlay && _broadInfo != data) {
				//채팅 다시연결
				chat.close();
				_broadInfo = $.extend({}, data);
				this.play(); //소켓 재연결
			} else {
				_broadInfo = $.extend({}, data);
			}
		},

		/*
		 * 채팅 생성
		 */
		creatChat: function () {
			ChatService.list().then(function (reponse) {
				webChat = new WebChat();
                webChat.setBroadInfo(_broadInfo);
				webChat.init(reponse.data.list);
				webChat.openHandler(chat.openHandler)
				webChat.messageHandler(chat.chatHandler)
				webChat.abnormalCloseHandler(chat.abnormalCloseHandler)
				webChat.connect();
			});
		},
		/**
		 * 비정상 종료
		 */
		abnormalCloseHandler: function () {
			bReconnect = true;
			webChat.connect();
		},

		/**
		 * 연결이벤트
		 * @param boolean connect  연결설정
		 */
		openHandler: function (connect) {
			if (clearCallback && !bReconnect)
				clearCallback();

			//채팅사용가능
			if (connect > 0) {
				//채팅 로그인
				chat.login();
			} else {
				//chat.noticMessage('접속하신 브라우저에서는 채팅이 지원되지 않습니다.');
				//chat.noticMessage('Chrome을 이용하시면 채팅을 확인하실 수 있습니다.');
				//chat.noticMessage('[작업관리자] 로 이동하여 [기본정보 지우기]를 선택하고 사용 중이신 브라우저를 삭제하시면 이후 Chrome을 기본 브라우저로 설정하실 수 있습니다.');
				httpChat = new HttpChat();
				httpChat.init(_broadInfo);
				httpChat.messageHandler(chat.chatHandler);
				httpChat.start();
				chat.infoMessage(gtext('[안내]'), gtext('방송에 입장하였습니다.'));
				socketSupportCallback(true);
			}
		},


		blockChatClearHandler: function (fn) {
			blockChatClearCallback = fn;
		},
		loginHandler: function (fn) {
			loginCallback = fn;
		},
		/**
		 * 채팅 메시지 받을 콜백함수
		 * @param {Functioin} fn
		 */
		messageHandler: function (fn) {
			messageCallback = fn;
		},
		closeHandler: function (fn) {
			closeCallback = fn;
		},
		viewerHandler: function (fn) {
			viewerCallback = fn;
		},
		clearHandler: function (fn) {
			clearCallback = fn;
		},
		alertHandler: function (fn) {
			alertCallback = fn;
		},
		pauseHandler: function (fn) {
			pauseCallback = fn;
		},
		giftHandler: function (fn) {
			giftCallback = fn;
		},
		socketNotSupportHandler: function (fn) {
			socketSupportCallback = fn;
		},
		stateHandler: function (fn) {
			stateCallback = fn;
		},
		viewerBoxHandler: function (fn) {
			viewerBoxCallback = fn;
		},
		noticeHandler: function (fn) {
			noticeCallback = fn;
		},

		/**
		 * 레벨값비교
		 * @param {integer} level
		 * @param {bit} chkFlag
		 * @returns {Boolean}
		 */
		compareFlag: function (level, chkFlag) {
			return ((level & chkFlag) > 0) ? true : false;
		},
		/*
		 * User 레베에 따른 클래스값
		 * @param {integer} level
		 * @returns {String}
		 */
		getLevelClass: function (level, type) {
			var szClass = '';
			if (chat.compareFlag(level, USERLEVEL.ADMIN)) { //1 : 관리자 메세지, COP
				szClass = 'st2';
				if (type == '1')
					szClass = 'snt st3';
				else if (type == '2')
					szClass = 'snt st2';

			} else if (chat.compareFlag(level, USERLEVEL.BJ)) { //2: 방송중인 BJ
				szClass = 'st4';
			} else if (chat.compareFlag(level, USERLEVEL.AUTOMANAGER)) { //6 :매니저
				szClass = 'st5';
			} else if (chat.compareFlag(level, USERLEVEL.MANAGERLIST)) { //7: 매니저
				szClass = 'st5';
			} else if (chat.compareFlag(level, USERLEVEL.SUBBJ)) { //8: 매니저
				szClass = 'st5';
			} else if (chat.compareFlag(level, USERLEVEL.TOPFAN)) { //15:열혈팬
				szClass = 'st6';
			} else if (chat.compareFlag(level, USERLEVEL.FANCLUB)) { //4: 팬클럽
				szClass = 'st7';
			} else { //일반유저
				szClass = '';
			}
			return szClass;
		},

        /**
         * Id에서 괄호 제거
         * @param {string} id
         * @returns string
         */
        realID : function(id){
            var match = id.match(/(\w+)(\(\d\))?/);
            return (!match) ? id : match[1];
        },

		/**
		 * 채팅안보기/해제 레이어 노출 체크
		 * @param {integer} level
		 * @returns integer
		 */
		checkChatHideLayer: function (level) {
			if (chat.compareFlag(level, USERLEVEL.ADMIN)) { //1 : 관리자 메세지, COP
				return false;
			} else if (chat.compareFlag(level, USERLEVEL.BJ)) { //2: 방송중인 BJ
				return false;
			}
			return true;
		},

		/*
		 * 안내 메시지
		 * @param {String} title
		 * @param {String} message
		 */
		infoMessage: function (title, message) {
			messageCallback({
				class: 'st1',
				nickname: $sce.trustAsHtml(title),
				'message': message
			});
		},
		illegalMessage: function (message) {
			messageCallback({
				class: 'illegal',
				nickname: $sce.trustAsHtml(gtext("[아프리카TV 안내]")),
				'message': message
			});
		},
		/*
		 * 어드민 공지 메시지
		 * @param {String} message  메시지
		 */
		adminNoticMessage: function (message) {
			messageCallback({
				class: 'snt',
				nickname: $sce.trustAsHtml(gtext("[아프리카TV 안내]")),
				'message': message
			});
		},
		/*
		 * 공지 메시지
		 * @param {String} message  메시지
		 */
		noticMessage: function (message) {
			messageCallback({
				class: 'ntc st1',
				nickname: $sce.trustAsHtml(""),
				'message': message
			});
		},
		/*
		 * 운영자 채팅 메시지
		 * @param string message
		 * @returns {undefined}
		 */
		adminChatMessage: function (message) {
			messageCallback({
				class: 'snt st3',
				nickname: $sce.trustAsHtml(gtext("운영자 안내")),
				'message': message
			});
		},
		/*
		 * 운영자 귓말 메시지
		 * @param string message
		 * @returns {undefined}
		 */
		adminDirectChatMessage: function (message) {
			messageCallback({
				class: 'st9',
				nickname: $sce.trustAsHtml("운영자의<span>귓말</span>"),
				'message': message
			});
		},
		close: function () {
			bPlay = false;
			_broadInfo = null;
			if (webChat) {
				webChat.disconnect();
				webChat = null;
			}
			if (httpChat) {
				httpChat.disconnect();
				httpChat = null;
			}
		},
		play: function () {
			//채팅연결
			if (!webChat) {
				bPlay = true;
				chat.creatChat();
			}
		},
		stop: function () {
			this.close();
		},

		/*
		 * 로그인
		 */
		login: function () {
			// 0:국내사용자, 1:중국사용자
			var nLanguageType = ($rootScope.szLanguage == 'zh') ? 1 : 0;
			webChat.send(Util.printf('LOGIN%s:%s:%s:%s',
				_broadInfo.channel_info,
				CookieFactory.get('PdboxTicket'),
				nLanguageType,
				Util.getOS() == 'ios' ? 'mweb_ios' : 'mweb_aos'));
		},
		/*
		 * 팬티켓전달
		 */
		join: function () {
			webChat.send(Util.printf('JOIN%d:%s:%s:%s', _broadInfo.chat_no, _broadInfo.fan_ticket, '', _getLogData()))
		},
		/*
		 * 센터서버 연결
		 */
		connectCenter: function () {
			var relayInfo = Util.printf('%s:%d', _broadInfo.relay_ip, _broadInfo.relay_port)
			webChat.send(Util.printf('CONNECTCENTER%s:%d:%s:%s:%s', relayInfo, _broadInfo.broad_no, CookieFactory.get('_au'), _getLogData(), CookieFactory.get('PdboxTicket')))
		},
		/*
		 * 추천서버 요청
		 */
		connectRecommandServer: function () {
			webChat.send('GETITEMADDR');
		},
		/*
		 * 팬티켓전달
		 */
		sendMessage: function (message) {
			webChat.send(Util.printf('CHAT%s', message))
		},
		/**
		 * 블라인드 해제
		 */
		disableBlind: function () {
			webChat.send('DUMBEND');
		},

		getAdminTitle: function (type) {

			var admin = gtext('운영자');
			switch (type) {
				case 1:
					admin = gtext('BJ');
					break;
				case 2:
					admin = gtext('매니저');
					break;
				case 3:
				case 6:
					admin = gtext('운영자');
					break;
				case 4: //임직원
					admin = gtext('운영자');
					break;
				case 5: //클린아티
					admin = gtext('클린아티');
					break;
			}
			return admin;
		},
		getRecommandServer: function () {
			return szRecommandServer.split(':');
		},
		/*
		 * 채팅 패킷처리
		 * @param {String} packet
		 */
		chatHandler: function (packet) {
			var nickname = $sce.trustAsHtml("");
			var message = '';
			var aMsg = packet.split('|');
			switch (aMsg[0].toString()) {
				case "OGQEMOTICON":
					//"OGQEMOTICON|CHNO|MESSAGE|GROUPID|SUBID|VERSION|ID|NICK|FLAG|COLOR|LANGUAGE|TYPE|EXT|"
					var message = aMsg[2].replace(/\r/gi, '');
					messageCallback({
						small_class: '', //TODO :: 작게 보기 인지 체크,지금은 걍 기본 사이즈 고정
						class: chat.getLevelClass(aMsg[8], aMsg[11]),
						chat_id: aMsg[6],
						nickname: $sce.trustAsHtml(aMsg[7]),
						message: message,
						subscription: chat.compareFlag(aMsg[8], USERLEVEL.SUBSCRIPTION), //구독자
						possible_hide: chat.checkChatHideLayer(aMsg[8]),
						isBj : chat.realID(aMsg[6]) == _broadInfo.bj_id,
						image : OGQ_IMG_DOMAIN.KR+"/sticker/"+aMsg[3]+"/"+aMsg[4]+"_"+OGQ_IMG_SIZE.CHAT_DEFAULT+"."+aMsg[13]+"?ver="+aMsg[5],
						isOGQ : true
					});
					break;
				case "LOGIN": // 1:   로그인
					chat.join();
					szRealUserId = aMsg[1];
					loginCallback(szRealUserId);
					break;
				case "JOIN": // 2:   채널참여
					if (!bReconnect) {
						chat.infoMessage(gtext('[안내]'), gtext('방송에 입장하였습니다.'));
					}
					chat.connectCenter();
					chat.connectRecommandServer();
					break;
				case "ALERTMSG": // 수동 메시지
					//aMsg[1]
					break;
				case "GETITEMADDR": // 수동 메시지
					szRecommandServer = aMsg[1];
					break;
				case "QUITCH": // 3:   채널퇴장
					/*
					 0. QUITCH
					 1. QUITCHREP_QUITSTAT,    // 이값을검사해서퇴장사유를알수있다.
					 2. QUITCHREP_REQID,       // 강퇴인경우나가도록명령한아이디
					 3. QUITCHREP_KICKTYPE,    // 강퇴타입, 1:BJ에의해, 2:매니저, 3:어드민, 4:임직원, 5:클린아티
					 4. QUITCHREP_REQREASON,   // 강퇴횟수(등록된 운영자의 아이디에 대해서 강제퇴장 되었을때만 횟수를 전송)
					 */
					//방송 종료로 간주
					chat.noticMessage(gtext('채널 퇴장하셨습니다.'));
					chat.close();
					//var nQuitChCnt;

					//4:임직원, 의해 강퇴 (aMsg[3])/ 강퇴 횟수유무로 비교 하거나 / 강퇴횟수가 있고 타입이 뭔지 확인해서 비교
					if (Number(aMsg[3]) == 3 || Number(aMsg[3]) == 4) {
						var nQuitChCnt = aMsg[4];
						//메인으로 이동
						if (alertCallback) //테스트를 위해/lertCallback(18, 강퇴횟수);
							alertCallback(18, nQuitChCnt);
					} else {
						var adminTitle = chat.getAdminTitle(Number(aMsg[3]));
						alert(gtext('%s에 의해 강제퇴장 되었습니다.', adminTitle));


						//메인으로 이동
						if (alertCallback)
							alertCallback(18);
					}


					break;
				case "CHATMESG": // 5:   채팅메시지

					var message = aMsg[1].replace(/\r/gi, '');
					if (chat.compareFlag(aMsg[5], USERLEVEL.ADMIN)) {
						aMsg[4] = gtext("운영자 안내") + ":";
					}

					//운영자 채팅 메시지
					if (aMsg[6] == '1') {
						chat.adminChatMessage(message);
						break;
					}
					messageCallback({
						class: chat.getLevelClass(aMsg[5], aMsg[6]),
						chat_id: aMsg[2],
						nickname: $sce.trustAsHtml(aMsg[4]),
						message: message,
						subscription: chat.compareFlag(aMsg[5], USERLEVEL.SUBSCRIPTION), //구독자
						possible_hide: chat.checkChatHideLayer(aMsg[5]),
						isBj : chat.realID(aMsg[2]) == _broadInfo.bj_id
					});
					break;
				case "BJNOTICE":
					var state = parseInt(aMsg[2]) == 1 ? true : false;
					var message = aMsg.slice(4, aMsg.length - 1).join("|"); // 구분자가 | 라서 |는 못온다. 언젠가 구분자를 바꿔야함
					noticeCallback(state, message);
					break;
				case "DIRECTCHAT": //운영자 귓말
					var message = aMsg[1].replace(/\r/gi, '');
					if (aMsg[4] == '4' || aMsg[4] == '5' || aMsg[4] == '6') {
						chat.adminDirectChatMessage(message);
						break;
					}
					break;
				case "SENDADMINNOTICE": // 서버 공지사항
					chat.adminNoticMessage(aMsg[1], true);
					break;
				case "SETCHNAME": // 6:   채널이름세팅
					break;
				case "SETBJSTAT": // 7:   방장상태변경
					switch (Number(aMsg[1])) {
						case 0: //방송종료
							alert(gtext('BJ가 방송을 종료하였습니다.'));
							chat.close();
							if (closeCallback)
								closeCallback();
							break;
					}
					break;
				case "CLOSECH":
					/*
					 0 CLOSECH
					 1 방송번호
					 2 종료타입
					 3 종료인사말
					 */
					chat.noticMessage(aMsg[3]);
					break;
				case "SETDUMB": // 8:   벙어리지정
					/*
					 * SETDUMB|lomi525(2)|1130504|0|30|1|lomi525|매그니토2|1|
					 0.SETDUMB
					 1.lomi525(2)    //벙어리 지정된 아이디
					 2.1130504       // 플래그
					 3.0             // 플래그
					 4.30            // 벙어리 유지 시간(초)
					 5.1             // 벙어리 지정 횟수
					 6.lomi525       // 벙어리 요청한 아이디
					 7.매그니토2     // 벙어리 지정된 닉네임
					 8.1             // 채팅금지타입, 1:BJ에의해, 2:매니저, 3:어드민, 4:임직원, 5:클린아티
					 */
					///> 지정된 시간 이후 벙어리 해지 패킷 보냄
					///> 벙어리 타켓이 나 일 경우
					var adminTitle = chat.getAdminTitle(Number(aMsg[8]))

					switch (Number(aMsg[5])) {
						case 1:
							chat.infoMessage(gtext('[안내]'), gtext('%s 님이 채팅금지 %s회가 되었습니다', aMsg[7], aMsg[5]));
							if (szRealUserId == aMsg[1]) {
								alert(gtext('%s님은 %s에 의해 채팅금지 되었습니다. 30초 동안 채팅이 금지 됩니다.', aMsg[7], adminTitle));
							}
							break;
						case 2:
							chat.infoMessage(gtext('[안내]'), gtext('%s 님이 채팅금지 %s회가 되었습니다.', aMsg[7], aMsg[5]));
							if (szRealUserId == aMsg[1]) {
								alert(gtext('%s님은 %s에 의해 채팅금지 되었습니다. 60초 동안 채팅이 금지 됩니다.', aMsg[7], adminTitle));
							}
							break;
						case 3:
						default:
							chat.infoMessage(gtext('[안내]'), gtext('%s 님이 채팅 금지 횟수 초과로 블라인드 되었습니다. 2분 동안 채팅과 방송화면을 볼 수 없습니다.', aMsg[7]));
							if (szRealUserId == aMsg[1]) {
								alert(gtext('%s님은 %s에 의해 채팅금지 횟수 초과로 인해 2분간 블라인드 처리됩니다. 블라인드 상태에서는 화면과 채팅이 보이지 않으며 블라인드 상태로 방송에서 나갈 경우 자동 강제퇴장 처리되며 방송 재입장이 불가능 합니다.', aMsg[7], adminTitle));
								//화면 막음
								alertCallback(20);
							}
							break;
					}
					break;
				case 'BLINDEXIT':
					//BLINDEXIT|lomi525(2)|실론티옹8|
					/*
					 0 BLINDEXIT|
					 1 lomi525(2)|		// 벙어리 지정된 아이디
					 2 실론티옹8||			// 플래그
					 */
					chat.infoMessage(gtext('[안내]'), gtext('%s(%s) 님이 블라인드 상태에서 탈출을 시도하여 강제 퇴장되었습니다.', aMsg[2], aMsg[1]));
					if (blockChatClearCallback)
						blockChatClearCallback(aMsg[1])
					break;
				case "KICK": // 11:  강퇴

					chat.infoMessage(gtext('[안내]'), gtext('%s(%s)님이 강제퇴장 되었습니다.', aMsg[2], aMsg[1]));
					if (blockChatClearCallback)
						blockChatClearCallback(aMsg[1])
					//강제 퇴장처리
					if (szRealUserId == aMsg[1]) {
						if (alertCallback)
							alertCallback(18);
					}
					break;
				case "KICKCANCEL": //강퇴취소
					chat.infoMessage(gtext('[안내]'), gtext('%s(%s)님의 강제퇴장이 취소되었습니다.', aMsg[2], aMsg[1]));
					break;
				case "SENDBALLOON": // 18:  별풍선선물     // 비데몬에서직접접속한다.
					/*
					 0: SENDBALLOON
					 1: SENDBALLOONREP_BJ,			// 받는놈 (뒤에 아이디 순번까지 보내야 함)
					 2: SENDBALLOONREP_VIEWER,		// 보내는놈
					 3: SENDBALLOONREP_VIEWERNICK,	// 보낸놈 닉네임
					 4: SENDBALLOONREP_CNT,			// 별풍선 갯수
					 5: SENDBALLOONREP_FAN_SEQ,		// 팬클럽 가입 순서 (0: already fan, n: fan sequence)
					 6: SENDBALLOONREP_FAN_CHIEF,	// 팬클럽 회장 선정 유무 (0: not cheif, 1: become cheif)
					 7: SENDBALLOONREP_CHNO,		// 채팅방 번호
                     8: SENDBALLOONREP_FILE_NAME,   // 파일명
                     9: SENDBALLOONREP_IS_DEFAULT   // 기본 이미지 여부
					 */
                    giftCallback('starballoon', aMsg[3], aMsg[4], (Number(aMsg[9])!==1 ? aMsg[8] : ''));
					chat.noticMessage(gtext('%s님이 별풍선 %s개를 선물했습니다!', aMsg[3], aMsg[4]));
					if (Number(aMsg[5]) > 0) {
						chat.noticMessage(gtext('%s님이 %s번째로 팬클럽이 되셨습니다.', aMsg[3], aMsg[5]));
					}
					break;
				case "SENDBALLOONSUB": // 33:  별풍선선물(중계방)
					/*
					 0. SENDBALLOONSUB
					 1. SENDBALLOONSUBREP_CHNO,			// 채팅방 번호 (본방은 본방 채팅방 번호, 중계방은 중계방 채팅방 번호)
					 2. SENDBALLOONSUBREP_BJ,			// 받는놈 (뒤에 아이디 순번까지 보내야 함)
					 3. SENDBALLOONSUBREP_BJNICK,		// BJ닉네임
					 4. SENDBALLOONSUBREP_VIEWER,		// 보내는놈
					 5. SENDBALLOONSUBREP_VIEWERNICK,	// 보낸놈 닉네임
					 6. SENDBALLOONSUBREP_CNT,			// 별풍선 갯수
					 7. SENDBALLOONSUBREP_FAN_SEQ,		// 팬클럽 가입 순서 (0: already fan, n: fan seqence)
					 8. SENDBALLOONSUBREP_FAN_CHIEF,	// 팬클럽 회장 선정 유무 (0: not cheif, 1: become cheif)
					 9: SENDBALLOONREP_FILE_NAME,       // 파일명
                     10: SENDBALLOONREP_IS_DEFAULT      // 기본 이미지 여부
					 */
                    giftCallback('starballoon', aMsg[5], aMsg[6], (Number(aMsg[10])!==1 ? aMsg[9] : ''));
                    chat.noticMessage(gtext('중계방에서 %s님이 별풍선 %s개를 선물했습니다!', aMsg[5], aMsg[6]));
					if (Number(aMsg[7]) > 0) {
						chat.noticMessage(gtext('%s님이 %s번째로 팬클럽이 되셨습니다.', aMsg[5], aMsg[7]));
					}
					break;
				case "VODBALLOON":
					/*
                    0: VODBALLOON
					1: VODBALLOONREQ_BJ,			// 받는놈(뒤에아이디순번까지보내야함)
					2: VODBALLOONREQ_VIEWER,		// 보내는놈
					3: VODBALLOONREQ_VIEWERNICK,	// 보내는놈닉네임
					4: VODBALLOONREQ_CNT,			// 별풍선갯수
					5: VODBALLOONREQ_FILE_NAME,     // 별풍선이미지파일이름(ex null, 500, 500_00140003)
					6: VODBALLOONREQ_IS_DEFAULT,	// 별풍선기본이미지여부(0, 1)
                    7: VODBALLOONREQ_MSG,           // 선물 메세지
                    8: VODBALLOONREQ_CHNO,
					 */
					giftCallback('vodballoon', aMsg[3], aMsg[4], (Number(aMsg[6])!==1 ? aMsg[5] : ''));
                    chat.noticMessage(gtext('VOD에서 %s님이 별풍선 %s개를 선물했습니다!', aMsg[3], aMsg[4]));
					break;
                case "VIDEOBALLOON": // 105:  비디오 풍선 선물
					/*
                     0: VIDEOBALLOON
                     1: VIDEOBALLOONREP_CHNO,         // 채팅방 번호 (본방은 본방 채팅방 번호, 중계방은 중계방 채팅방 번호)
                     2: VIDEOBALLOONREP_BJ,           // BJ아이디
                     3: VIDEOBALLOONREP_VIEWER,       // 보내는사람 아이디
                     4: VIDEOBALLOONREP_VIEWERNICK,   // 보내는사람 닉네임
                     5: VIDEOBALLOONREP_CNT,          // 별풍선 수
                     6: VIDEOBALLOONREP_FAN_SEQ,      // 팬클럽 가입 순서 (0: already fan, n: fan seqence)
                     7: VIDEOBALLOONREP_FAN_CHIEF,    // 팬클럽 회장 선정 유무 (0: not cheif, 1: become cheif)
                     8: VIDEOBALLOONREP_TOPFAN,       // 열혈팬 여부(0, 1)
                     9: VIDEOBALLOONREP_SUBROOM,      // 중계방 선물 여부 (0: 본방, 1: 중계방)
                     10: VIDEOBALLOONREP_TITLENO,      // 영상 타이틀번호
                     11: VIDEOBALLOONREP_START,        // 후원 시작 구간 (초)
                     12: VIDEOBALLOONREP_DURATION,     // 총 후원 시간 (초)
                     13: VODBALLOONREQ_FILE_NAME,       // 별풍선이미지파일이름(ex null, 500, 500_00140003)
					 14: VODBALLOONREQ_IS_DEFAULT,      // 별풍선기본이미지여부(0, 1)
					 */
                    giftCallback('videoballoon', aMsg[4], aMsg[5], (Number(aMsg[14])!==1 ? aMsg[13] : ''));
                    if(Number(aMsg[9])===1) {
                        chat.noticMessage(gtext('중계방에서 %s님이 영상풍선 %s개를 선물했습니다!', aMsg[4], aMsg[5]));                        
                    } else {
                        chat.noticMessage(gtext('%s님이 영상풍선 %s개를 선물했습니다!', aMsg[4], aMsg[5]));
                    }
					if (Number(aMsg[6]) > 0) {
						chat.noticMessage(gtext('%s님이 %s번째로 팬클럽이 되셨습니다.', aMsg[4], aMsg[6]));
					}
					break;      
                case "ADCONEFFECT" :
                    /*
                    1: ADCONEFFECTREP_CHNO,		// 채팅방 번호
					2: ADCONEFFECTREP_BJID,		// BJ 아이디
					3: ADCONEFFECTREP_UID,			// 유저 아이디
					4: ADCONEFFECTREP_UNICK,		// 유저 닉네임
					5: ADCONEFFECTREP_MSG,			// 출력 메시지
					6: ADCONEFFECTREP_MSG2,		// 출력 메시지
					7: ADCONEFFECTREP_TITLE,		// 설치한 앱 제목
					8: ADCONEFFECTREP_URL_IMG,		// 제휴사 이미지 URL
					9: ADCONEFFECTREP_URL_DEFAULT,	// 기본 이미지 URL
					10: ADCONEFFECTREP_CNT,			// ADCON 개수
					11: ADCONEFFECTREP_FAN_SEQ,		// 팬클럽 가입 순서 (0: already fan, n: fan sequence)
					12: ADCONEFFECTREP_TOP_FAN,		// 열혈팬 여부 (0: not topfan, 1: become topfan)
					13: ADCONEFFECTREP_FAN_CHIEF,	// 팬클럽 회장 선정 유무 (0: not cheif, 1: become cheif)
					14: ADCONEFFECTREP_SUB_ROOM,	// 본방, 중계방 여부 (0:본방, 1: 중계방)
					*/
					//type, nickname, count, code
					giftCallback('adcon', aMsg[4], aMsg[10], aMsg[8]);
					if(Number(aMsg[10]) > 0) {
						if(Number(aMsg[14]) === 1) {
							chat.noticMessage(gtext('%s님이 %s 중계방에서 애드벌룬 %s개를 선물 했습니다.', aMsg[4], aMsg[7], aMsg[10]));
						} else {
							chat.noticMessage(gtext('%s님이 %s 애드벌룬 %s개를 선물 했습니다.', aMsg[4], aMsg[7], aMsg[10]));
						}									
					} else {
						chat.noticMessage(gtext('%s님이 %s 애드벌룬 신청 완료를 했습니다!',aMsg[4],aMsg[7]));
					}

 					if (Number(aMsg[11]) > 0) {
						chat.noticMessage(gtext('%s님이 %s번째로 팬클럽이 되셨습니다.', aMsg[4], aMsg[11]));
					}
					break;
				case "VODADCON" :
					/*
					1: VODADCONREP_BJ,			// BJ 아이디
					2: VODADCONREP_VIEWER,		// 선물한유저 아이디
					3: VODADCONREP_VIEWERNICK,	// 선물한유저 닉네임
					4: VODADCONREP_CNT,		// 애드벌룬 갯수
					5: VODADCONREP_IS_DEFAULT,	// 애드벌룬이미지 주소
					6: VODADCONREP_MSG,		// 애드벌룬 메세지
					7: VODADCONREP_CHNO,		// 채팅방 번호	
					*/
					//type, nickname, count, code
					giftCallback('adcon', aMsg[3], aMsg[4], aMsg[5]);
 					if (Number(aMsg[4]) > 0) {
 						//혹시 번역 하게 되면 수정해야됨
						chat.noticMessage(gtext('동영상에서 %s님이 애드벌룬 %s개를 선물 했습니다.', aMsg[3], aMsg[4]));
					}
					break;
				case "STATIONADCON" :
					/*
					1: STATIONADCONREP_BJ,			// BJ 아이디
					2: STATIONADCONREP_VIEWER,		// 선물한유저 아이디
					3: STATIONADCONREP_VIEWERNICK,	// 선물한유저 닉네임
					4: STATIONADCONREP_CNT,		// 애드벌룬 갯수
					5: STATIONADCONREP_IS_DEFAULT,	// 애드벌룬이미지 주소
					6: STATIONADCONREP_MSG,		// 애드벌룬 메세지
					7: STATIONADCONREP_CHNO,		// 채팅방 번호	
					*/
					//type, nickname, count, code
					giftCallback('adcon', aMsg[3], aMsg[4], aMsg[5]);
 					if (Number(aMsg[4]) > 0) {
 						//혹시 번역 하게 되면 수정해야됨
						chat.noticMessage(gtext('방송국에서 %s님이 애드벌룬 %s개를 선물 했습니다.', aMsg[3], aMsg[4]));
					}
					break;
				case "ICEMODE": // 19:  채팅얼리기(채팅금지)
					/*
					 0 ICEMODE
					 1 ICEMODEREP_ON,				//	1: 채팅창 얼리기, 0: 풀기
					 2 ICEMODEREP_ICEMODETYPE,		//	0: 풀기,
					 1: 얼리기(BJ,매니저 채팅가능)
					 2: 얼리기(BJ,매니저, 팬클럽)
					 3: 얼리기(BJ,매니저, 서포터)
					 4: 얼리기(BJ,매니저, 팬클럽, 서포터)
					 */
					///> 얼리기
					if (Number(aMsg[1]) === 1) {
						var szMsg = '';
						if (freezeStatus === false) {
							szMsg = gtext('채팅창을 얼렸습니다.');
						} else {
							szMsg = gtext('채팅 참여 등급이 변경되었습니다.');
						}
						freezeStatus = aMsg[2];
						switch (Number(aMsg[2])) {
							case 1:
								chat.infoMessage(gtext('[안내]'), szMsg + gtext(' BJ와 매니저만 채팅에 참여할 수 있습니다.'));
								break;
							case 2:
								chat.infoMessage(gtext('[안내]'), szMsg + gtext(' BJ와 매니저, 팬클럽만 채팅에 참여할 수 있습니다.'));
								break;
							case 3:
								chat.infoMessage(gtext('[안내]'), szMsg + gtext(' BJ와 매니저, 서포터만 채팅에 참여할 수 있습니다.'));
								break;
							case 4:
								chat.infoMessage(gtext('[안내]'), szMsg + gtext(' BJ와 매니저, 팬클럽, 서포터만 채팅에 참여할 수 있습니다.'));
								break;
						}
					} else {
						freezeStatus = false;
						chat.infoMessage(gtext('[안내]'), gtext('채팅창을 녹였습니다. 채팅에 참여할 수 있습니다.'));
					}
					break;
				case "SENDFANLETTER": // 20:  팬레터보내기
					/*
					 * ["SENDFANLETTER", "oky0707", "oky0707", "lomi525", "실론티옹8", "", "235", "", "1", "0", "229", ""]
					 0: SENDFANLETTER |
					 1: SENDFANLETTERREP_BJID,         // BJ아이디 (뒤에 아이디 순번까지 보내야 함)
					 2: SENDFANLETTERREP_BJNICK,       // BJ닉네임
					 3: SENDFANLETTERREP_SENDERID,     // 보내는 놈 아이디
					 4: SENDFANLETTERREP_SENDERNICK,   // 보내는 놈 닉네임
					 5: SENDFANLETTERREP_IMAGEURL,     // 이미지 URL
					 6: SENDFANLETTERREP_PAPER,        // 편지지 종류
					 7: SENDFANLETTERREP_MESSAGE,      // 내용
					 8: SENDFANLETTERREP_ITEMCOUNT,    // 펜레터 카운트
					 9: SENDFANLETTERREP_SPTR_SEQ,		// 스티커 서포터 가입 순서 (0: already supporter, n: supporter sequence)
					 10: SENDFANLETTERREP_CHNO,			// 채팅방 번호
					 */
					giftCallback('sticker', aMsg[4], aMsg[8], aMsg[6]);
					chat.noticMessage(gtext('%s님이 스티커 %s개를 선물했습니다!', aMsg[4], aMsg[8]));
					if (Number(aMsg[9]) > 0) {
						chat.noticMessage(gtext('%s님이 %s번째로 서포터가 되셨습니다.', aMsg[4], aMsg[9]));
					}
					break;
				case "SENDFANLETTERSUB": // 34:  스티커선물(중계방)
					/*
					 0: SENDFANLETTERSUB |
					 1 SENDFANLETTERSUBREP_CHNO,         // 채팅방 번호 (본방은 본방 채팅방 번호, 중계방은 중계방 채팅방 번호)
					 2 SENDFANLETTERSUBREP_BJID,         // BJ아이디 (뒤에 아이디 순번까지 보내야 함)
					 3 SENDFANLETTERSUBREP_BJNICK,       // BJ닉네임
					 4 SENDFANLETTERSUBREP_SENDERID,     // 보내는 놈 아이디
					 5 SENDFANLETTERSUBREP_SENDERNICK,   // 보내는 놈 닉네임
					 6 SENDFANLETTERSUBREP_IMAGEURL,     // 이미지 URL
					 7 SENDFANLETTERSUBREP_PAPER,        // 편지지 종류
					 8 SENDFANLETTERSUBREP_MESSAGE,      // 내용
					 9 SENDFANLETTERSUBREP_ITEMCOUNT,    // 펜레터 카운트
					 10 SENDFANLETTERSUBREP_SPTR_SEQ,	  // 스티커 서포터 가입 순서 (0: already supporter, n: supporter squence)
					 11 SENDFANLETTERSUBREP_COLS
					 SENDFANLETTERSUB|1076|sksk230|환이,|mobqq05|기개가그가||248||1|0|
					 */
					giftCallback('sticker', aMsg[4], aMsg[8], aMsg[7]);
					chat.noticMessage(gtext('중계방에서 %s님이 스티커 %s개를 선물했습니다!', aMsg[5], aMsg[9]));
					if (Number(aMsg[10]) > 0) {
						chat.noticMessage(gtext('%s님이 %s번째로 서포터가 되셨습니다.', aMsg[5], aMsg[10]));
					}
					break;
				case "CHOCOLATE": // 37:  초콜릿선물(데몬)
					/*
					 0: CHOCOLATE
					 1: SENDCHOCOLATEREQ_CHNO,		// 채팅방 번호
					 2: SENDCHOCOLATEREQ_BJ,			// 받는놈 (뒤에 아이디 순번까지 보내야 함)
					 3: SENDCHOCOLATEREQ_VIEWER,		// 보내는놈
					 4: SENDCHOCOLATEREQ_VIEWERNICK,	// 보내는놈 닉네임
					 5: SENDCHOCOLATEREQ_CNT			// 초콜릿 갯수
					 */
					giftCallback('chocolate', aMsg[4], aMsg[5]);
					chat.noticMessage(gtext('%s님이 초콜릿 %s개를 선물했습니다!', aMsg[4], aMsg[5]));
					break;
				case "CHOCOLATESUB": // 38:  초콜릿선물(데몬 :중계방)
					/*
					 0. CHOCOLATESUB
					 1. SENDCHOCOLATESUBREP_CHNO,		// 채팅방 번호 (본방은 본방 채팅방 번호, 중계방은 중계방 채팅방 번호)
					 2. SENDCHOCOLATESUBREP_BJ,			// 받는놈 (뒤에 아이디 순번까지 보내야 함)
					 3. SENDCHOCOLATESUBREP_VIEWER,		// 보내는놈
					 4. SENDCHOCOLATESUBREP_VIEWERNICK,	// 보낸놈 닉네임
					 5. SENDCHOCOLATESUBREP_CNT,		// 초콜릿 갯수
					 6. SENDCHOCOLATESUBREP_COLS
					 */
					giftCallback('chocolate', aMsg[4], aMsg[5]);
					chat.noticMessage(gtext('중계방에서 %s님이 초콜릿 %s개를 선물했습니다!', aMsg[4], aMsg[5]));
					break;
				case "SENDQUICKVIEW": // 45:  퀵뷰선물
					/*
					 0: SENDQUICKVIEW
					 1: SENDQUICKVIEWREP_CHNO,				// 채팅방 번호
					 2: SENDQUICKVIEWREP_SENDER,			// 보내는 사람ID
					 3: SENDQUICKVIEWREP_SENDERNICK,		// 보내는 사람NICK
					 4: SENDQUICKVIEWREP_ID,				// 받는 사람ID
					 5: SENDQUICKVIEWREP_NICK,				// 받는 사람NICK
					 6: SENDQUICKVIEWREP_ITEMTYPE,			// 아이템 타입(1: 30일권, 2:90일권, 3:365일권)
					 7: SENDQUICKVIEWREP_ITEMCODE,			// 아이템 코드(퀵뷰 사용시 필요한 코드)
					 */
					switch (Number(aMsg[6])) {
						case 1:
							szType = 30;
							break;
						case 2:
							szType = 90;
							break;
						case 3:
							szType = 365;
							break;
					}
					chat.noticMessage(gtext('%s 님이 %s님에게 퀵뷰 %s일권을 선물했습니다!', aMsg[3], aMsg[5], szType));
					break;
				case "SENDSUBSCRIPTION" : // 108 : 구독권 선물
					/*
					 0: SENDSUBSCRIPTION
					 1: SENDSUBSCRIPTIONREP_CHNO,				// 채팅방 번호
					 2: SENDSUBSCRIPTIONREP_SENDER,			// 보내는 사람ID
					 3: SENDSUBSCRIPTIONREP_SENDERNICK,		// 보내는 사람NICK
					 4: SENDSUBSCRIPTIONREP_ID,				// 받는 사람ID
					 5: SENDSUBSCRIPTIONREP_NICK,				// 받는 사람NICK
					 6: SENDSUBSCRIPTIONREP_BJ,			// 구독대상 BJ
					 7: SENDSUBSCRIPTIONREP_BJNICK,			// 구독대상 BJ NICK
					 8: SENDSUBSCRIPTIONREP_ITEMTYPE,			// 아이템 타입(1: 30일, 2:90일, 3:180일)
					 9: SENDSUBSCRIPTIONREP_ITEMCODE,			// 아이템 코드(필요?)
					 10: SENDSUBSCRIPTIONREP_IS_SUB,			// 피선물자 구독 대상 BJ 구독 여부
					 11: SENDSUBSCRIPTIONREP_SUB_TYPE,			// 피선물자 구독 결제 타입 (-1:일반 구독, 0:동영상 일반 구독, 1:정기권 3개월, 2:정기권 6개월, 3:정기권 12개월, 4:동영상 정기권 3개월, 5:동영상 정기권 6개월, 6:동영상 정기권 12개월, 7:선물권 30일, 8:선물권 90일, 9:선물권 180일, 10:동영상 선물권 30일, 11:동영상 선물권 90일, 12:동영상 선물권 180일)
					 */
					switch (Number(aMsg[8])) {
						case 1:
							szType = 30;
							szMonth = 1;
							break;
						case 2:
							szType = 90;
							szMonth = 3;
							break;
						case 3:
							szType = 180;
							szMonth = 6;
							break;
					}
					chat.noticMessage(gtext('%s 님이 %s님에게 %s %s개월 구독권(%s일)을 선물했습니다!', aMsg[3], aMsg[5], aMsg[7], szMonth, szType));
					break;
				break;
                case "SETCHINFO":
					//채널정보
					if (Number(aMsg[1]) === 19) {
						chat.close();
						if (alertCallback)
							alertCallback(19);
					}
					break;
				case "GETUSERCNT": // device 정보 넘길 시점
					/*
					 0
					 1,		// 채팅방 번호
					 2,		// PC
					 3,		// 모바일
					 4,     // 중계방합계
					 */

					break;
				case "GETUSERCNTEX":

					/*
					 1. 부모 방송번호 (0이면 본방, 아니면 중계방)
					 2. 참여중인 채널의 방송번호
					 3. 중계방 수
					 4. 참여중인 채널의 PC 유저수
					 5. 참여중인 채널의 MB 유저수
					 6. 본방의 PC 유저수
					 7. 본방의 MB 유저수
					 8. 전체 중계방의 PC 유저수
					 9. 전체 중계방의 MB 유저수
					 10. 본방의 누적 PC 유저수
					 11. 본방의 누적 MB 유저수
					 12. 전체 중계방의 누적 PC 유저수
					 13 전체 중계방의 누적 MB 유저수
					 */
					//부모 방송 번호가 0번이면 본방, 아니면 중계방

					var aViewerCnt = {};
					aViewerCnt['broad_type'] = Number(aMsg[1]);

					aViewerCnt['current_broad_viewer'] = Number(aMsg[4]) + Number(aMsg[5]); //참여중인 채널의 유저수
					aViewerCnt['current_broad_pc'] = Number(aMsg[4]); //참여중인 채널의 pc유저수
					aViewerCnt['current_broad_mobile'] = Number(aMsg[5]); //참여중인 채널의 모바일 유저수
					aViewerCnt['accumulate_viewer'] = Number(aMsg[10]) + Number(aMsg[11]) + Number(aMsg[12]) + Number(aMsg[13]);

					if (aMsg[1] == 0) {
						aViewerCnt['diff_broad_viewer'] = Number(aMsg[8]) + Number(aMsg[9]); //전체 중계방의 유저수
					} else {
						aViewerCnt['diff_broad_viewer'] = Number(aMsg[6]) + Number(aMsg[7]); //본방의 유저수
					}
					aViewerCnt['total_viewer'] = Number(aMsg[6]) + Number(aMsg[7]) + Number(aMsg[8]) + Number(aMsg[9]);

					viewerBoxCallback(aViewerCnt);
					break;
				case "CHATERROR": // device 정보 넘길 시점
					///> device 정보를 넘긴다.
					chat.noticMessage(aMsg[3]);
					if (aMsg[1] == 2) {
						if (clearCallback)
							clearCallback();
						if (alertCallback)
							alertCallback(21);
					}
					break;
				case "GETCHINFO": // device 정보 넘길 시점
					///> device 정보를 넘긴다.
					//console.log("CHROMEUV" + BROAD_INFO.szDeviceId);
					break;
				case "MOBBROADPAUSE": //방송 일시정지기능
					pauseCallback(aMsg[1] == '0' ? true : false);
					break;
				case "MULTIBROAD": //방송 일시정지기능
					var points = [];
					for (var i = 0; i < aMsg[3]; i++) {
						point = aMsg[4 + i].split(',').map(function (val) {
							return parseInt(val);
						});
						points.push(point);
					}
					stateCallback('multi_view', {
						'width': parseInt(aMsg[1]),
						'height': parseInt(aMsg[2]),
						points: points
					})
					pauseCallback(aMsg[1] == '0' ? true : false);
					break;
				case "DIRECTCHAT": // 9:   직접대화(1:1)
				case "NOTICE": // 10:  공지(전체) 메시지 :관리자만가능
				case "SETSUBBJ": // 13:  부방장지정
				case "SETNICKNAME": // 14:  닉네임변경
				case "CLUBCOLOR": // 17:  팬클럽글자색지정
				case "BLINDKICK": // 25:  블라인드퇴장시강퇴
				case "MANAGERCHAT": // 26:  메니저채팅
				case "APPENDDATA": // 27:  강퇴누적패널티
				case "SNSMESSAGE": // 31:  SNS 메세지(소셜스트림)
				case "SNSMODE": // 32:  SNS 메세지(소셜스트림) ON = 1/ OFF = 0
				case "BJSTICKERITEM": // 36:  BJ 스티커아이템설정 :알림
				case "TOPCLAN": // 39:  열혈클랜원(데몬)
				case "TOPCLANSUB": // 40:  열혈클랜원(데몬 :중계방)
				case "SUPERCHAT": // 41:  슈퍼채팅(데몬)
				case "UPDATETICKET": // 42:    티켓갱신(팬티켓복호화한뒤유저플래그갱신)
				case "NOTIGAMERANKER": // 43:  게임신 :TOP20 입장시채널로알려줌
				case "STARCOIN": // 44: 스타코인(글로벌별풍선)
				case "ITEMSTATUS": // 46:  아이템사용여부확인
				case "ITEMUSING": // 47:  아이템사용
				case "USEQUICKVIEW": // 48:  퀵뷰선물권사용
				case "ICEMODERELAY": // 49:  채팅얼리기(채팅금지 :중계방)
				case "NOTIFYPOLL": // 50:  투표상태변경알림
				case "CHATBLOCKMODE": // 51:    채팅차단모드설정(설정된유저는SVC_CHATMESG를받지않음)
					break;
				default:
					break;
			}
		}
	}
	return chat;
}

module.exports = ChatFactory;
