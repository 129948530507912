
var toastr = require('toastr');
LivePlayerGiftController.$inject = ['$scope', '$rootScope', '$filter', '$location', '$timeout', '$state', '$stateParams', '$window', '$http', 'GiftService', 'ItemService', 'Util', 'LoginManager', 'PmService', 'gtext'];
function LivePlayerGiftController($scope, $rootScope, $filter, $location, $timeout, $state, $stateParams, $window, $http, GiftService, ItemService, Util, LoginManager, PmService, gtext) {

	var itemList = $scope.$parent.itemList;
	var chat_id = $scope.$parent.chat_id;
	var balloonCodes = [];
	var _broadInfo = {};
	$scope.balloons = [];
	$scope.stickers = [];
	$scope.chocolates = [];
	$scope.count = 10;
	$scope.bFunnding = false;
	//초기 아이템 셋팅
	{
		/**
		 * 별풍선 리스트
		 */
		var counts = [10, 50, 100, 300, 500]
		angular.forEach(counts, function (value, key) {
			this.push({
				type: 'starballoon',
				code: value,
				count: value,
				title: value,
				thumb: STATIC_FILE_AFREECATV + '/new_player/items/thumb/balloon_' + value + '.png'
			});
		}, $scope.balloons);
		$scope.selectItem = $scope.balloons[0]; //초기 선택

		/**
		 * 스티커 리스트
		 */
		var sticker = itemList.sticker;
		angular.forEach(sticker.list, function (value, key) {
			this.push({
				type: 'sticker',
				code: value.code,
				count: 10,
				title: value.title,
				thumb: (sticker.url + sticker.thumb_prefix + value.code + '.png'),
				url: (sticker.url + sticker.image_prefix + value.code + '.png')
			});
		}, $scope.stickers);

		/*
		 * 초콜릿 아이켐 리스트
		 */
		var counts = [5, 10, 50, 100]
		angular.forEach(counts, function (value, key) {
			this.push({
				type: 'chocolate',
				code: value,
				count: value,
				title: value,
				thumb: RES_AFREECATV + '/new_player/items/thumb/chocolate_' + value + '.png'
			});
		}, $scope.chocolates);

		_broadInfo = $scope.$parent.getBroadInfo()

		$scope.tab = 'starballoon';

	}





	/**
	 * 탭 선택시
	 */
	$scope.$watch('tab', function (type) {
		switch (type) {
			case 'starballoon':
				$scope.count = 10;
				$scope.selectItem = angular.copy($scope.balloons[0]);
				break;
			case 'gold':
				$scope.count = 10;
				$scope.selectItem = angular.copy($scope.stickers[0]);
				break;
			case 'chocolate':
				$scope.count = 5;
				$scope.selectItem = angular.copy($scope.chocolates[0]);
				break;
		}
	});

	/**
	 * 선택한 아이템
	 * @param {type} item
	 * @returns {undefined}
	 */
	$scope.selectItemHandler = function (item) {
		$scope.selectItem = angular.copy(item);
		if (item.type != 'sticker') {
			$scope.count = item.count
		}
	}

	/**
	 * 직접입력에 포커스가 맞춰졌을때(클릭햇을때
	 * @param {type} $event
	 * @returns {undefined}
	 */
	$scope.numberFocusHandler = function ($event) {
		$scope.count = "";
	}


	/**
	 * 구매하기
	 * @param {type} type
	 * @param {object} windowReference
	 * @returns {undefined}
	 */
	$scope.buy = function (type, windowReference) {
		// 비디오 포탈이나, OllehTvMobile로 구매하기 요청시 막음(해당 웹뷰서 동작하지 않음)
		if (/videoportal/.test(navigator.userAgent) || $scope.bOllehTvMobile) {
			windowReference.close();
			toastr.warning("앱을 설치하시면 구매가 가능합니다.");

			return false;
		}

		// 아이템 구매하기 URL정보
		var oItemUrl = {
			ko: {
				starballoon: POINT_AFREECATV
				, gold: POINT_AFREECATV
				, quickview: POINT_AFREECATV
			}
			// 페이레이터 페이지에서 cookie값을 이용하여 국적을 판단하여 사용함.
			, abroad: {
				starballoon: POINT_AFREECATV
				, gold: POINT_AFREECATV
				, quickview: POINT_AFREECATV
			}
		};

		// 구매
		var data = {
			ndevice: Util.getOS() == 'ios' ? 1 : 2,
			szWork: 'help',
			szKind: 'TBOX20131007000000',
			szUserID: $rootScope.member.user_id ? $rootScope.member.user_id : '',
			szVersion: '1.0.0',
			szModel: 'web',
			broadno: _broadInfo['broad_no'],
			broad_no: _broadInfo['broad_no'],
			sys_type: 'webm',
			location: 'live'
		};

		// 별풍선, gold, quickview 구매하기 || 한국코드, 한국코드가 아닌경우에 구매 URL 변경
		var oUrlInfo = oItemUrl.ko;
		
		windowReference.location = oUrlInfo[type];// + $.param(data);
		$state.go("player");
	};


	/**
	 * 닫기 버튼
	 */
	$scope.closeHandler = function () {
		$state.go("player");
	};


	/**
	 * 선물하기전 PM체크
	 */
	$scope.checkPmAndGift = function (szType) {
		PmService.check({ szItem: $scope.selectItem.type, szFunction: 'gift', szAgent: 'web' }).then(function (response) {
			$scope.sendHandler();
		}, function (response) {
			if (response.result == -1 && response.data.code == -1200) {
				alert(response.data.message);
				return;
			}
			$scope.sendHandler();
		});

	};
	/**
	 * 구매하기전 PM체크
	 */
	$scope.checkPmAndBuy = function (szType) {
		// 팝업차단 때문에 새창을 열고 시작함
		var windowReference = window.open();
		PmService.check({ szItem: $scope.selectItem.type, szFunction: 'buy', szAgent: 'web' }).then(function (response) {
			$scope.buy(szType, windowReference);
		}).catch(function (response) {
			if (response.result == -1 && response.data.code == -1200) {
				windowReference.close();
				alert(response.data.message);;
				return;
			}
			$scope.buy(szType, windowReference);
		});
	};



	var _bGift = false;
	/**
	 * 선물 보내기
	 */
	$scope.sendHandler = function () {
		if (_bGift) {
			return false;
		}

		$state.go("player");
		_bGift = true;
		switch ($scope.selectItem.type) {
			case 'starballoon':
				if ($scope.count <= 0) {
					toastr.warning(gtext('1개 이상 선물이 가능합니다.'));
					return;
				}
				if ($scope.item.starballoon < $scope.count) {
					toastr.warning(gtext('보유하신 별풍선이 부족합니다.'));
					return;
				}
				break;
			case 'sticker':
				if ($scope.item.gold < $scope.count) {
					toastr.warning(gtext('보유하신 골드가 부족합니다.'));
					return;
				}
				break;
			case 'chocolate':
				if ($scope.item.chocolate < $scope.count) {
					toastr.warning(gtext('보유하신 초콜릿이 부족합니다.'));
					return;
				}
				break;
		}
		GiftService.gift({
			type: $scope.selectItem.type,
			funding_idx: _broadInfo['funding_idx'],
			bj_id: _broadInfo['bj_id'],
			count: $scope.count,
			broad_no: _broadInfo['broad_no'],
			chat_id: chat_id,
			sticker_code: $scope.selectItem.code
		}).then(function (response) {
			_bGift = false;
			// 성공
		}, function (response) {
			_bGift = false;
			var message = response.message.replace(/\n/g, '<br>');
			toastr.error(gtext(message));
		}).catch(function (error) {
			_bGift = false;
			alert(gtext('선물하기 서버 문제로 이용하실 수 없습니다.'));
		});
	};



	/**
	 * $viewContentLoaded 가 발생하지 않아 $loginChange 이벤트가 없음
	 */
	if (LoginManager.isLogin()) {

		//아이템 갯수 가져오기 (별풍선,골드,초콜릿)
		ItemService.iteminfo().then(function (response) {
			console.log(response);
			$scope.item = {
				gold: response.data.gold,
				starballoon: response.data.starballoon,
				chocolate: response.data.chocolate
			};
			$scope.$digest();
		}).catch(function (response) {
			console.log(response);
			$scope.item = {
				gold: 0,
				starballoon: 0,
				chocolate: 0
			};
			$scope.$digest();
		});
	} else {
		if (confirm(gtext('로그인 하셔야 선물 하실 수 있습니다.'))) {
			$scope.login();
		} else {
			$state.go("player");
		}
	}



	$rootScope.$on('$locationChangeSuccess', function (evt, next, current) {
		/**
		 * 백버튼으로 메뉴 사라지게
		 */
		if (current.indexOf('#gift') > 0 && next.indexOf('#gift') < 0) {
			$state.go("player");
		}


	});


}

module.exports = LivePlayerGiftController;
