require('jquery');
var angular = require('angular');
var Kakao = require('kakao');
window.toastr = require("toastr");

angular.module('afreecatv')
	.factory('CookieFactory', require('./cookie.factory'))
	.factory('LoginManager', require('./login.manager.factory'))
	.factory('AfreecaStorageFactory', require('./afreeca.storage.factory'))
	.factory('HistoryState', require('./history.state.factory'))
	.factory('LaterviewFactory', require('./laterview.factory'))
	.factory('TokenFactory', require('./token.factory'))
	.factory('ChatFactory', require('./chat.factory'))
	.factory('ItemFactory', require('./item.factory'))
	.factory('ThumbnailListFactory', require('./thumbnail.list.factory'))
	.factory('Util', require('./util.factory'))
	.factory('gtext', require('./gtext.factory'))
	.factory('ToastMessage', require('./toast.factory'));