RankListController.$inject = ["$scope", "$rootScope", "$stateParams", "RankService", "Util", "$state", "CookieFactory", 'gettextCatalog'];

function RankListController($scope, $rootScope, $stateParams, RankService, Util, $state, CookieFactory, gettextCatalog) {
	$("body").removeClass("liveArea fullscreen");

	$scope.szRankType = $stateParams.rank || "home";
	$rootScope.title = "BJ 랭킹";
	$rootScope.type = "rank";	
	$scope.aGroups = {};
	$scope.szCategoryShow = "";
	$scope.szTipShow = "";
	$scope.bPageLoading = true;
	$scope.szLayerRank = "";
	$scope.szImageurl = "";
    $scope.szImageurlTablet = "";
	$scope.szCommuUrl = "";
    $scope.nicknameList= "";
    $scope.displayYn = "Y";

	var nCurrentPage = 1;

	$scope.aTabs = [
		{
			id: "home",
			name: "랭킹 홈",
		},
		{
			id: "rookie",
			name: "신인BJ",
		},
		{
			id: "viewer",
			name: "최고 유저 평균",
		},
		{
			id: "favoriteup",
			name: "애청자 증가수",
		},
		{
			id: "up",
			name: "UP 수",
		},
		{
			id: "game",
			name: "게임",
		},
		{
			id: "sports",
			name: "Thể thao",
		},
		{
			id: "social",
			name: "Social",
		},
		// {
		// 	id: "videos",
		// 	name: "VOD Streamer",
		// },
		{
			id: "favorite",
			name: "누적 애청자 수",
		},
		{
			id: "fanclub",
			name: "누적 팬클럽 수",
		},
	];

	//랭킹 종류
	angular.forEach($scope.aTabs, function(category, key) {
		if (category.id === $scope.szRankType) {
			$scope.szCategoryName = category.name;
		}
	});

	// TAB BTN 카테고리(RANK) SELECTION BOX 클릭시
	$scope.categorySelectBox = function() {
		$scope.szCategoryShow = $scope.szCategoryShow == "show" ? "" : "show";
		$scope.szTipShow = "";
	};
	// TAB BTN QUESTION (RANK) SELECTION BOX 클릭시
	$scope.tipSelectBox = function() {
		$scope.szTipShow = $scope.szTipShow == "show" ? "" : "show";
		$scope.szCategoryShow = "";
	};

	// TAB ITEM 클릭시 해당 카테고리의 리스트 가져오기
	$scope.categoryClick = function(aCategory) {
		if ($scope.szRankType === aCategory.id) {
			$scope.szCategoryShow = "";
			return;
		}

		$scope.bPageLoading = true;
		$scope.szCategoryName = aCategory.name;
		$scope.aGroups = {};
		$scope.szCategoryShow = "";
		nCurrentPage = 1;
		$state.go("rank.list", {
			rank: aCategory.id,
		});
	};

	// 랭킹 순위변동여부및 랭킹
	$scope.rankGap = function(aRank) {
		var nGap = aRank.total_rank_last - aRank.total_rank;
		var szRankStatus = "";
		var nRankGap = "";

		// 상승
		if (aRank.total_rank_last == 0) {
			szRankStatus = "new";
			nRankGap = "";
		} else if (nGap > 0) {
			szRankStatus = "up";
			nRankGap = nGap;
		}
		// 하락
		else if (nGap < 0) {
			szRankStatus = "down";
			nRankGap = Math.abs(nGap);
		}
		// 변동없음
		else {
			szRankStatus = "even";
			nRankGap = "";
		}
		return {
			szRankStatus: szRankStatus,
			nRankGap: nRankGap,
		};
	};

	//더보기
	Util.lastItem(function() {
		if ($scope.bPageLoading == false && $scope.bHasMore) {
			nCurrentPage++;
			getRanking();
		}
	});

	/**
	 * 랭킹 방송국 가기
	 * @param MouseEvent event
	 * @param {string} user_id
	 */
	$scope.goRankStation = function(event, user_id) {
		event.stopPropagation();
		afreeca.link.goStation(user_id, null);
	};

	/**
	 * 더보기
	 * @param string type
	 * @returns {undefined}
	 */
	$scope.goMoreRank = function(type) {
		$state.go("rank.list", {
			rank: type,
		});
	};

	/**
	 * scheme 실행
	 * @param string scheme
	 */
	$scope.action = function(scheme) {
		if ($rootScope.bIsWebView) {
			location.href = scheme;
		} else {
			$rootScope.action(scheme);
		}
	};

	//랭킹가져오기
	var getRanking = function() {
		$scope.bPageLoading = true;
		RankService.list({
			category_id: $scope.szRankType,
			current_page_no: nCurrentPage,
		})
			.then(function(response) {
				var data = response.data;
				if ($scope.szRankType !== "home") {
					angular.forEach(data.groups, function(aGroup) {
						var szKey = aGroup.title;
						// title이 키가되서 object에 담고   이미 담아져있으면 contents 만 담는다.
						$scope.aGroups[szKey] ? $.merge($scope.aGroups[szKey].contents, aGroup.contents) : ($scope.aGroups[szKey] = aGroup);
					});
				} else {
					$scope.aGroups = data.groups;
				}
				$scope.bHasMore = data.has_more_list;
				$scope.bPageLoading = false;
				$scope.$digest();
			})
			.catch(function(response) {
				$scope.bPageLoading = false;
				$scope.$digest();
			});
	};

    var getRankingPreview = function() {
        if ($scope.szRankType != 'preview') {
            return;
        }
        $scope.bPageLoading = true;
        RankService.list({
            category_id: 'home',
            current_page_no: nCurrentPage,
        })
            .then(function(response) {
                var data = response.data;
                if ($scope.szRankType !== "preview") {
                    angular.forEach(data.groups, function(aGroup) {
                        var szKey = aGroup.title;
                        // title이 키가되서 object에 담고   이미 담아져있으면 contents 만 담는다.
                        $scope.aGroups[szKey] ? $.merge($scope.aGroups[szKey].contents, aGroup.contents) : ($scope.aGroups[szKey] = aGroup);
                    });
                } else {
                    $scope.aGroups = data.groups;
                }
                $scope.bHasMore = data.has_more_list;
                $scope.bPageLoading = false;
                $scope.$digest();
            })
            .catch(function(response) {
                $scope.bPageLoading = false;
                $scope.$digest();
            });
    };

	/**
	 * 랭킹 소통센터 공지글 이동
	 * @param MouseEvent event
	 */
	$scope.goCommuCenter = function(event) {
		event.stopPropagation();
		// BJ랭킹 이달의 BJ 팝업 레이어 https로 변경
		var szRegUrl = new RegExp(M_AFREECATV + "/notice/a/view/no/[0-9+]*$");
		if(!szRegUrl.exec($scope.szCommuUrl)){
			$scope.szCommuUrl = M_AFREECATV + "/notice/a/list";
		}
		afreeca.link.goStationLink($scope.szCommuUrl, true);
	};

	//이달의랭킹BJ팝업가져오기
	var getMonthBjRanking = function() {
        var param = {
            'displayYn': $scope.displayYn
        };
		RankService.monthBjRanking(param)
			.then(function(response){
				if ( response.DATA != "" ) {
					var data = response.DATA;
					var timestamp = parseInt(new Date() / 10000);

                    // 데이터 세팅
                    setLayerPopup(data);

                    $scope.szImageurl = EVENT_IMG_AFREECATV + "/" + data.mobile_img_path + "?" + timestamp;
                    $scope.szImageurlTablet = EVENT_IMG_AFREECATV + "/" + data.tablet_img_path + "?" + timestamp;

					$scope.rankingLayer("show");
				} else {
					$scope.rankingLayer("hide");
				}

			})
			.catch(function(response) {
				$scope.rankingLayer("hide");
			});
	};

    var setLayerPopup = function (data) {
        // 변수 세팅
        var bList = JSON.parse(data.bj_id_list);
        var tList = JSON.parse(data.rank_title_list);
        $scope.nicknameList = data.nicknameData;

        $(".monthlyBJ_wrap").css("backgroundColor", data.bg_color);

        makeBjDataHtml('star_box', tList.starbj_1_title, bList.starbj_1, tList.starbj_1_memo, 1);
        makeBjDataHtml('star_box', tList.starbj_2_title, bList.starbj_2, tList.starbj_2_memo, 2);
        makeBjDataHtml('star_box', tList.starbj_3_title, bList.starbj_3, tList.starbj_3_memo, 3);
        makeBjDataHtml('star_box', tList.starbj_4_title, bList.starbj_4, tList.starbj_4_memo, 4);
        makeBjDataHtml('star_box', tList.starbj_5_title, bList.starbj_5, tList.starbj_5_memo, 5);
        makeBjDataHtml('star_box', tList.starbj_6_title, bList.starbj_6, tList.starbj_6_memo, 6);

        makeBestVodHtml('vod_box_1', tList.best_vod_title, data.vodData.best_video);
        makeBestVodHtml('vod_box_2', tList.rookie_vod_title, data.vodData.rookie_video);
        makeBestVodHtml('vod_box_3', tList.user_vod_title, data.vodData.user_clip);

        makeBjDataHtml('user_box', tList.staruser_1_title, bList.staruser_1, tList.staruser_1_memo, 1);
        makeBjDataHtml('user_box', tList.staruser_2_title, bList.staruser_2, tList.staruser_2_memo, 2);
        makeBjDataHtml('user_box', tList.staruser_3_title, bList.staruser_3, tList.staruser_3_memo, 3);

        makeBjDataHtml('fanclub_1', tList.fanclub_1_title, bList.fanclub_1, tList.fanclub_1_memo, 1);
        makeBjDataHtml('fanclub_2', tList.fanclub_2_title, bList.fanclub_2, tList.fanclub_2_memo, 2);
        makeBjDataHtml('gudok_1', tList.gudok_1_title, bList.gudok_1, tList.gudok_1_memo, 1);
        makeBjDataHtml('gudok_2', tList.gudok_2_title, bList.gudok_2, tList.gudok_2_memo, 2);
        makeBjDataHtml('supporter_1', tList.supporter_1_title, bList.supporter_1, tList.supporter_1_memo, 1);
        makeBjDataHtml('supporter_2', tList.supporter_2_title, bList.supporter_2, tList.supporter_2_memo, 2);

        makeBjDataHtml('bottom', tList.bottom_1_title, bList.bottom_1, tList.bottom_1_memo, 1);
        makeBjDataHtml('bottom', tList.bottom_2_title, bList.bottom_2, tList.bottom_2_memo, 2);
        makeBjDataHtml('bottom', tList.bottom_3_title, bList.bottom_3, tList.bottom_3_memo, 3);
        makeBjDataHtml('bottom', tList.bottom_4_title, bList.bottom_4, tList.bottom_4_memo, 4);
        makeBjDataHtml('bottom', tList.bottom_5_title, bList.bottom_5, tList.bottom_5_memo, 5);
        makeBjDataHtml('bottom', tList.bottom_6_title, bList.bottom_6, tList.bottom_6_memo, 6);
        makeBjDataHtml('bottom', tList.bottom_7_title, bList.bottom_7, tList.bottom_7_memo, 7);

    };

    // BJ 데이터 세팅
    var makeBjDataHtml = function (type, title, bjId, memo, nth) {
        var dom = '';
        var dom_img = '';
        var dom_title = '';
        var dom_nick = '';
        var dom_memo = '';
        var dom_link = '';
        var dom_bjId = '';

        switch (type) {
            case 'star_box':
                dom = '.' + type + ' li:nth-child('+nth+') ';
                dom_img = dom + '.rankProfile img';
                dom_title = dom + '.star_tit';
                dom_nick = dom + '.nick';
                dom_memo = dom + '.sub';
                dom_link = dom + '.rankProfile a';
                $(dom_img).attr("src", getBroadLogo(bjId));
                $(dom_title).text(title);
                $(dom_nick).html('<a href="'+getBjUrl(bjId)+'" target="_blank">'+getNickname(bjId)+' (<i>'+bjId+'</i>)</a>');
                $(dom_memo).text(memo);
                $(dom_link).attr("href", getBjUrl(bjId));
                $(dom_link).attr("target", "_blank");
                break;

            case 'user_box':
                dom = '.' + type + ' li:nth-child('+nth+') ';
                dom_title = dom + '.user_tit';
                dom_nick = dom + '.nick i:nth-child(1)';
                dom_bjId = dom + '.nick .bjId';
                dom_memo = dom + '.up';
                dom_link = dom + '.nick a';
                $(dom_title).text(title);
                $(dom_nick).text(getNickname(bjId));
                $(dom_bjId).text(bjId);
                $(dom_memo).text(memo);
                $(dom_link).attr("href", getBjUrl(bjId));
                $(dom_link).attr("target", "_blank");
                break;

            case 'fanclub_1':
            case 'fanclub_2':
                dom = '#fanclub .cont div:nth-child('+nth+') ';
                dom_img = dom + 'img';
                dom_title = dom + '.user_tit';
                dom_nick = dom + '.nick i:nth-child(1)';
                dom_bjId = dom + '.nick .bjId';
                dom_memo = dom + '.sub';
                dom_link = dom + 'a';
                $(dom_img).attr("src", getBroadLogo(bjId));
                $(dom_title).text(title);
                $(dom_nick).text(getNickname(bjId));
                $(dom_bjId).text(bjId);
                $(dom_memo).text(memo);
                $(dom_link).attr("href", getBjUrl(bjId));
                $(dom_link).attr("target", "_blank");
                break;

            case 'gudok_1':
            case 'gudok_2':
                dom = '#gudok .cont div:nth-child('+nth+') ';
                dom_img = dom + 'img';
                dom_title = dom + '.user_tit';
                dom_nick = dom + '.nick i:nth-child(1)';
                dom_bjId = dom + '.nick .bjId';
                dom_memo = dom + '.sub';
                dom_link = dom + 'a';
                $(dom_img).attr("src", getBroadLogo(bjId));
                $(dom_title).text(title);
                $(dom_nick).text(getNickname(bjId));
                $(dom_bjId).text(bjId);
                $(dom_memo).text(memo);
                $(dom_link).attr("href", getBjUrl(bjId));
                $(dom_link).attr("target", "_blank");
                break;

            case 'supporter_1':
            case 'supporter_2':
                dom = '#supporter .cont div:nth-child('+nth+') ';
                dom_img = dom + 'img';
                dom_title = dom + '.user_tit';
                dom_nick = dom + '.nick i:nth-child(1)';
                dom_bjId = dom + '.nick .bjId';
                dom_memo = dom + '.sub';
                dom_link = dom + 'a';
                $(dom_img).attr("src", getBroadLogo(bjId));
                $(dom_title).text(title);
                $(dom_nick).text(getNickname(bjId));
                $(dom_bjId).text(bjId);
                $(dom_memo).text(memo);
                $(dom_link).attr("href", getBjUrl(bjId));
                $(dom_link).attr("target", "_blank");
                break;

            case 'bottom':
                dom = '.single_box li:nth-child('+nth+') ';
                dom_img = dom + 'img';
                dom_title = dom + 'h3';
                dom_nick = dom + '.nick i:nth-child(1)';
                dom_bjId = dom + '.nick .bjId';
                dom_memo = dom + '.sub';
                dom_link = dom + 'a';
                $(dom_img).attr("src", getBroadLogo(bjId));
                $(dom_title).text(title);
                $(dom_nick).text(getNickname(bjId));
                $(dom_bjId).text(bjId);
                $(dom_memo).text(memo);
                $(dom_link).attr("href", getBjUrl(bjId));
                $(dom_link).attr("target", "_blank");
                break;
        }
    }

    var makeBestVodHtml = function (type, title, data) {
        var dom = '';
        var dom_thumb = '';
        var dom_thumb_link = '';
        var dom_img = '';
        var dom_vod_title = '';
        var dom_title = '';
        var dom_nick = '';
        var dom_link = '';
        var dom_plays = '';

        var vodUrl = VOD_AFREECATV + "/player/" + data.title_no;
        var read_cnt = new Intl.NumberFormat("ko-KR").format(data.read_cnt);

        // VOD 정보
        var vod_user_id = data.user_id;
        var vod_user_nick = data.user_nick;

        // 원작자 정보가 있을 경우
        if (data.ct_user_id) {
            vod_user_id = data.ct_user_id;
            vod_user_nick = data.ct_user_nick;
        }

        var bjUrl = getBjUrl(vod_user_id);

        dom = '#' + type + ' ';
        dom_title = dom + 'h3';
        dom_thumb = dom + '.cont img';
        dom_thumb_link = dom + '.cont a';
        dom_img = dom + '.profile img';
        dom_vod_title = dom + '.vod_tit';
        dom_nick = dom + '.nick i';
        dom_link = dom + '.detail a:nth-child(2)';
        dom_plays = dom + '.plays';

        $(dom_title).text(title);
        $(dom_thumb).attr("src", data.thumb);
        $(dom_thumb_link).attr("href", vodUrl);
        $(dom_thumb_link).attr("target", "_blank");
        $(dom_img).attr("src", getBroadLogo(vod_user_id));
        $(dom_vod_title).text(data.title_name);
        $(dom_nick).text(vod_user_nick);
        $(dom_link).attr("href", bjUrl);
        $(dom_link).attr("target", "_blank");
        $(dom_plays).text(read_cnt+'회');
    }

    // 방송국 url
    var getBjUrl = function (bjId) {
        return BJ_AFREECATV + '/' + bjId;
    }

    // 방송국 로고
    var getBroadLogo = function (bjId) {
        return PROFILE_IMG_AFREECATV+'/LOGO/'+bjId.substr(0,2)+'/'+bjId+'/'+bjId+'.jpg';
    }

    // 닉네임
    var getNickname = function (bjId) {
        return $scope.nicknameList[bjId];
    }

	$scope.rankingLayer = function(szLayerMode) {
		if ( szLayerMode == "show") {
			$scope.szLayerRank = $scope.szLayerRank == "show" ? "" : "show";
			$scope.szRankDim = $scope.szRankDim == "show" ? "" : "show";
		} else {
			$scope.szLayerRank = "";
			$scope.szRankDim = "";
		}
	}

	$scope.setRankLayerCookie = function (key, value, expireDay) {
		CookieFactory.set(key, value, expireDay);
		$scope.rankingLayer("hide");
	}

	var checkRankLayerCookie = function () {
		if (CookieFactory.get('rank_event') === 'hide') {
			$scope.rankingLayer("hide");
		} else {
			/**
			 * 매월 5일부터 3일동안 노출 => 2020-01-02 주말 관계없이 매달 5일 변경하도록 변경
			 * 2020-11-30 매월 5일부터 3일 노출 없이 어드민에서 지정한 날짜로 노출
			 */
			getMonthBjRanking();
		}
	}

	if ( ($scope.szRankType === "home")
        || $scope.szRankType === "preview"
    ) {
        if ($scope.szRankType == 'preview') {
            $scope.displayYn = 'N'
        }
		checkRankLayerCookie();
	}

	getRanking();
    getRankingPreview();

	//랭킹 홈 에서 더보기로 이동 후 뒤로 버튼으로 돌아왔을 경우 제대로 페이지 랜더링 되지 않는 이슈 있어 강제로 scrolltop 시킴 (ios safari)
	//$window.scrollTo(0 ,0) 으론 해결 안됨.
	$("html, body").scrollTop(0);
}
module.exports = RankListController;
