LoginManager.$inject = ['$rootScope', '$http', '$q', '$sessionStorage', 'CookieFactory'];

function LoginManager($rootScope, $http, $q, $sessionStorage, CookieFactory) {
	$rootScope.loginCheck = false;
	var bLoading = false;
	var loginStorage;

	/**
	 * 로그인 유저정보 가져오기
	 * @returns {loginManagerL#1.loginManagerL#1#L#4._getMyInfo.promise}
	 */
	function _getMyInfo() {

		var deferredAbort = $q.defer();
		var request = $http({
			method: 'POST',
			timeout: deferredAbort.promise,
			url: MEMBER_M_AFREECATV + '/api/member/a/info',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded'
			}
		});

		var promise = request.then(
			function (response) {
				if (response.data.result == 1) {
					return response.data.data;
				} else {
					return ($q.reject(response.data.data.message));
				}
			},
			function (response) {
				return ($q.reject("서버 오류가 발생하였습니다."));
			}
		);
		promise.abort = function () {
			deferredAbort.resolve();
		};
		return promise;
	}


	return {
		/**
		 * 최초 로그인정보셋팅  
		 * $loginChange 호출하지 않음
		 * @returns {undefined}
		 */
		init: function () {
			var that = this;
			_getMyInfo().then(function (data) {
				$rootScope.member = $rootScope.member = data;
				$rootScope.$broadcast('$loginInit', true);
				collector.setUserId(that.getUserId());
			}).catch(function (errorMessage) {
				$rootScope.$broadcast('$loginInit', false);
				collector.setUserId('')
			});
		},
		/**
		 * 로그인을 했을경우 
		 * @returns {undefined}
		 */
		check: function () {

			// 개인정보불러오기
			_getMyInfo().then(function (data) {
				$rootScope.member = data;
				$rootScope.$broadcast('$loginChange', true);
			}).catch(function (errorMessage) {
				$rootScope.$broadcast('$loginChange', false);
			});

		},
		isLogin: function () {
			if (CookieFactory.get('PdboxTicket')) {
				return true;
			} else {
				CookieFactory.remove('PdboxTicket', {
					domain: DOMAIN
				});
				$rootScope.member = null;
				return false;
			}
		},
		logout: function (callback) {
			var that = this;
			$rootScope.member = null;
			$http({
			    method: 'POST' ,
			    url: MEMBER_M_AFREECATV + '/api/member/a/logout',
			    headers: {
			        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
			    }
			}).then(
			function(response) {
				if (typeof callback === "function")
					callback();
			});
			// location.href = AUTH_M_AFREECATV + '/sns/vtvlive/logout?'+ encodeURIComponent(redirectUrl);
			// return;
		},
		/**
		 * 
		 * @returns {undefined}
		 */
		isRealName: function () {
			return $rootScope.member ? $rootScope.member.is_realnamecheck : null;
		},
        isChnnlRealName : function() {
            return $rootScope.member ? $rootScope.member.is_chnnlnamecheck : null;
        },
		getUserId: function () {
			return $rootScope.member ? $rootScope.member.user_id : null;
		},
		getUserNo: function () {
			return $rootScope.member ? $rootScope.member.user_no : 0;
		},
		getNickname: function () {
			return $rootScope.member ? $rootScope.member.nickname : null;
		},
		changeNickName: function (nickname) {
			$rootScope.member.nickname = nickname;
		},
		getNationalCode: function () {
			return $rootScope.member ? $rootScope.member.nationality_cd : null;
		},
		getPwd2ndCheck: function () {
			return $rootScope.member ? $rootScope.member.pwd2nd : null;
		},
		isAdultCheck : function () {			
			return $rootScope.member ? ( $rootScope.member.is_adultcheck === 1? true : null ) : null;
		},
		getAge : function () {
			return $rootScope.member ? $rootScope.member.age : null;
		}

	};

};

module.exports = LoginManager;