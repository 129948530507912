
RecentService.$inject = ['$rootScope', '$location', 'AfreecaStorageFactory', 'LiveService'];
function RecentService($rootScope, $location, AfreecaStorageFactory, LiveService) {

        return {
                removeAll: function () {
                        AfreecaStorageFactory.removeItem('aRecentUsers');
                },
                remove: function (szUserId) {
                        var aRecentUsers = JSON.parse(AfreecaStorageFactory.getItem('aRecentUsers'));
                        var nIndex = aRecentUsers.indexOf(szUserId);
                        aRecentUsers.splice(nIndex, 1);
                        AfreecaStorageFactory.setItem('aRecentUsers', JSON.stringify(aRecentUsers));

                },
                add: function (szUserId) {

                        var aRecentUsers = AfreecaStorageFactory.getItem('aRecentUsers') ? JSON.parse(AfreecaStorageFactory.getItem('aRecentUsers')) : [];
                        // 100개 이상일때 가정 먼저 추가한 user제거
                        if (aRecentUsers.length >= 100) {
                                aRecentUsers.splice(0, 1);
                        }

                        // 중복체크
                        if (aRecentUsers.indexOf(szUserId) < 0) {
                                aRecentUsers.push(szUserId);
                                AfreecaStorageFactory.setItem('aRecentUsers', JSON.stringify(aRecentUsers));
                        }
                },
                getAll: function (data) {
                        
                        return new Promise(function (resolve, reject) {

                                var szRecentUsers = AfreecaStorageFactory.getItem('aRecentUsers');
                                aRecentUsers =  JSON.parse(szRecentUsers).join(',');

                                if (!aRecentUsers) {
                                        resolve([]);
                                }

                                var newData = angular.merge({}, {
                                        user_id: aRecentUsers,
                                        page_type: 'recent'
                                }, data);
                                
                                LiveService.list(newData).then(function (response) {
                                        resolve(response);
                                }).catch(function (error) {
                                        reject(error);
                                });

                        });

                }
        }
};

module.exports = RecentService;