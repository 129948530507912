function WebChat() {

	var socket = null;
	var bConnect = false;
	var nServerIndex = -1;
	var callack = null;
	var openCallback = null;
	var bNormalClose = false; //socket.close로 정상종료
	var abnormalCloseCallback = null;
	var aServerList = [];
    var broadInfo = null;

	/*
	 * 채팅소켓 URL 생성
	 * @returns {unresolved}
	 */
	var getSocketUrl = function (list) {
		var szServer;
		if (nServerIndex > 0) {
			//값이 있으면 순서대로 다음꺼
			nServerIndex++;
			if (nServerIndex >= list.length)
				nServerIndex = 0;
			szServer = list[nServerIndex];
		} else {
			//랜덤으로 포트가져오기
			nServerIndex = Math.floor((Math.random() * list.length));
			szServer = list[nServerIndex];
		}
		szServer = list[nServerIndex];
		//return 'ws://118.217.182.248:3576/Websocket';   //개발서버
        
        if(location.protocol == 'https:') {
            return 'ws://mobbridge.onlive.vn:443/Websocket/' + broadInfo.bj_id;
            //return 'wss://' + szServer.replace(/:[0-9]+/gi, ':443') + '/Websocket/' + broadInfo.bj_id;
        } else {
            return 'ws://' + szServer + '/Websocket/' + broadInfo.bj_id;
        }
	}
	this.init = function (value) {
		aServerList = value;
	}
	this.getServer = function () {
		return getSocketUrl(aServerList);
	}
    this.setBroadInfo = function(_broadInfo) {
        broadInfo = _broadInfo;
    }

	/**
	 * 채팅서버 연결
	 */
	this.connect = function (serverList) {
		if (window.WebSocket) {
			socket = new WebSocket(this.getServer(), "chat");
			socket.binaryType = 'arraybuffer';
			socket.onmessage = function (e) {
				var msg = '';
				if (typeof e.data === 'string') {
					msg = e.data;
				} else {
					var data = new Uint8Array(e.data);
					msg = new TextDecoder("utf-8").decode(data);
				}
				if (callback)
					callback(msg);
			};
			socket.onopen = function (e) {
				bConnect = true;
				openCallback(1);

			};

			socket.onclose = function (e) {
				if (bNormalClose == false) {
					//비정상 종료
					abnormalCloseCallback();
				}
				bConnect = false;
			};
			socket.onerror = function (e) {
				bConnect = false;
			};

			if (socket.binaryType == undefined) //"blob" or  "arraybuffer"
			{
				//BROWSER NOT SUPPORTED
				if (openCallback)
					openCallback(-1);
			}
		} else {
			//BROWSER NOT SUPPORTED
			if (openCallback)
				openCallback(-1);
		}
	}

	this.abnormalCloseHandler = function (fn) {
		abnormalCloseCallback = fn
	}

	/*
	 * 채팅 종료
	 */
	this.disconnect = function () {
		bNormalClose = true;
		if (socket) {
			socket.close()
			socket = null;
		}
	}
	/*
	 * 메시지 전송
	 * @param {String} packet
	 */
	this.send = function (packet) {
		// if (packet.substr(0, 5) == 'CHAT1') {
		// 	socket.close()
		// }
		try {
			socket.send(packet);
		} catch (error) {
			if (error.code == 11) {
				// possibly still 'CONNECTING'
				if (socket.readyState !== 1) {
					// setTimeout(webChat.send(packet + "^"), 100);
				}
			}
		}
	}
	/*
	 * 채팅소켓을 사용할수 있을때 알림 핸들러
	 * @param {function} fn
	 */
	this.openHandler = function (fn) {
		openCallback = fn
	}
	/*
	 * 채팅 패킷 이벤트 핸들러
	 * @param {function} fn
	 */
	this.messageHandler = function (fn) {
		callback = fn
	}
}
module.exports = WebChat;