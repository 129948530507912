


Run.$inject = ['$window', 'gettextCatalog'];
function Run($window, gettextCatalog) {

        function getPreferredLocale() {
                var supportLanguage = ['vi_VN'];
                var lang = $window.navigator.language || $window.navigator.userLanguage;
                var lang = lang.replace('-', '_');
                var aLang = lang.split('_');
                var nationCode = aLang[1] ? aLang[1].toUpperCase() : null;

                if (nationCode == 'HANS') {
                        nationCode = 'CN';
                } else if (nationCode == 'HANT' || nationCode == 'HK') {
                        nationCode = 'TW';
                }

                //4자 매칭
                var code = aLang[0] + (nationCode ? ('_' + nationCode) : '');
                if ($.inArray(code, supportLanguage) > 0) {
                        return code;
                }

                //앞 2자 매칭(언어매칭)
                for (var i = 0; i < supportLanguage.length; i++) {
                        var patt = new RegExp(aLang[0]);
                        if (patt.test(supportLanguage[i])) {
                                return supportLanguage[i];
                        }
                }

                return supportLanguage[0];

        }
        //언어셋팅
        gettextCatalog.currentLanguage = getPreferredLocale();
        console.log(gettextCatalog.currentLanguage)

        require.ensure([], function () {
                gettextCatalog.setStrings('vi_VN', require('./vi_VN'));
        }, 'vi_VN');
};

module.exports = Run;