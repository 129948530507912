var md5 = require('md5');
PlayerFactory.$inject = ['Util', '$rootScope', '$location', 'LoginManager', 'PlayerService', 'ItemService', 'MultiviewFactory', 'ThumbnailListFactory', 'EmoticonService','CookieFactory'];
function PlayerFactory(Util, $rootScope, $location, LoginManager, PlayerService, ItemService, MultiviewFactory, ThumbnailListFactory, EmoticonService, CookieFactory) {

        var szM3U8Url;
        var broadSuccessCallback, broadErrorCallback, startCallback, hasQuickviewCallback, stateCallback, errorCallback

        // handle multiple browsers for requestAnimationFrame()
        window.requestAFrame = (function () {
                return window.requestAnimationFrame ||
                        window.webkitRequestAnimationFrame ||
                        window.mozRequestAnimationFrame ||
                        window.oRequestAnimationFrame ||
                        // if all else fails, use setTimeout
                        function (callback) {
                                return window.setTimeout(callback, 1000 / 24); // shoot for 60 fps
                        };
        })();

        // handle multiple browsers for cancelAnimationFrame()
        window.cancelAFrame = (function () {
                return window.cancelAnimationFrame ||
                        window.webkitCancelAnimationFrame ||
                        window.mozCancelAnimationFrame ||
                        window.oCancelAnimationFrame ||
                        function (id) {
                                window.clearTimeout(id);
                        };
        })();


        var player = this;
        var video = null;
        var _broadInfo = {};
        var _originalBroadInfo = {};
        var bj_id;
        var broad_no;
        var _relay = false;
        var user_no = false;
        var _bLogin = false;
        var bError = false;
        var _multiViewFactoryInfo;
        var _videoWidth;
        var _videoHeight;
        var _bCreatedView = false;
        var _startBufferTime = 0;
        var _lastBufferTime = 0;
        var _joinTime = 0;
        
        /**
         * 방송정보
         * @param {boolean} relay
         * @returns {undefined}
         */
        var getBroadInfo = function () {

                PlayerService.getBroadInfo({
                        bj_id: bj_id
                        , broad_no: broad_no
                        , agent: 'web'
                }).then(function (response) {
                        if (response.result > 0) {
                                _originalBroadInfo = _broadInfo = response.data;
                                
                                if (_broadInfo.broad_type == 40) {
                                        alert(gtext('모바일 웹에서는 참여하실 수 없습니다.\nPC나 모바일앱(IOS,AOS)에서 참여해주세요.'));
                                        $state.go('home');
                                        return false;
                                }

                                /**
                                 * 본방만 있으면 무조건 본방에만
                                 */
                                if (_broadInfo.relay_count == 0) {
                                        //HLS서버 호출
                                        broadSuccessCallback(_broadInfo);
                                        getBroadStreamResource();
                                        return;
                                }

                                /**
                                 * 로그인 되어 있으면 퀵뷰 체크
                                 */
                                if (LoginManager.isLogin()) {
                                        //퀵뷰체크
                                        loadQuickview();
                                        return;
                                } else {
                                        //중계방으로 이동
                                        getRelayBroadInfo();
                                }
                        } else {
                                broadErrorCallback(response);
                        }
                }).catch(function (error) {
                        broadErrorCallback({ result: -1, data: { code: -1 , message: error.message} });
                });
        }

        /**
         * 중계방 정보 불러오기
         * @returns {undefined}
         */
        var getRelayBroadInfo = function () {
                _relay = true;
                PlayerService.getRelayBroadInfo({
                        bj_id: bj_id
                        , broad_no: _originalBroadInfo.broad_no
                }).then(function (response, status, headers, config) {
                        if (response.result > 0) {
                                _broadInfo = response.data;
                                //HLS서버 호출
                                broadSuccessCallback(_broadInfo);
                                getBroadStreamResource();
                        } else {
                                broadErrorCallback(response);
                        }
                }).catch(function (response, status, headers, config) {
                        broadErrorCallback({ result: -1, data: { code: -1 } });
                });
        }

        /**
         * 방송정보
         * @param {boolean} relay
         * @returns {undefined}
         */
        var getSignatureEmoticons = function () {

            EmoticonService.getSignatureEmoticons({
                work : 'list',
                szBjId: bj_id
            }).then(function (response) {
                    if (response.result > 0) {
                        EmoticonService.setSignatureEmoticons(response);
                    }
            }).catch(function (error) {
                    //do nothing
            });
        };

        /**
         * 퀵뷰정보 불러오기
         */
        var loadQuickview = function () {


                ItemService.quickview({
                        uno: LoginManager.getUserNo(),
                        hvalue: md5(LoginManager.getUserNo() + "AfreecaMobile")
                }).then(function (response) {
                        if (response.STATUS == 1) {
                                //퀵뷰 사용중
                                if (parseInt(response.ITEM.Result) > 0) {
                                        //HLS서버 호출
                                        broadSuccessCallback(_originalBroadInfo);   //본방정보 알림
                                        getBroadStreamResource();
                                        hasQuickviewCallback(true);
                                } else {
                                        //중계방으로 연결
                                        getRelayBroadInfo();           //중계방 정보 불러오기
                                        hasQuickviewCallback(false);
                                }
                        } else {
                                getRelayBroadInfo();           //중계방 정보 불러오기
                                hasQuickviewCallback(false);

                        }
                }).catch(function (error) {
                        getRelayBroadInfo();           //중계방 정보 불러오기
                        hasQuickviewCallback(false);
                });




        }


        /**
         * HLS 방송정보를 가져옴
         * @returns {undefined}
         */
        var _origianl_broad_no = 0;
        var getBroadStreamResource = function () {
                //영상은 정보가 바뀌어도  갱신하지 않음
                if (_origianl_broad_no === _broadInfo.origianl_broad_no) {
                        return false;
                }
                PlayerService.getBroadStreamResource(_broadInfo.resource_manager_url, {
                        broad_no: (_broadInfo.origianl_broad_no + '-mobileweb-hd-hls'),
                        use_cors: 'true',
                        cors_origin_url: location.host,
                        return_type: (/gs_cdn/i.test(_broadInfo.cdn)) ? 'gs_cdn_mobile_web' : _broadInfo.cdn
                }).then(function (response) {
                        szM3U8Url = response.view_url;
                        setVideoMeta();
                }).catch(function (error) {
                        sendLog('C', 8, error.status);                        
                        console.log(error);
                });



        }
        var setVideoMeta = function () {

                szM3U8Url = szM3U8Url.replace('/playlist.m3u8', '/auth_playlist.m3u8');
                szM3U8Url = szM3U8Url + '?aid=' + _broadInfo.hls_authentication_key

                video = $('#live_video');
                //M3U8
                video.attr('src', szM3U8Url);
                //표지화면
                video.attr('poster', _broadInfo.thumbnail.replace(".gif", "_480x270.gif"));

                video.on('error play pause loadeddata playing waiting canplay ended', function (e) {
                        switch (e.type) {
                                case 'error':
                                        errorCallback(e.target.error);
                                        break;
                                case 'loadedmetadata':
                                        _videoWidth = e.target.videoWidth;
                                        _videoHeight = e.target.videoHeight;
                                        break;
                        }
                        if (stateCallback)
                                stateCallback(e);
                });

                video.on('timeupdate', function (e) {
                        if (e.target.videoWidth && e.target.videoHeight) {
                                _videoWidth = e.target.videoWidth;
                                _videoHeight = e.target.videoHeight;
                                console.log(e.target.videoWidth, e.target.videoHeight);
                                video.off('timeupdate');
                        }
                });
        }
        
        var sendLog = function(mainType, subType, codeNo) {             
                var acptLang = Util.getPreferredLocale();
                var logdata = {};
                    
                logdata.m_type = mainType;    //이상 현상의 타입 (A:접속불가, B:버퍼링, C:끊김, D:이벤트로그)
                logdata.s_type = subType;    //이상 현상의 서브 타입
                logdata.c_num = (codeNo!==undefined && codeNo!==null) ? codeNo : '';		//서브 타입의 Code Number
                logdata.bj = bj_id;   //해당 증상 발생시 시청하고 있는 BJ 명     
                
                if(_broadInfo !== null) {
                    logdata.category_no = _broadInfo.broad_cate_no; //방송 카테고리 번호
                    logdata.broad_no = _broadInfo.broad_no;  //해당 증상 발생시 시청하고 있던 방송 번호                    
                    logdata.parent_broad_no  = _broadInfo.origianl_broad_no;    //해당 증상 발생시 시청하고 있던 부모방송 번호

                    logdata.bps = 1000;					//현재 영상을 수신받고 있는 bps
                    logdata.trans = 'H';    //현재 영상을 수신 받고 있는 형태                    
                    logdata.quality = 1;			//현재 영상을 수신 받고 있는 형태 (0:일반, 1:HD, 2:원본, 3:저화질)
                    logdata.resolution = null;	//해상도
                    logdata.dev_type = _broadInfo.broad_type;				//방송설정
                    logdata.set_bps = (_broadInfo.broad_bps) ? _broadInfo.broad_bps : null;           
                    logdata.cdn_src = (_broadInfo.cdn == 'aws_cf') ? 'A' : 'G';

                    if(_broadInfo.join_cc !== '' && _broadInfo.join_cc !== '0') {
                        logdata.join_cc = _broadInfo.join_cc;
                    }
                    if(_broadInfo.country_code !== '' && _broadInfo.country_code !== '0') {
                        logdata.geo_cc = _broadInfo.country_code;
                    }
                    if(_broadInfo.region_code !== '' && _broadInfo.region_code !== '0') {
                        logdata.geo_rc = _broadInfo.region_code;
                    }
                    if(acptLang !== '' && acptLang !== '0') {
                        logdata.acpt_lang = acptLang;                               
                    }
                    if(_broadInfo.lang !== '' && _broadInfo.lang !== '0') {
                        logdata.svc_lang = _broadInfo.lang;
                    }

                    if(mainType === 'B') {
                        logdata.abuse = 0;
                        logdata.join_api = 0;
                        logdata.api_center = 0;
                        logdata.center_seed = 0;
                        logdata.ad_sdk = 0;
                        logdata.ad_view = 0;
                        logdata.seed_play = 0;
                        logdata.seed_change_cnt = 0;
                        logdata.broad_info_type = 'normal';
                        logdata.total = 0;

                        if(subType === 1) {
                            if(_broadInfo.is_adult == true && _broadInfo.is_password_check == true) {
                                logdata.broad_info_type = 'adultpass';
                            }
                            else if(_broadInfo.is_adult == true) {
                                logdata.broad_info_type = 'adult';
                            }
                            else if(_broadInfo.is_password_check == true) {
                                logdata.broad_info_type = 'pass';
                            }
                            
                            logdata.total = _lastBufferTime - _joinTime;               
                            
                        } else {
                            logdata.total = _lastBufferTime - _startBufferTime;
                            
                            //중간 버퍼링일땐 센터 ip/port 전달
                            logdata.center_ip = _broadInfo.relay_ip;
                            logdata.center_port = _broadInfo.relay_port;
                        }

                        if(logdata.total <= 100 || logdata.total > 60000) {
                            logdata.abuse = 1;
                            return;
                        }
                    }
                }
                    
                //로그 전송
                collector.cstatus(logdata);
        }
        
        
        return {

                init: function (id, no, userno) {
                        bj_id = id;
                        broad_no = no;
                        _relay = false;
                        _bLogin = (userno === 0) ? false : true;
                        user_no = userno;
                        _joinTime = new Date().getTime();
                        getBroadInfo();
                        getSignatureEmoticons();
                },
                play: function () {
                        if (video && video[0]) {
                                //오류가 있을경우 플레이를 시키지 않음
                                if (video[0].error) {
                                        errorCallback(video[0].error);
                                        return;
                                }
                                video[0].play();
                        }
                },
                stop: function () {
                        if (video) {
                                video.off('error play pause loadeddata playing waiting canplay ended timeupdate');
                                video.attr('src', '');
                        }
                        _origianl_broad_no = 0;
                        _broadInfo = _originalBroadInfo = null;
                        _startBufferTime = 0;
                        _lastBufferTime = 0;
                },
                refresh: function () {
                        getBroadStreamResource();

                },
                reconnect: function (userno) {
                        _bLogin = (userno === 0) ? false : true;
                        user_no = userno;
                        //중계방일겨우
                        if (_relay) {                                    
                                loadQuickview();
                        } else {
                                getBroadInfo();
                                //broadSuccessCallback(_broadInfo);
                        }
                },

                broadSuccessHandler: function (fn) {
                        broadSuccessCallback = fn;
                },

                broadErrorHandler: function (fn) {
                        broadErrorCallback = fn;
                },
                startHandler: function (fn) {
                        startCallback = fn;
                },
                stateHandler: function (fn) {
                        stateCallback = fn;
                },
                hasQuickviewHandler: function (fn) {
                        hasQuickviewCallback = fn;
                },
                errorHandler: function (fn) {
                        errorCallback = fn;
                },


                /**
                 * 퀵뷰정보 불러오기
                 */
                checkQuickview: function (callback) {
                        $http({
                                method: 'post',
                                url: BILLING_AFREECATV + '/billing-api/mobile/my-items',
                                data: $.param({ uno: user_no }),
                                headers: { 
                                        'Content-Type': 'application/x-www-form-urlencoded',
                                        'Authorization' : 'Bearer ' + CookieFactory.get('access_token')
                                }
                        }).success(function (response, status, headers, config) {
                                if (response.STATUS == 1) {
                                        //퀵뷰 사용중
                                        if (response.COUPON.Result > 0) {
                                                if (callback)
                                                        callback(true);
                                        } else {
                                                if (callback)
                                                        callback(false);
                                        }
                                } else {
                                        if (callback)
                                                callback(false);

                                }
                        }).error(function (data, status, headers, config) {
                                if (callback)
                                        callback(false);
                        });


                },
                syncCheckQuickview: function (callback) {
                        var bQuickview = false;
                        ItemService.syncCheckQuickview({
                                uno: user_no,
                                hvalue: md5(user_no + "AfreecaMobile")
                        }).then(function (response) {
                                if (response.STATUS == 1 && response.COUPON.Result > 0) {
                                        bQuickview = true;
                                } else {
                                        bQuickview = false;
                                }
                        }).catch(function (error) {
                                bQuickview = false;
                        })
                        return bQuickview;
                },
                videoPlay: function () {
                        //영상이 일시정지일때  플레이로 바꿈
                        if (video && video[0] && video[0].paused == true) {
                                video[0].play();
                        }
                },
                videoPause: function () {
                        //플레이 중일때만
                        if (video && video[0] && video[0].readyState == 4) {
                                video[0].pause();
                        }
                },

                /**
                 * 멀티 방송정보 셋팅
                 * @param {type} info
                 * @returns {undefined}
                 */
                setMultiviewInfo: function (info) {

                        if (info.points.length > 0) {
                                //화면정보 저장
                                _multiViewFactoryInfo = info;
                                MultiviewFactory.setInfo(_multiViewFactoryInfo, _videoWidth, _videoHeight);
                                MultiviewFactory.setChannel(0);
                                MultiviewFactory.reload();
                                ThumbnailListFactory.setInfo(_multiViewFactoryInfo, _videoWidth, _videoHeight);
                                ThumbnailListFactory.setChannel(0);
                                ThumbnailListFactory.reload();
                        } else {
                                //숨기기;
                                ThumbnailListFactory.hide();
                                MultiviewFactory.setChannel(0);
                                ThumbnailListFactory.setChannel(0);
                        }
                },
                /**
                 * 멀티뷰 생성하기
                 */
                createMultiView: function () {
                        var createDeferred = $q.defer();

                        //만들어져있으면
                        if (_bCreatedView) {
                                setTimeout(function () {
                                        createDeferred.resolve();
                                }, 10);
                                return createDeferred.promise;
                        }

                        //_bCreatedView = true;
                        var video = $('#live_video');

                        //멀티화면 만들기
                        MultiviewFactory.setInfo(_multiViewFactoryInfo, _videoWidth, _videoHeight);
                        MultiviewFactory.create($('#live_canvas'), video);

                        //썸네일리스트 만들기
                        if (!ThumbnailListFactory) {
                                ThumbnailListFactory = new ThumbnailList();
                        }
                        ThumbnailListFactory.setInfo(_multiViewFactoryInfo, _videoWidth, _videoHeight);
                        ThumbnailListFactory.create($('.mlist'), video);

                        createDeferred.resolve();

                        //썸네일 클릭이벤트
                        $('.mlist').on('click_event', function (event, index) {
                                MultiviewFactory.setChannel(index);
                                ThumbnailListFactory.setChannel(index);
                        });

                        return createDeferred.promise;

                },
                /**
                 * 멀티방송 리스트 보기
                 * @param {type} show
                 * @returns {undefined}
                 */
                showMultiView: function () {
                        createMultiView().then(function () {
                                ThumbnailListFactory.show();
                        })

                },

                hideMultiView: function () {
                        if (ThumbnailListFactory)
                                ThumbnailListFactory.hide();
                },

                revmoeMultiView: function () {
                        if (MultiviewFactory) {
                                MultiviewFactory.remove();
                        }
                        if (ThumbnailListFactory) {
                                ThumbnailListFactory.remove();
                        }
                },
                
                setStartBufferTime: function(time) {
                    _startBufferTime = time;
                }, 
                
                setLastBufferTime: function(time) {
                    _lastBufferTime = time;
                },
                
                log: function (mainType, subType, codeNo) {
                    sendLog(mainType, subType, codeNo);                    
                },
                
                getUserNo: function() {
                    return user_no;
                }
        }
}

module.exports = PlayerFactory;