

CollectorService.$inject = ['$http'];
function CollectorService($http) {
        const LOG_NAME = {
                VOD: 'VOD'
                , VOUT: 'VOUT'
                , LATERVIEW: 'LATERVIEW'
                , FAV : 'FAV'
        };
        const HTTP_COLECTOR_ENV_URL_DEV = '//tcollector1.onlive.vn:8080/CONFIG/';
        const HTTP_COLECTOR_LOG_URL_DEV = '//sdev1.onlive.vn:8080/gather?sv=AF';
        const HTTP_COLECTOR_ENV_URL = '//collector1.onlive.vn/CONFIG/';
        const HTTP_COLECTOR_LOG_URL = '//collector1.onlive.vn/gather';
        const SERVICE_NAME = 'AF';
        const VERSION = '1.1';
        const CONNECT_SYSTEM = 'webm';
        const VALUE_DELIMITER = String.fromCharCode(6) + '=' + String.fromCharCode(6);
        const KEY_DELIMITER = String.fromCharCode(6) + '&' + String.fromCharCode(6);

        var log = {};
        var reloadTime = 0;
        var uid = '';
        var canLog = false;

        /**
         * 로그 검증용 hash 값
         * @param {type} param
         * @returns {unresolved}
         */
        function createHash(param) {
                var values = Object.keys(param).map(function (key) {
                        return param[key];
                });
                values.push('afreeca');
                var hashValue = values.join('|');
                return md5(hashValue);
        }

        function getUUIDHash(uuid, persent) {
                if (!uuid || uuid === null) {
                        return 100;
                }

                var hash = md5(uuid);
                var len = hash.length;
                var i, j = 0;
                for (i = 0; i < len; i++) {
                        j = hash[i].charCodeAt(0);
                }

                return j % persent;
        }


        function getOs() {
                if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
                        return 'ios';
                } else if (navigator.userAgent.match(/Android/i)) {
                        return 'android';
                } else {
                        return 'etc';
                }
        }

        /**
         * 쿠키읽기
         * @param {string} name
         * @returns {match|Array}
         */
        function getCookie(name) {
                match = document.cookie.match(new RegExp(name + '=([^;]+)'));
                return match ? match[1] : null;
        }
        /**
         * 쿠키쓰기
         * @param {string} name
         * @param {string} value
         * @param {number} exdays
         * @returns {undefined}
         */
        function setCookie(name, value, exdays) {
                var exdate = new Date();
                exdate.setDate(exdate.getDate() + exdays);
                var c_value = escape(value) + ((exdays == null) ? "" : "; expires=" + exdate.toUTCString());
                document.cookie = name + "=" + c_value;
        }

        /**
         * 32bit uuid문자열
         * @returns {Array}
         */
        function generateUUID() {
                return '0x' + 'xxxxxxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
                        return v.toString(16);
                });
        }

        this.init = function () {
                $.ajax({
                        method: "GET",
                        dataType: 'json',
                        url: HTTP_COLECTOR_ENV_URL
                })
                        .success(function (response) {
                                canLog = true;
                                reloadTime = response.config_reload;
                                log = response.log;
                        });
                return this;
        };
        this.setUserId = function (val) {
                uid = val;
                return this;
        };
        this.getDelimiter = function () {
                return KEY_DELIMITER;
        };
        this.buildQuery = function (data) {
                var newData = {};
                //값이 없는거 제거
                Object.keys(data).map(function (key) {
                        if (data[key]) {
                                newData[key] = data[key];
                        }
                });

                return Object.keys(newData).map(function (key) {
                        return key + VALUE_DELIMITER + data[key];
                }).join(KEY_DELIMITER);
        };
        this.uuid = function () {
                var uuid = getCookie('_au');
                if (uuid) {
                        return uuid;
                } else {
                        uuid = generateUUID();
                        setCookie('_au', uuid, 10);
                        return uuid;
                }
        };
        this.sendLog = function (logName, data) {
                if (!canLog) {
                        return;
                }

                var logConfig = log[logName];
                if (!logConfig) {
                        return;
                        logConfig = {
                                able: true,
                                send_percent: 100,
                                rand_send: 0
                        }
                }

                if (!logConfig.able || logConfig.send_percent == 0) {
                        return;
                }

                if (logConfig.send_percent < 100
                        && getUUIDHash(this.uuid(), logConfig.send_percent) >= logConfig.send_percent) {
                        return;
                }

                var aParam = {
                        sv: SERVICE_NAME,
                        ns: logName,
                        ver: VERSION,
                        tm: Math.round(new Date().getTime() / 1000),
                        ht: getOs(),
                        cs: CONNECT_SYSTEM,
                        uid: uid,
                        a: this.buildQuery(data)
                };
                aParam.hash = createHash(aParam);


                //로그 보내는 시간 (default 0)
                //0 이면 발생즉시 보내며
                //다른값이면 sleep( rand( 0~해당값 ) ) 후 전송

                /*
                setTimeout(function () {
                        $.ajax({
                                type: 'POST'
                                , url: HTTP_COLECTOR_LOG_URL
                                , data: aParam
                                , xhrFields: {
                                        withCredentials: false
                                }
                                , async: false
                                , dataType: 'json'
                                , success=function (response) {
                                }
                                , error=function (xhr, ajaxOptions, thrownError) {
                                }
                        });
                }, logConfig.rand_send * 1000);
                */
        };
        /**
         * VOD 로깅
         * @param {type} data
         * @returns {undefined}
         */
        this.vod = function (data) {
                this.sendLog(LOG_NAME.VOD, data);
        };

        /**
         * 나중에보기 로깅
         * @param object data
         * @returns collector
         */
        this.laterView = function (data) {
                var params = {
                        os: (getOs() === 'ios') ? 'ios' : 'android'
                        , uuid: getCookie('_au')
                        , ad_uuid: getCookie('OAX')
                };

                var data = $.extend(params, data);

                //null, undefined 제거
                $.each(data, function (key, value) {
                        if (value === null || value === undefined) delete data[key];
                });

                this.sendLog(LOG_NAME.LATERVIEW, data);
                return this;
        };
        /**
         * VOUT 로깅
         * @param {object} data
         * @returns {undefined}
         */
        this.vout = function (data) {
                this.sendLog(LOG_NAME.VOUT, data);
        }
        /**
         * 즐겨찾기 로깅
         * @param {object} data
         * @returns {undefined}
         */
        this.fav = function(data) { 
            this.sendLog(LOG_NAME.FAV, data);
        }
}


module.exports = CollectorService;