var toastr = require("toastr");
VideoController.$inject = [
	"$scope",
	"$timeout",
	"$rootScope",
	"Util",
	"VideoListService",
	"RecentVodService",
	"LoginManager",
	"LaterviewFactory",
	"$http",
	"gettextCatalog"
];

function VideoController(
	$scope,
	$timeout,
	$rootScope,
	Util,
	VideoListService,
	RecentVodService,
	LoginManager,
	LaterviewFactory,
	$http,
	gettextCatalog
) {
	$("body").removeClass("liveArea fullscreen");

	$("body").removeClass("liveArea fullscreen");

	// Default 정의
	var aIndex = [];
	$scope.storage = {};
	$rootScope.title = "VOD";
	$scope.aGroups = [];
	$scope.bShowEmptyBox = false;
	$scope.bHasMore = true; // 전체 카테고리의
	$scope.szOrder = "lastest"; // lastest : 최신순, view_cnt : 시청인원순
	$scope.bShowLoginView = false; //나중에보기 로그인 페이지
	$scope.bIsEmptyLaterView = false; //나중에보기 리스트 없음
	$scope.bPeriod = $scope.bCategory = $scope.bOrder = false;

	// 동영상 섹션, 스페셜, 카테고리 기본값 셋팅
	$scope.oVideoSection = $scope.oVideoSpecial = $scope.oVideoCategory = {
		bCompleted: true,
		nCurrentPageNo: 1,
		bHasMore: false,
	};

	// 동영상 최상위 탭 (핫이슈, top30, 나중에보기, 전체)
	$scope.aTopMemnu = [
		{
			id: "home",
			name: "홈",
		},
		{
			id: "hotissue",
			name: "핫이슈",
		},
		{
			id: "favorite",
			name: "즐겨찾기",
		},
		{
			id: "later",
			name: "나중에보기",
		},
		{
			id: "all",
			name: "전체",
		},
	];

	// 기간
	var aPeriod = [
		{
			lately: "",
			name: "전체",
		},
		{
			lately: "1day",
			name: "1일",
		},
		{
			lately: "1week",
			name: "1주일",
		},
		{
			lately: "1month",
			name: "1개월",
		},
	];

	// 정렬
	$scope.aOrderByColumns = [
		{
			id: "view_cnt",
			name: "재생순",
			period: aPeriod,
		},
		{
			id: "recomm_cnt",
			name: "UP순",
			period: aPeriod,
		},
		{
			id: "memo_cnt",
			name: "댓글순",
			period: aPeriod,
		},
		{
			id: "reg_datetime",
			name: "최신순",
			period: aPeriod,
		},
	];

	// default
	$scope.storage.videoOrder = $scope.aOrderByColumns[0];
	$scope.storage.videoOrder.selectPeriod = $scope.aOrderByColumns[0]["period"][2];

	// Top메뉴 선택
	$scope.oSelectedTopMenu = $scope.aTopMemnu[0];

	// Top메뉴 클릭 이벤트
	$scope.clickTopMenu = function(oItem) {
		$scope.bPeriod = $scope.bCategory = $scope.bOrder = false;
		$scope.oSelectedTopMenu = oItem;
		$scope.resetContents(); // 컨텐츠 변수 리셋
		$scope.bShowLoginView = false;

		//나중에보기인 경우 로그인 체크
		if (LoginManager.isLogin() === false && $.inArray(oItem.id, ["later", "favorite"]) > -1) {
			$scope.aGroups = [];
			$scope.bShowLoginView = true;
			return false;
		}

		// 테마 진입시 정렬 - 기간 초기화
		$scope.setDefaultOrderPeriod(oItem.id);

		getList();
	};

	// 정렬탭, 기간 설정 변경 (default)
	$scope.setDefaultOrderPeriod = function(szType) {
		// 핫이슈
		if (szType == "hotissue") {
			$scope.storage.videoOrder = $scope.aOrderByColumns[3];
			$scope.storage.videoOrder.selectPeriod = $scope.aOrderByColumns[3]["period"][2];
		}
		// default
		else {
			$scope.storage.videoOrder = $scope.aOrderByColumns[0];
			$scope.storage.videoOrder.selectPeriod = $scope.aOrderByColumns[0]["period"][2];
			$scope.storage.videoCategory = {
				category_name: "전체",
				category_no: "00000000",
			};
			$scope.szCurrentCategoryNo = "00000000";
		}
	};

	//로그인이벤트
	$scope.$on("$loginChange", function(event, value) {
		$scope.bShowLoginView = false;
		getList();
	});

	// 카테고리 텝 클릭
	$scope.clickCategoryTab = function() {
		$scope.bCategory = !$scope.bCategory;
		$scope.bOrder = false;
		$scope.bPeriod = false;
	};

	// 정렬 탭 클릭
	$scope.clickOrderTab = function() {
		$scope.bOrder = !$scope.bOrder;
		$scope.bCategory = false;
		$scope.bPeriod = false;
	};

	// 정렬 탭 클릭
	$scope.clickPeriodTab = function() {
		$scope.bPeriod = !$scope.bPeriod;
		$scope.bCategory = false;
		$scope.bOrder = false;
	};

	// 카테고리 아이템 선택
	if (!$scope.storage.videoCategory) {
		$scope.storage.videoCategory = {
			category_name: "전체",
			category_no: "00000000",
		};
		$scope.szCurrentCategoryNo = "00000000";
	}
	//
	else {
		$scope.szCurrentCategoryNo = $scope.storage.videoCategory.category_no;
	}

	//카테고리선택
	$scope.setCategory = function(oCategory) {
		$scope.resetContents(); // 컨텐츠 변수 리셋
		$scope.szCurrentCategoryNo = oCategory.category_no;
		$scope.storage.videoCategory = oCategory;
		$scope.bOrder = false;
		$scope.bCategory = false;

		//스페셜 카테고리일 경우 기본 정렬 최신순+전체
		if ($scope.szCurrentCategoryNo == "00000001") {
			$scope.storage.videoOrder = $scope.aOrderByColumns[3]; //'최신순' 'reg_datetime';;
			$scope.storage.videoOrder.selectPeriod = $scope.aOrderByColumns[3]["period"][0]; //기간
		}
		// 신입BJ default 변경
		else if ($scope.szCurrentCategoryNo == "00000002") {
			$scope.storage.videoOrder = $scope.aOrderByColumns[0]; // 재생순
			$scope.storage.videoOrder.selectPeriod = $scope.aOrderByColumns[0]["period"][1]; // 1일
		}
		//
		else {
			$scope.storage.videoOrder = $scope.aOrderByColumns[0];
			$scope.storage.videoOrder.selectPeriod = $scope.aOrderByColumns[0]["period"][2];
		}

		getList();
	};

	// 정렬 아이템 선택
	$scope.setOrderby = function(oOrder) {
		var oPeriod = $scope.storage.videoOrder.selectPeriod;
		$scope.storage.videoOrder = oOrder;
		$scope.storage.videoOrder.selectPeriod = oPeriod;
		$scope.bOrder = false;
		$scope.bCategory = false;
		$scope.resetContents(); // 컨텐츠 변수 리셋
		getList();
	};

	// 기간
	$scope.setPeriod = function(oPeriod) {
		$scope.bPeriod = false;
		$scope.storage.videoOrder.selectPeriod = oPeriod;
		$scope.resetContents(); // 컨텐츠 변수 리셋
		getList();
	};

	// 컨텐츠 데이터들 리셋
	$scope.resetContents = function() {
		aIndex = [];
		$scope.aGroups = [];
		$scope.bShowEmptyBox = false;
		$scope.oVideoSection.bCompleted = true;
		$scope.oVideoSection.bHasMore = false;
		$scope.oVideoSection.nCurrentPageNo = 1;

		//스페셜 카테고리 데이터 리셋
		$scope.oVideoSpecial.bCompleted = true;
		$scope.oVideoSpecial.bHasMore = false;
		$scope.oVideoSpecial.nCurrentPageNo = 1;
	};

	////////////////////////////////////////////////
	//		리스트 호출
	////////////////////////////////////////////////
	var getList = function() {
		// 전체동영상 : 검색 API 사용
		if ($scope.oSelectedTopMenu.id == "all") {
			//전체동영상 > 스페셜 카테고리일 경우
			if ($scope.szCurrentCategoryNo == "00000001") {
				////////////////////////////////////////////////
				//		전체 동영상 > 스페셜카테고리 리스트  정의
				////////////////////////////////////////////////
				VideoListService.getVideoSpecial({
					nCurrentPage: $scope.oVideoSpecial.nCurrentPageNo,
					nRowPerPageNo: 20,
					szOrderByColumn: $scope.storage.videoOrder.id,
					szLately: $scope.storage.videoOrder.selectPeriod.lately ? $scope.storage.videoOrder.selectPeriod.lately : 0,
				}).then(function(oRes) {
					makeList(oRes.data);

					$scope.oVideoSpecial.bHasMore = oRes.data.has_more_list; // 더보기
					$scope.oVideoCategory.bCompleted = true; // 전체 동영상
					$scope.oVideoSection.bCompleted = true; // 핫이슈||TOP30
					$scope.oVideoSpecial.bCompleted = true; // 스페셜
				});
			}
			// 전체 동영상 [카테고리별]
			else {
				////////////////////////////////////////////////
				//		전체 동영상 [카테고리]
				////////////////////////////////////////////////
				VideoListService.getCategoryList({
					m: "vodList",
					nPageNo: $scope.oVideoCategory.nCurrentPageNo,
					nListCnt: 20,
					szOrder: $scope.storage.videoOrder.id,
					szTerm: $scope.storage.videoOrder.selectPeriod.lately,
					szVideoType: "",
					szPlatform: "mobile",
					szCateNo: $scope.storage.videoCategory.category_no,
					szFileType: "",
				}).then(function(oRes) {
					makeList(oRes.data);

					$scope.oVideoCategory.bHasMore = oRes.data.has_more_list; // 더보기
					$scope.oVideoCategory.bCompleted = true; // 전체 동영상
					$scope.oVideoSection.bCompleted = true; // 핫이슈||TOP30
					$scope.oVideoSpecial.bCompleted = true; // 스페셜
				});
			}
		}
		// 모바일 API 사용 : 홈, 핫이슈, TOP30, 나중에보기
		else {
			$scope.oVideoSection.bCompleted = false;

			// 나중에보기 order는 기본 order와 중복되면 안됨.
			var szOrder = $scope.oSelectedTopMenu.id == "later" ? $scope.szOrder : $scope.storage.videoOrder.id;

			// 호출

			////////////////////////////////////////////////
			//		동영상 섹션 리스트  정의
			////////////////////////////////////////////////
			var params = {
				current_page: $scope.oVideoSection.nCurrentPageNo,
				rows_per_page: 20,
				theme: $scope.oSelectedTopMenu.id, // 현재 선택된 ID
				order: szOrder,
				period: $scope.storage.videoOrder.selectPeriod.lately,
			};

			if ($scope.oSelectedTopMenu.id == "home") {
				params.title_list = RecentVodService.getAll(true);
			}
			VideoListService.getVideoSection(params).then(function(oRes) {
				makeList(oRes.data);

				$scope.oVideoSection.bHasMore = oRes.data.has_more_list; // 더보기
				$scope.oVideoCategory.bCompleted = true; // 전체 동영상
				$scope.oVideoSection.bCompleted = true; // 핫이슈||TOP30
				$scope.oVideoSpecial.bCompleted = true; // 스페셜
			});
		}
	};

	////////////////////////////////////////////////
	//		더보기
	////////////////////////////////////////////////
	Util.lastItem(function() {
		// 전체 동영상 더보기
		if ($scope.oSelectedTopMenu.id == "all") {
			// 스페셜 동영상
			if ($scope.szCurrentCategoryNo == "00000001") {
				if ($scope.oVideoSpecial.bCompleted && $scope.oVideoSpecial.bHasMore) {
					$scope.oVideoSpecial.nCurrentPageNo++;
					getList();
				}
			}
			//
			else {
				if ($scope.oVideoCategory.bCompleted && $scope.oVideoCategory.bHasMore) {
					$scope.oVideoCategory.bCompleted = false;
					$scope.oVideoCategory.nCurrentPageNo++;
					getList();
				}
			}
		}
		// 홈, 핫이슈, TOPO30, 나중에보기
		else {
			if ($scope.oVideoSection.bCompleted && $scope.oVideoSection.bHasMore) {
				$scope.oVideoSection.nCurrentPageNo++;
				getList();
			}
		}
	});

	////////////////////////////////////////////////
	//		리스트 만들기
	////////////////////////////////////////////////
	var makeList = function(data) {
		angular.forEach(data.groups, function(aGroup) {
			// title이 키가되서 object에 담고   이미 담아져있으면 contents 만 담는다.
			if ((index = aIndex.indexOf(aGroup.title)) >= 0) {
				$.merge($scope.aGroups[index].contents, aGroup.contents);
			} else {
				aIndex.push(aGroup.title);
				$scope.aGroups.push(aGroup);
			}

			// 모든그룹에 방송이 하나도없을경우
			if ($scope.oSelectedTopMenu.id == "all" && $scope.szCurrentCategoryNo == "00000001") {
				$scope.bShowEmptyBox = aGroup.contents.length < 1 && $scope.oVideoSpecial.nCurrentPageNo == 1 ? true : false;
			}
			// 나중에보기
			else if ($scope.oSelectedTopMenu.id === "later") {
				$scope.bShowEmptyBox = aGroup.contents.length < 1 && $scope.oVideoCategory.nCurrentPageNo == 1 ? true : false;
				$scope.bIsEmptyLaterView = aGroup.group_id === "best_later" ? true : false;
			}
			// 카테고리 전체
			else {
				$scope.bShowEmptyBox = aGroup.contents.length < 1 && $scope.oVideoCategory.nCurrentPageNo == 1 ? true : false;
			}
			$scope.$digest();
		});
	};

	///////////////////////////////////
	//		동영상 카테고리
	///////////////////////////////////
	VideoListService.getCategory().then(function(oRes) {
		$scope.categorys = oRes.data;
	});

	// 초로된 시간 Time format으로 변경
	$scope.secondsToTime = function(nSeconds) {
		return Util.secondsToTime(nSeconds);
	};

	//전체>스페셜 || 핫이뉴에는 마크노출안함
	$scope.hideMark = function(group) {
		if (group.title == "스페셜" || group.group_id == "list.hotissue_hotissue") {
			return true;
		} else {
			return false;
		}
	};

	// 나중에보기 삭제 Handler
	$scope.delLaterViewHandler = function(scheme, type) {
		LaterviewFactory.sheme(scheme)
			.delete()
			.then(function() {
				$scope.resetContents(); // 컨텐츠 변수 리셋
				getList();
			});
	};

	// 나중에보기 추가 Handler
	$scope.addLaterViewHandler = function(scheme) {
		LaterviewFactory.sheme(scheme)
			.add()
			.then(
				function() {
					if ($scope.oSelectedTopMenu.id === "later") {
						$(window).scrollTop(0); //나중에보기 안내 팝업 레이어 위치 조정
						$scope.resetContents(); // 컨텐츠 변수 리셋
						getList();
					}
				},
				function(error) {
					console.log("error", error);
				}
			);
		return true;
	};
	// action별 삭제 (전체/시청한 영상만)
	$scope.delLaterViewListHandler = function(action) {
		LaterviewFactory.deleteAll(action).then(function() {
			if ($scope.oSelectedTopMenu.id === "later") {
				$(window).scrollTop(0); //나중에보기 안내 팝업 레이어 위치 조정
				$scope.resetContents(); // 컨텐츠 변수 리셋
				getList();
			}
		});
	};

	// 나중에보기 탭에서 로그인 안된 경우 로그인 버튼 액션
	$scope.goLogin = function() {
		$rootScope.login();
	};

	//정렬변경
	$scope.$watch("szOrder", function(newValue, oldValue) {
		if ($scope.oSelectedTopMenu.id === "later" && newValue !== oldValue) {
			$scope.resetContents(); // 컨텐츠 변수 리셋
			getList();
		}
	});

	// 그룹 찾기
	$scope.getFavoriteEmpty = function(szType) {
		var szReason = "";
		angular.forEach($scope.aGroups, function(oItem) {
			if (oItem.group_type == "favorite") {
				szReason = oItem.group_info;
				return false;
			}
		});

		return szReason;
	};

	// 메뉴 이동
	$scope.goDetailPage = function(szScheme) {
		if (!szScheme) {
			return;
		}

		var aParam = /onlive:\/\/go\/vod\/theme\?id\=(.+)/.exec(szScheme);

		if (aParam && aParam[1]) {
			var menuItem = getTopMenuItemById(aParam[1]);
			if (menuItem) {
				$scope.clickTopMenu(menuItem);
			}
		}
	};

	// top menu item찾기
	function getTopMenuItemById(szId) {
		var item = null;

		$scope.aTopMemnu.forEach(function(oItem) {
			if (oItem.id == szId) {
				item = oItem;
				return false;
			}
		});

		return item;
	}

	//초기데이터////////////////////
	getList();

	//모바일 동영상 UV
	$timeout(function() {
		window.szLogAction = "MOBILE_VOD_PAGE";
		$.getScript(ANALYSIS_AFREECATV + "/_au.js");
	}, 1000);
}
module.exports = VideoController;
