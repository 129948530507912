MyinfoQuickviewController.$inject = ['$scope', '$rootScope', '$state', '$http', '$timeout', 'Util', 'LoginManager', 'CookieFactory'];
function MyinfoQuickviewController($scope, $rootScope, $state, $http, $timeout, Util, LoginManager, CookieFactory) {
    /**
	 * 퀵뷰정보
	 */
	$scope.loadQuickview = function () {
		$http({
			method: 'post',
			url: BILLING_AFREECATV + '/billing-api/mobile/my-items',
			data: $.param({ uno: LoginManager.getUserNo()}),
			headers: { 
				'Content-Type': 'application/x-www-form-urlencoded',
				'Authorization' : 'Bearer ' + CookieFactory.get('access_token')
			}
		}).success(function (response, status, headers, config) {
			if (response.STATUS == 1) {
				$scope.$apply(function () {
					$scope.quickview = response;
				});
			}
		}).error(function (data, status, headers, config) {
			$scope.item.chocolate = 0;
		});
	}

	/**
	 * 뒤로가기 버튼
	 */
	$scope.backHandler = function () {
		$state.go("myinfo");
	}

	$scope.use = function (coupon) {
		var params = $.param({
			no: LoginManager.getUserNo(),
			couponno: coupon.CouponNo,
			itemid: coupon.ItemID,
			duration: coupon.Duration,
			hvalue: md5(coupon.CouponNo + coupon.ItemID + coupon.Duration + LoginManager.getUserNo() + "AfreecaMobile")
		});
		$http({
			method: 'post',
			url: POINT_AFREECATV + '/mobile/mobilesvccouponins.asp',
			data: params,
			headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
		}).success(function (response, status, headers, config) {
			switch (response.RESULT) {
				case "1":
					$rootScope.$broadcast('$quickviewReload');
					toastr.success("등록되었습니다.");
					break;
				case "-200":
					toastr.warning("퀵뷰를 이용 중에는 등록이 불가능합니다.");
					break;
				default:
					toastr.warning(response.MESSAGE);
					break;
			}
		}).error(function (data, status, headers, config) {
			toastr.warning("오류가 발생하여 이용하실수 없습니다.");
		});
	}


	/**
	 * 로그인 이벤트
	 */
	$scope.$on('$loginChange', function (event, value) {
		if (value) {
			$scope.loadQuickview();

		} else {
			$state.go("myinfo");
		}
	});
}
module.exports = MyinfoQuickviewController; 