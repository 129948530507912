Constant.$inject = ['$rootScope'];
function Constant($rootScope) {
        $rootScope.$const = {
            WWW_AFREECATV : 'https://www.onlive.vn'
            , RES_AFREECATV : 'https://res.onlive.vn'
            , AUTH_M_AFREECATV : 'https://auth.m.onlive.vn'
            , MEMBER_M_AFREECATV : 'https://member.m.onlive.vn'
            , EVENT_IMG_AFREECATV : 'https://event.img.onlive.vn'
            , STATIC_FILE_AFREECATV : 'https://static.file.onlive.vn'
        };
};

module.exports = Constant; 