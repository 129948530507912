
PlayerService.$inject = ['$http'];
function PlayerService($http) {
        this.getBroadInfo = function (data) {
                return new Promise(function (resolve, reject) {
                        $http.post(API_M_AFREECATV + '/broad/a/watch?bjid=' + data.bj_id, $.param(data || '')).then(function (response) {
                                resolve(response.data);
                        }).catch(function (error) {
                                reject(error);
                        })
                });
        }
        this.getRelayBroadInfo = function (data) {
                return new Promise(function (resolve, reject) {
                        $http.post(API_M_AFREECATV + '/broad/a/watchRelay?bjid=' + data.bj_id, $.param(data || '')).then(function (response) {
                                resolve(response.data);
                        }).catch(function (error) {
                                reject(error);
                        })
                });
        }




        this.getBroadStreamResource = function (url, data) {

                return new Promise(function (resolve, reject) {
                        $http.get(url, { params: data }).then(function (response) {
                                if (response.data.result == 1) {
                                        resolve(response.data)
                                } else {
                                        reject(response.data);
                                };
                        }).catch(function (error) {
                                reject(error);
                        })
                });
        }



}


module.exports = PlayerService;