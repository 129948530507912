NotificationNotisettingController.$inject = ['$scope', '$rootScope', '$state', '$stateParams', '$http', '$timeout', 'Util', 'LoginManager', 'NotificationService', 'gettextCatalog', 'gtext']
function NotificationNotisettingController($scope, $rootScope, $state, $stateParams, $http, $timeout, Util, LoginManager, NotificationService, gettextCatalog, gtext) {

    if (!$rootScope.member) {
        $rootScope.login("notification.notisetting");
    }

    $scope.settingContent = []; // setting data
    $scope.settingType = [];
    
    //기존 세팅정보 조회
    $scope.settingInfo = function () {
        NotificationService.settingInfo({
        }).then(function (response) {
            $.merge($scope.settingContent, response.data[0].noti);
            setOptionType();
            setContentType();
            $scope.$digest(); 
        }).catch(function (response) {
            if (response.data.code == -1000) {
                $rootScope.login("notification.notisetting");
                return;
            } else {
                alert(response.data.message);
            }
        });
    }

    //설정 초기화정보 조회
    $scope.resetInfo = function(){
        NotificationService.resetInfo({
        }).then(function (response) {
            $.merge($scope.settingContent, response.data[0].noti);
            setOptionType();
            $scope.$digest();
        }).catch(function (response) {
            if (response.data.code == -1000) {
                $rootScope.login("notification.notisetting");
                return;
            } else {
                alert(response.data.message);
            }
        });
    }

    //설정저장
    $scope.save = function(){
        var cnt = $scope.settingType.length;
        $timeout(function () {
            $szOnSettingKeys = "";
            for (var i = 0; i < cnt; i++) {
                if ($scope.settingType[i].value){
                    $szOnSettingKeys+= ","+$scope.settingType[i].key;
                }
            }
            $szOnSettingKeys = $szOnSettingKeys.substring(1, $szOnSettingKeys.length);
            $scope.saveType = $szOnSettingKeys;
            $scope.$digest();
            
            NotificationService.saveInfo({
                set_flag : $scope.saveType,
            }).then(function (response) {
                alert(gtext("설정이 저장되었습니다."));
                $state.go("notification.notisetting", {"infoType":null}, {reload:true});
            }).catch(function (response) {
                if (response.data.code == -1000) {
                    $rootScope.login("notification.notisetting");
                    return;
                } else {
                    alert(response.data.message);
                }
            });
        });
    }

    //초기화정보 조회
    $scope.reset = function() {
        if (confirm(gtext("알림 설정을 초기화 합니다. \n변경했던 모든 세부 설정값을 초기 상태로 되돌립니다. 초기화한 알림 설정은 저장 시 적용됩니다."))) {
            $state.go("notification.notisetting", {"infoType":'reset'});
        }
    }

    //리스트 페이지 이동
    $scope.back = function () {
        location.replace(M_AFREECATV + "/#/notification/list");
    }

    //NOTI설정 페이지이동
    $scope.setting_noti = function () {
        location.replace(M_AFREECATV + "/#/notification/notisetting");
    }

    //PUSH설정 페이지이동
    $scope.setting_push = function () {
        location.replace(M_AFREECATV + "/#/notification/pushsetting");
    }

    function setOptionType() {
        var nCount = $scope.settingContent.length;
        $timeout(function () {
            for (var i = 0; i < nCount; i++) {
                $.merge($scope.settingType, $scope.settingContent[i].list);
            } 
        });
    }

    function setContentType() {
        var nCount = $scope.settingContent.length;
        $timeout(function () {
            for (var i = 0; i < nCount; i++) {
                var nListCnt = $scope.settingContent[i].list.length;
                for (var j = 0; j < nListCnt; j++) {
                    var szSubTile = $scope.settingContent[i].list[j].sub_title;
                    szTargetText = 'OnLive';
                    var szChangeText = '<span>OnLive</span>';
                    var szConvertedText = szSubTile.replace(new RegExp(szTargetText,'gi'),szChangeText)
                    $scope.settingContent[i].list[j].sub_title = szConvertedText;
                }
            }
        });
    }

    function chooseView(type){
        if (type == 'reset'){
            $scope.resetInfo();
        }else{
            $scope.settingInfo();
        }
    }

    chooseView($stateParams.infoType);
}

module.exports = NotificationNotisettingController;