var md5 = require('md5');
var toastr = require('toastr');
IteminfoController.$inject = ['$scope', '$window', '$rootScope', '$state', '$stateParams', 'Util', 'LoginManager', 'ItemService', 'PmService', 'gtext'];

function IteminfoController($scope, $window, $rootScope, $state, $stateParams, Util, LoginManager, ItemService, PmService, gtext) {

	$rootScope.title = '내 별풍선ㆍ아이템';
	$scope.item = {
		gold: 0,
		sticker: 0,
		starballoon: 0,
		chocolate: 0
	};
	$scope.language = Util.getPreferredLocale();
	$scope.bPageLoading = true;
	$scope.bToolTip = true;

	/**
	 * 아이템 유입 통계 호출
	 */	
	sendStatisticsData('access_page');
	
    /**
     * 점검체크 후 기본 정보 로드
     * @returns {undefined}
     */
    $scope.checkPmAndLoad = function() {
        PmService.check({
			szItem: 'starballoon',
			szFunction: 'gift',
			szAgent: 'web'
		}).then(function (response) {
			$scope.loadQuickview();
			$scope.loadItemInfo();
			$scope.loadGiftSubscriptionCount();
			$scope.loadGiftQuickviewCount();			
			// sleep(1);
		}).catch(function (response) {
			if (response.result == -1 && response.data.code == -1200) {
				toastr.error(response.data.message);
                $state.go("home");
                return;
			}
            $scope.loadQuickview();
			$scope.loadItemInfo();
			$scope.loadGiftSubscriptionCount();
			$scope.loadGiftQuickviewCount();
		});
    };

	$scope.loadItemInfo = function () {
		//아이템 갯수 가져오기 (별풍선,골드)
		ItemService.iteminfo().then(function (response) {
			$scope.item.starballoon = response.data['starballoon'];
			$scope.item.gold = response.data['gold'];
			$scope.item.sticker = response.data['sticker'];
			$scope.item.chocolate = response.data['chocolate'];
			$scope.item.recvsubscription = response.data['recvsubscription'];
			$scope.item.giftsubscription = response.data['giftsubscription'];
			$scope.$digest();
		}).catch(function (error) {
			$scope.item.starballoon = 0;
			$scope.item.gold = 0;
			$scope.item.sticker = 0;
			$scope.$digest();
		});
	};

	// 아이템 구매하기 URL정보
	var oItemUrl = {
		ko: {
			starballoon: POINT_AFREECATV,
			gold: POINT_AFREECATV + '/buy-sticker',
			quickview: POINT_AFREECATV + '/quick-view',
			autopay: POINT_AFREECATV,
			subs : M_AFREECATV

		}
		// 페이레이터 페이지에서 cookie값을 이용하여 국적을 판단하여 사용함.
		,
		abroad: {
			starballoon: POINT_AFREECATV,
			gold: POINT_AFREECATV + '/buy-sticker',
			quickview: POINT_AFREECATV + '/quick-view',
			autopay: POINT_AFREECATV
		}
	};

	/**
	 *
	 * @param {type} type
	 * @param {object} windowReference
	 * @returns {undefined}
	 */
	$scope.buy = function (type, windowReference) {
		var oUrlInfo = oItemUrl.ko;
		windowReference.location = oUrlInfo[type];
	};

	/**
	 * PM체크 및 구매하기
	 */
	$scope.checkPmAndBuy = function (type) {
		$scope.szItemType = type;
		// 팝업차단 때문에 새창을 열고 시작함
		var windowReference = window.open();
		PmService.check({
			szItem: type,
			szFunction: 'buy',
			szAgent: 'web'
		}).then(function (response) {
			// 아이템 구매 클릭통계 호출
			sendStatisticsData('click',type);
			// 결제 팝업 오픈
			$scope.buy($scope.szItemType,windowReference);
		}).catch(function (response) {
			if (response.result == -1 && response.data.code == -1200) {
				windowReference.close();
				alert(response.data.message);
				return;
			}
			$scope.buy($scope.szItemType,windowReference);
		});
	};



	/**
	 * 비밀번호 변경
	 */
	$scope.changePW = function () {
		Util.popup(MEMBER_M_AFREECATV + '/change/a/pwform/referer/app_menu/?path=web');
	};

	/**
	 * 퀵뷰이용권 페이지로 이동
	 */
	$scope.goQuickviewPage = function () {
		if ($scope.quickview.COUPON.Result > 0) {
			$state.go("iteminfo.quickview");
		} else {
			return false;
		}
	}

	/**
	 * 선물받은 구독권 페이지로 이동
	 * 국내만 지원. 해외는 얼럿으로 막음
	 */
	$scope.goRecvGiftSubscriptionPage = function () {
		location.href = POINT_AFREECATV + '/mygift-subscription/#giftcard';
		return false;
	}

	/**
	 * 보유중인 구독 선물권 페이지로 이동
	 * 국내만 지원. 해외는 얼럿으로 막음
	 */
	$scope.goGiftSubscriptionPage = function () {
		location.href = POINT_AFREECATV + '/mygift-subscription/#giftcard';
		return false;
	}

	/**
	 * 스티커 페이지로 이동
	 * 국내만 지원. 해외는 얼럿으로 막음
	 */
	$scope.goStickerPage = function () {
		location.href = POINT_AFREECATV + '/exchange-sticker';
		return false;
	}

	/**
	 * 퀵뷰정보 불러오기
	 */
	$scope.loadQuickview = async () => {
		try {
			const response = await ItemService.quickview({
				uno: LoginManager.getUserNo(),
				hvalue: md5(LoginManager.getUserNo() + "AfreecaMobile")
			});

			$scope.bPageLoading = false;
			$scope.quickview = response;

			$scope.autopay = response.AutoPay; //별풍선 자동충전 사용정보(STATUS : (0:노출안함, 1:노출) / Result : (0:사용안함, 1:사용중))

			const item = $scope.quickview.ITEM;
			item.ExpTimeDay =  Math.floor(item.ExpTime / 3600 / 24); // 남은시간 일(day) 노출
			item.ExpTimeHour =  Math.floor(item.ExpTime / 3600 % 24); // 남은시간 (hour) 노출
			item.PayYMD = convertDateFormat(item.PayYMD);

			// 퀵뷰 라벨 세팅
			item.label = getQuickviewLabel(item);

			// [퀵뷰 플러스 전환버튼 노출조건]
			item.upgrade = getQuickviewUpgrade(item);

			$scope.$digest();
		} catch (error) {
			$scope.bPageLoading = false;
			$scope.$digest();
		}
	}

	//퀵뷰선물권 개수 정보
	$scope.loadGiftQuickviewCount = function () {
		ItemService.quickviewCount().then(function (response) {
			$scope.nGiftQuickview = response.data.totalcnt;
		}).catch(function (error) {
			$scope.nGiftQuickview = 0;
		});
	};

	//퀵뷰선물권 상세정보페이지 (보유선물권현황)
	$scope.goGiftQuickviewInfo = function () {
		location.href = POINT_AFREECATV + '/mygift-quickview';
		return false;
	};

	/**
	 * 퀵뷰상품권등록, 이용권 사용으로 정보 새로고침
	 */
	$scope.$on('$quickviewReload', function () {
		$scope.loadQuickview();
		$scope.loadGiftQuickviewCount();
	});

	// 구독선물권 개수 정보
	$scope.loadGiftSubscriptionCount = function () {
		ItemService.subscriptionCount().then(function (response) {
			$scope.nGiftSubscription = response.data.buytotalcnt;
			$scope.nRecvGiftSubscription = response.data.rcvtotalcnt;
		}).catch(function (error) {
			$scope.nGiftSubscription = 0;
			$scope.nRecvGiftSubscription = 0;
		});
	};

	/**
	 * 상품권 등록 후 rerload
	 */
	$window.addEventListener("message", receiveMessage, false);

	function receiveMessage(event) {
		if (event.origin != ITEM_M_AFREECATV) {
			return false;
		}
        $scope.checkPmAndLoad();
	}
	/**
	 * 상품권 등록 이벤트
	 * @returns {undefined}
	 */
	$scope.registerCoupon = function () {

		$window.open(ITEM_M_AFREECATV + "/coupon/a/registerForm/default/starballoon/location/item/sys_type/webm");
	}
	/**
	 * 이용중인 별풍선 자동충전 상세 팝업
	 * @returns {undefined}
	 */
	$scope.goAutopayInfo = function () {
		$window.open(POINT_AFREECATV);// + "/Balloon/MobileBalloonAutoPayDtl.asp?szModel=web");
	}

	/**
	 * 별풍선 자동충전 상세 팝업
	 * @returns {undefined}
	 */
	 $scope.balloonAutoPay = function () {
		$window.open(POINT_AFREECATV);// + "/Balloon/MobileBalloonAutoPayDtl.asp?" + $.param(data));
	}

	/**
	 * 퀵뷰 30일 자동결제 해지
	 */
	$scope.cnlQuickViewAutoPayment = function () {
		if ($scope.quickview.ITEM.AutoPayTool == "18") {
			alert(gtext('애플 정기구독(자동결제)으로\n현재 디바이스에선\n해지가 불가능 합니다.\n\n아이폰(혹은 아이패드)의\nApp Store>Apple ID>구독을 이용해주세요.'));
			return false;
		}

		if ($scope.quickview.ITEM.AutoPay === 'YES') {
			var confirmMessage = "";
			if ($scope.quickview.ITEM.QuickViewType === "1") {
				confirmMessage = "회원님은 현재 3,900원보다 10% 할인된 월 3,500원으로 퀵뷰를 이용하고 계십니다. 퀵뷰 자동결제를 정말 해지 하시겠습니까 ?";
			} else {
				confirmMessage = "회원님은 현재 5,900원보다 10% 할인된 월 5,300원으로 퀵뷰 플러스를 이용하고 계십니다. 퀵뷰 플러스 자동결제를 정말 해지 하시겠습니까 ?";
			}
		} else {
			return; 
		}

		if(confirm(gtext(confirmMessage))) {
			ItemService.cnlquickview().then(function (response) {
				if(response.RETCODE == 0){
					var expDate = $scope.quickview.ITEM.ExpDate;
					if ($scope.quickview.ITEM.QuickViewType === "1") {
						alert("자동결제 해지 신청이 완료되었습니다.\n"+expDate.split('-')[0]+"년 "+expDate.split('-')[1]+"월 "+expDate.split('-')[2].split(' ')[0]+"일까지 남은 퀵뷰 사용이 가능하며 이후 해지가 완료 됩니다.");
					} else {
						alert("자동결제 해지 신청이 완료되었습니다.\n"+expDate.split('-')[0]+"년 "+expDate.split('-')[1]+"월 "+expDate.split('-')[2].split(' ')[0]+"일까지 남은 퀵뷰 플러스 사용이 가능하며 이후 해지가 완료 됩니다.");
					}
					$scope.loadQuickview();
				}
				else {
					alert(response.ERRMSG);
				}
			}).catch(function (error) {
			});
		}
	}

    /**
     * 퀵뷰 자동결제 결제수단 변경
     */
    $scope.goChangeAutoPayment = function (billNo) {
		if ($scope.quickview.ITEM.AutoPayTool == "18") {
			alert(gtext('애플 정기 구독(자동결제)으로\n현재 디바이스에선\n결제수단 변경이 불가합니다.\n\n아이폰(혹은 아이패드)의\nApp Store>Apple ID>구독을 이용해주세요.'));
			return false;
		}

        $window.open(POINT_AFREECATV);// + "/AutoPayChange/MobileAutoPayUpdFrm.asp?" + $.param(data));
    }

    /**
     * 퀵뷰 업그레이드 페이지 이동
     */	
	$scope.goQuickviewUpgradePage = function (itemType) {

		//퀵뷰전환 클릭통계 호출
		sendStatisticsData('click','quickview_upgraqde');

		if (itemType == 'AUTO' || itemType == 'NORMAL') {
			$state.go('iteminfo.quickview.upgrade', {
				itemType: itemType.toLowerCase()
			});
		} else if (itemType == 'LESS') {
			alert(gtext('퀵뷰 30일 자동 결제 2회차 이용 후\n플러스로 전환이 가능합니다.\n\n다음에 다시 이용해주세요.'));
			return false;
		} else if (itemType == 'DUPLICATE') {
			alert(gtext('이미 퀵뷰 플러스 전환 첫달 무료 혜택을 받으셨기에\n중복 참여 불가능합니다.'));
			return false;
		} else {
			alert(gtext('유료 퀵뷰, 유료 퀵뷰 선물권만 \n퀵뷰 플러스로 전환 가능합니다.'));
			return false;
		}
	}

	$scope.toggleTooltip = function () {
		$scope.bToolTip = false;
	}
	

	$rootScope.$on('$loginChange', function (event, value) {
		if (LoginManager.isLogin()) {
            $scope.checkPmAndLoad();								
		} else {
			toastr.error(gtext("로그인이 되어 있지 않습니다."));
			setTimeout(function () {
				$state.go("home");
			}, 500);
		}
	});
	LoginManager.check();

	/**
	 * 퀵뷰 라벨 노출 여부 세팅
	 * @param itemInfo
	 */
	function getQuickviewLabel(itemInfo) {
		const label = {
			paid: false,			// 유료 사용 중
			freeUse: false,			// 무료 이용권 사용 중
			freeTrial: false,		// 무료 체험 중
			adballoonLimit: false	// 애드벌룬 적립 제한 (무료 퀵뷰 플러스 이용 중일 경우)
		};

		const freeTrialProducts = [/*740140, 740141, 740142, 740143, 740144*/];
		if (freeTrialProducts.includes(parseInt(itemInfo.ItemID))) {
			// 무료 체험 중
			label.freeTrial = true;
		} else if (
			parseInt(itemInfo.QuickViewItemCode) === 10000 && parseInt(itemInfo.UpgradeFlag) === 4 ||	// 퀵뷰 무료 이용권 사용 중
			parseInt(itemInfo.QuickViewItemCode) === 10004	// 퀵뷰 플러스 무료 이용권 사용 중
		) {
			// 무료 퀵뷰/퀵뷰 플러스 사용 중
			label.freeUse = true;
		} else {
			// 유료 사용 중
			label.paid = true;
		}

		// 애드벌룬 적립 제한
		if (parseInt(itemInfo.QuickViewItemCode) === 10004) {
			label.adballoonLimit = true;
		}

		return label;
	}

	/**
	 * 퀵뷰 플러스 업그레이드 여부 조회
	 *
	 * @param itemInfo
	 * @returns {{show: boolean, type: string}}
	 */
	function getQuickviewUpgrade(itemInfo) {
		const upgrade = {
			show: false,
			type: ''
		};

		// 업그레이드 가능여부 , 퀵뷰타입 확인
		if (parseInt(itemInfo.UpgradeFlag) === 1 && parseInt(itemInfo.QuickViewType) === 1) {
			// 자동결제 여부, 해지중 확인 (국내-버튼 노출 / 해외-버튼 미노출)
			if ($itemInfo.AutoPayCnlFlag === 'NO' && itemInfo.AutoPay === 'YES') {
				upgrade.show = true;
				upgrade.type = "AUTO";
				// 일반결제
			} else if (itemInfo.AutoPay === 'NO') {
				upgrade.show = true;
				upgrade.type = "NORMAL";
			}
			// 자동결제 한달 미만 (국내-버튼노출 / 해외-버튼 미노출)
		} else if (parseInt(itemInfo.UpgradeFlag) === 2) {
			upgrade.show = true;
			upgrade.type = "LESS";
			// 무료 아이템
		} else if (parseInt(itemInfo.UpgradeFlag) === 4) {
			upgrade.show = true;
			upgrade.type = "FREE";
		}
		return upgrade;
	}
	
	/**
	 * 별풍선 * 아이템 페이지 유입(호출,클릭) 로그전송
	 *
	 * @param actionType
	 * @param buttonType
	 */	
	function sendStatisticsData (actionType,buttonType) {
		let url = '//eventapi.onlive.vn/set_log_api.php';
		let parameters = {
			referer : document.referrer,
			page : document.location.href,
			lang : $scope.language
		};
		let entryLocation = 'unknown';
		if ($stateParams.entry_location === 'm_starballonitem') {
			entryLocation = $stateParams.entry_location;			
		}

		// 클릭된 버튼타입 재정의
		switch(buttonType) {
			case 'quickview':
				buttonType = 'quickview_purchase';
				break;
			case 'gold':
				buttonType = 'sticker_purchase';
				break;
			case 'starballoon':
				buttonType = 'starballoon_purchase';
				break;	
			case 'autopay':
				buttonType = 'starballoon_auto';
				break;
			default:
				break;
		  }

		$.ajax({
			type : "POST"
			, url: url
			, data : {
				sztype : 'ITEMSTORE'
				,entry_location : entryLocation
				,action_type : actionType
				,tab_name : 'staballon_item'
				,button_type : buttonType
				,send_data : $.param(parameters)
				,sys_type : 'webm'
			}
			, dataType	: 'json'
			, cache	: false
			, crossDomain: true
			, xhrFields: {
				withCredentials: true
			}
			, beforeSend : function(request) {
				request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded;charset=UTF-8');
			}
			, success : function( data ) {
			}
			, error : function(jqXHR, textStatus, errorThrown ) {
			}
			, complete : function(jqXHR, textStatus ) {
			}
		});
	}
}

function convertDateFormat(date) {
    return date.replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3');
}

module.exports = IteminfoController;